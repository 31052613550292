.educatemain {
    width: 100%;
    height: auto;
    padding: 100px 0px 80px 0px;
}

.educatemainflx {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.educatemainleft {
    width: 100%;
    max-width: 76%;
    align-self: flex-start;
}

.educatemainright {
    width: 100%;
    max-width: 21%;
    align-self: flex-start;
}

.educatemainthreedivs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 19px;
    flex-wrap: wrap;
    row-gap: 20px;
}

.educatemainbg {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 10px;
    width: 100%;
    max-width: 100%;
}

.trainingimg {
    width: 100%;
    height: 128px;
    position: relative;
}

.trainingimg img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.trainingimgaddnew {
    position: absolute;
    top: 9px;
    right: 10px;

}

.trainingimgdivnew {
    background-color: red;
    padding: 3px 15px;
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    border-radius: 5px;
}

.educatetrainingtext {
    color: #000;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    font-family: noirprolight;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 7px 0px;
}

.imgtxtdivmaindddflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
}

.descritptoinmadtorydiv {
    padding: 0px 10px;
}

.imgtxtdivmainddd {
    display: flex;
    justify-content: flex-start;
    column-gap: 2px;
}

.strimgdiv {
    width: 25px;
    height: 25px;
}

.strimgdiv img {
    width: 100%;
    height: 100%;
}

.tsgyutppp {
    font-size: 11px;
    color: #000;
    font-weight: 600;
    font-family: 'NotoSans';
    line-height: 16px;
    align-self: center;
}

.bg-userdtwht {
    width: 100%;
    height: 159px;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
}

.educatedivmaintaroincrd {
    width: 100%;
    max-width: 100%;
    box-shadow: 0px 0px 5px 0px #ccc;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;

}

.plthclbtx {
    height: 34%;
    padding-right: 20px;
    padding-top: 0;
}

.divpatyn {
    padding-top: 10px;
}

.bghhkpp {
    font-family: 'NoirProRegular';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 22px;
    color: #ffffff;
    /* text-align: left; */
    padding-top: 4px;
    margin: 0;
    text-align: end;
    letter-spacing: 1px;
}

.clbpp {
    font-family: 'NoirProLight';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
    padding-top: 1px;
    margin: 0;
    text-align: end;
    letter-spacing: 1px;
}

.divpaddingghh {
    padding: 0px 20px;
}

.prflimgdiv {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 6%;
    align-items: flex-end;
}

.prflimgbrdr {
    /* align-items: center; */
    border: 4px solid #fff;
    border-radius: 50%;
    /* display: flex; */
    /* justify-content: center; */
    margin-left: -10px;
    margin-top: -50px;
    position: relative;
}

.prflimgfh {
    width: 100%;
    max-width: 70px;
    height: 70px;
    min-width: 70px;
}

.prflimgfh img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.thounsabpp {
    color: red;
    font-size: 23px;
    line-height: 28px;
    font-weight: 700;
    font-family: 'NoirProLight';
}

.pointshttx {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    font-family: 'NotoSans';
}

.tstdfghmrl {
    margin-top: 20px;
}

.txtppght {
    color: #000;
    font-size: 18px;
    font-weight: 800;
    font-family: "NoirProRegular";
    line-height: 22px;
}

.dvjknjpp {
    color: #000;
    font-size: 17px;
    font-weight: 500;
    font-family: "NoirProRegular";
    line-height: 20px;
}

.statusdivmain {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
}

.mandatorydiv {
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 5px 0px #ccc;
    background-color: #fff;
    border-radius: 5px;
    padding: 10PX;
}

.mandatoryicontxtflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icontxtdivmain {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
}

.mandatoryimg {
    width: 25px;
    height: 25px
}

.mandatoryimg img {
    width: 100%;
    height: 100%;
}

.mandatorttxt {
    font-size: 18px;
    line-height: 19px;
    color: #000;
    font-family: Noirprolight;
    font-weight: 600;
}

.Expandimg {
    width: 25px;
    height: 25px;
}

.Expandimg img {
    width: 100%;
    height: 100%;
}

.mandatorydivbg {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    margin-top: 10px;
    margin-bottom: 10px;
}

.traingmaindivmain {
    width: 100%;
    height: 160px;
}

.traingmaindivmain img {
    width: 100%;
    height: 100%;
}

.mandatorydivbtm .slick-next {
    line-height: 0;
    position: absolute;
    padding-top: 11px !important;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 4;
    top: 30%;
    right: -2%;
    width: 40px;
    height: 40px;
}

.mandatorydivbtm .slick-next:before {
    content: '\f105' !important;
    font-family: FontAwesome !important;
    font-size: 27px !important;
    line-height: 1;
    opacity: 1 !important;
    color: rgb(255, 255, 255) !important;
    display: flex;
    background-color: #d3a145;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15), 1px 2px 5px 0px rgba(0, 0, 0, 0.15) inset;
}

.mandatorydivbtm .slick-disabled {
    opacity: 0;
}

.mandatorydivbtm .slick-prev {
    line-height: 0;
    position: absolute;
    padding-top: 11px !important;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 4;
    top: 30%;
    left: -1.5%;
    width: 40px;
    height: 40px;
}

.mandatorydivbtm .slick-prev:before {
    content: '\f104' !important;
    font-family: FontAwesome !important;
    font-size: 27px !important;
    line-height: 1;
    opacity: 1 !important;
    color: rgb(255, 255, 255) !important;
    display: flex;
    background-color: #d3a145;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15), 1px 2px 5px 0px rgba(0, 0, 0, 0.15) inset;
}


.educatedivmaintaroincrd .slick-next {
    line-height: 0;
    position: absolute;
    padding-top: 11px !important;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 4;
    top: 30%;
    right: -2%;
    width: 40px;
    height: 40px;
}

.educatedivmaintaroincrd .slick-next:before {
    content: '\f105' !important;
    font-family: FontAwesome !important;
    font-size: 27px !important;
    line-height: 1;
    opacity: 1 !important;
    color: rgb(255, 255, 255) !important;
    display: flex;
    background-color: #d3a145;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15), 1px 2px 5px 0px rgba(0, 0, 0, 0.15) inset;
}

.educatedivmaintaroincrd .slick-disabled {
    opacity: 0;
}

.educatedivmaintaroincrd .slick-prev {
    line-height: 0;
    position: absolute;
    padding-top: 11px !important;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 4;
    top: 30%;
    left: -1.5%;
    width: 40px;
    height: 40px;
}

.educatedivmaintaroincrd .slick-prev:before {
    content: '\f104' !important;
    font-family: FontAwesome !important;
    font-size: 27px !important;
    line-height: 1;
    opacity: 1 !important;
    color: rgb(255, 255, 255) !important;
    display: flex;
    background-color: #d3a145;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15), 1px 2px 5px 0px rgba(0, 0, 0, 0.15) inset;
}

.mandttxtpp {
    color: #000;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    font-family: noirprolight;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 7px 0px;
}

.writertxtpp {
    color: #000;
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    font-family: noirprolight;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 7px 0px;
}

.mandatorydivbtm {
    margin: 15px 0px;
}

.slick-slide {
    padding: 0px 10px;
}

.mandatorycoursemaindiv {
    padding: 20px 0px 0px 0px;
}

.videoongoing {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    margin-top: 0px;
    padding-bottom: 5px;
}

.docmntdivmnan {
    padding: 20px 0px 0px 0px;
}

.videoongoing .top {
    width: 100%;
    height: auto;
    padding: 8px 12px 8px 12px;
    border-bottom: 1px solid rgba(198, 198, 210, 0.26);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.videoongoing .bulleBoardFig {
    width: 25px;
    height: 25px;
    /* margin: 0 3px 0 0; */
}

.videoongoing .bulleBoardHead {
    display: flex;
    align-items: center;
}

.videoongoing .bulleBoardFig img {
    width: 100%;
    height: 100%;
}

.videoongoing .top .head h4 {
    font-family: NoirProRegular;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: normal;
    color: #263238;
    padding: 0;
    margin: 0;
    margin-left: 10px;
}

.videoongoing .add_seeallflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5%;
}

.videoongoing .tyEvMainDiv {
    margin-right: 7px;
}

.videoongoing .bulleBoardFig {
    width: 25px;
    height: 25px;
}

.video_onflx {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 11px;
}

.trainingmainimgdiv {
    width: 100%;
    max-width: 75px;
    height: 65px;
    min-width: 75px;
    overflow: hidden;
}

.trainingmainimgdiv img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.playtxtflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;
}

.bg-play {
    background-color: #d3a145;
    padding: 3px 11px;
    color: #fff;
    font-size: 12px;
    line-height: 15px;
    font-family: Notosans;
    font-weight: 400;
    border-radius: 5px;
    border: none;
}

.lrmtxtpp {
    color: #263238;
    font-family: Noirprolight;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 92px;
    min-width: 92px;
    padding: 5px 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.videoongoing .bottom {
    padding: 8px 10px 0px 10px;
    height: 315px;
    overflow-y: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.videoongoing .bottom ::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .videoongoing .bottom  {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
.videoongoinddiv:last-child{
    border-bottom: 0px solid #ccc;
}

.dotstxtpp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #00000081;
    font-size: 14px;
    line-height: 19px;
    font-family: Noirprolight;
    align-items: center;
    column-gap: 4px;
    padding-top: 2px;
}

.dotsdiv {
    background-color: red;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: block;
}

.prgrsbartxtdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.imgtxtppdivprgrs {
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */
    column-gap: 5px;
}

.prgrstextper {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-family: notosans;
    align-self: center;
}

.prgrsdivimg {
    width: 25px;
    height: 25px;
}

.prgrsdivimg img {
    width: 100%;
    height: 100%;
}

.borderprgs {
    width: 100%;
    border: 1px solid #ccc !important;
    background-color: #ccc;
    height: 7px;
    border-radius: 10px;
    margin-top: 5px;
}

.brdrgrey {
    background-color: #d3a145;
    border-radius: 10px;
    overflow: hidden;
}

.prgrsbartxtdivpading {
    padding: 8px 0px 0px 0px;
}

.videoongoinddiv {
    /* background-color: #e2f0d9; */
    padding: 0px 0px 7px 0px;
    margin-bottom: 7px;
    border-bottom: 1px solid #ccc;
}

.video_onflxdiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.trainingnewdiv {
    background-color: #64a382;
    width: 100%;
    background-color: #64a382;
    width: 100%;
    padding: 10px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    font-family: Noirprolight;
    border: none;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
    display: block;
    text-decoration: none;
}

.videoongoinddiv:last-child {
    margin-bottom: 0px;
}

.playtextbtnvwwh {
    width: 100%;
}

.bottomrepoflx {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 17px;
    border-bottom: 1px solid #ccc;
    /* padding: 10px 0px; */
    margin: 5px 0px;
    padding: 6px 0px;
}

.purchaseimgdiv {
    width: 100%;
    max-width: 40px;
    height: 40px;
    min-width: 40px;
}

.purchaseimgdiv img {
    width: 100%;
    height: 100%;
}

.Purchasedivflx {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
}

.arttopsubtopic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;
    padding-top: 9px;
    width: 100%;
    border-top: 1px solid #ccc;
}

.purtxt {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    font-family: noirprolight;
    color: #000;
    text-align: center;
    /* border-bottom: 1px solid #ccc; */
    width: 100%;
    padding-bottom: 4px;
    width: 100%;
    max-width: 95px;
    min-width: 95px;
}

/* .purtxtdiv {
    width: 100%;
} */

.artcl {
    text-align: center;
    font-size: 13px;
    line-height: 16px;
    font-family: notosans;
    color: #000;
}

.artctext {
    font-size: 12px;
    line-height: 15px;
    font-family: noirproheavy;
    color: #000;
    width: 100%;
}

.createbtnnewdiv {
    background-color: #64a382;
    width: 100%;
    padding: 10px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    font-family: Noirprolight;
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-top: 15px;
}

/**** Single Mandatory Course start ****/
.singlemandatorysection {
    width: 100%;
    height: auto;
    padding: 100px 0px 50px 0px;
}

.singleeducatemainflx {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.educatemainleftbg {
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
    row-gap: 15px;
    flex-wrap: wrap;
    padding: 15px 10px 15px 15px;
}

.mandatorydivbgmain {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    /* margin-top: 10px; */
    /* margin-bottom: 10px; */
    width: 100%;
    max-width: 32%;
    min-width: 32%;
}

/**** Single Mandatory Course end ****/

/* Create Training Page Start */
.trainingcreatepagemain {
    width: 100%;
    height: auto;
    padding: 120px 0px 20px 0px;
}

.trainingcreatepagemainflx {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.trainingcreatepageleft {
    width: 100%;
    max-width: 23%;
}

.trainingcreatepageright {
    width: 100%;
    max-width: 72%;
}

.trainingcreatepageleftbrdr {
    width: 100%;
    border: 2px solid #64a382;
    height: auto;
    padding: 10px 10px 15px 10px;
    border-radius: 10px;

}

.typstringtxtppcrt {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    font-family: noirprolight;
    color: #000;
}

.advocacycreatemaindivpadongnew {
    padding-top: 20px;
}

.bgcrddivbg {
    width: 100%;
    height: 100%;
}

.typstrningwhselct {
    width: 100%;
    border: 1px solid #000;
    padding: 4px;
    border-radius: 4px;
    margin: 5px 0px 0px 0px;
}

.trainingpageleftdivmain {
    padding-bottom: 10px;
}

.txtdivallart {
    width: 100%;
    height: 70px;
    resize: none;
    margin: 10px 0px 0px 0px;
    border: 1px solid #000;
    border-radius: 4px;

}

.containerupldimg {
    max-width: 100%;
    width: 100%;
    background: #fff;
    padding: 30px;
    border-radius: 30px;
}

.isinthetraining {
    padding: 10px 0px 0px 0px;
}

.vdoulddivpbflx {
    display: flex;
    justify-content: space-between;
    margin: 5px 0px 0px 0px;
    column-gap: 10px;
}

.vdoulddivpbflxwh {
    width: 100%;
    max-width: 48%;
}

.upldbrdrdivbg {
    width: 100%;
    border: 1px solid #000;
    height: 100px;
    border-radius: 5px;
    background-color: #ccc;
}

.addimgdivriofg {
    width: 100px;
    height: 100px;
    margin: auto;

}

.addimgdivriofg img {
    width: 100%;
    height: 100%;
}

.addmrdibfk {
    background-color: #000000;
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    margin-left: 10px;
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    font-family: notosans;
    font-weight: 500;
}

.texttraingrightdiv {
    width: 100%;
    border: 1px solid #000;
    padding: 4px;
    border-radius: 4px;
    margin: 10px 0px 0px 0px;
}

.trainingcreatepagemain .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    margin: 10px 0px 0px 0px;
}

.anwrdivtyp {
    align-items: center;
    column-gap: 10px;
    display: flex;
    justify-content: flex-start;
    padding-top: 10px;
}

.anstxtwrttype {
    color: #000;
    font-family: noirprolight;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
}

.answerdrpdwn {
    border: 1px solid #000;
    border-radius: 5px;
    max-width: 30%;
    padding: 4px;
    width: 100%;
}

.trainingrifgtdivgappp {
    padding-bottom: 10px;
    padding-top: 5px;
}

.aaddiconinptfklddibwhhj {
    width: 100%;
}

.quiyzequestixp {
    font-size: 15px;
    line-height: 17px;
    font-weight: 600;
    font-family: noirprolight;
    color: #000;
    padding: 2px 0px 5px 0px;
}

.pdbquioze {
    margin: 5px 0px 0px 0px;
}

.trainingrifgtdivgapppbrdr {
    border: 2px solid #64a382;
    padding: 10px;
    margin-bottom: 10px;
}

.homePgCreModSubmitDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rsntxrpnewtxt {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    font-family: noirprolight;
    color: #000;
}

.filereltfbhyy {
    position: relative;
    width: 100%;
    height: 100%;
}

.rsntxrpnewtxtupld {
    font-size: 20px;
    line-height: 25px;
    font-weight: 600;
    color: #000;
    font-family: notosans;

}

.vdoulddivpb {
    padding: 10px 0px 0px 0px;
}

.bgcontainerupldbrdr {
    border: 1px solid #000;
    padding: 7px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 5px 0px 0px 0px;
}

.logoupld {
    width: 28px;
    height: 29px
}

.logoupld img {
    width: 100%;
    height: 100%;
    position: relative;
}

.imageuplddivvideo {
    position: relative;
    width: 50px;
    height: 50px;
}

.imageuplddivvideo video {
    width: 100%;
    height: 100%;
}

.videotitledesrtpupldflx {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px 0px 0px;
    column-gap: 10px;
}

.videottldivwhgt {
    width: 100%;
    max-width: 100%;
}

.vdoulddivpbupldg {
    width: 100%;
    max-width: 48%;

}

.bgcontainerupldbrdrdiv {
    border: 1px solid #000;
    padding: 7px;
    width: 100%;
    height: 131px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 5px 0px 0px 0px;
}


.uplddwnlddivbrtnmain {
    width: 100%;
    max-width: 20%;
}

.videottldescrtflx {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.questionnmbr {
    font-size: 14px;
    font-family: notosans;
    line-height: 18px;
    color: #000;
    font-weight: 500;

}

.inptyflddiccv {
    width: 100%;
    max-width: 95%;
    border: 1px solid #000;
    padding: 4px;
    border-radius: 4px;
    margin: 5px 0px 0px 0px;
    margin-left: 8px;
}

.addimngrtring {
    width: 25px;
    height: 25px;
}

.addimngrtring img {
    width: 100%;
    height: 100%;
}

.trainernameselectflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

/* Create Training Page End */


.ongingsinglemian {
    width: 100%;
    height: auto;
    padding: 100px 0px 50px 0px;
}

.ongoingmainleft {
    width: 100%;
    max-width: 76%;
    align-self: flex-start;
}

.ongoingmainright {
    width: 100%;
    max-width: 21%;
    align-self: flex-start;
}

.videoongoinddivsingle {
    /* background-color: #e2f0d9; */
    padding: 10px;
    margin-bottom: 8px;
    width: 100%;
    max-width: 32%;
    border-bottom: 1px solid #ccc;
}
.requestrtdrtrasijnpafdinl{
    padding-top: 20px;
}
.crerrrdocimmytstreyuspagdion{
    padding-top: 20px;  
}
.ongoinnghidivpding{
    padding-top: 20px; 
}   
.requesttedtrysiindibvpadin{
    padding-top: 20px;   
}
.traioningfnewcreatemaindiv {
    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50px;
    box-shadow: 0 0 5px 0 #ccc;
    column-gap: 7px;
    display: flex;
    justify-content: center;
    /* margin-bottom: 14px; */
    padding: 5px 20px;
    width: 100%;
    text-decoration: none;
}

.eductrtraingdiv {
    margin-top: 30px;

}

.bottomsinfledivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
    padding: 10px 20px;
    flex-wrap: wrap;
    row-gap: 15px;
}

.sinfglepagerightbtm {
    padding-bottom: 20px;
}

.detailsdocrepomain {
    width: 100%;
    height: auto;
    padding: 100px 0px 80px 0px;
}

.detailsdocleft {
    width: 100%;
    max-width: 76%;
    align-self: flex-start;
}

.detailsdocright {
    width: 100%;
    max-width: 21%;
    align-self: flex-start;
}

.thght_txtcrt {
    color: #d3a14587;
    cursor: pointer;
    font-family: NotoSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.crtevnttxtthght_txtdivcrtdct {
    border: 1px solid #d3a150;
    border-radius: 40px;
    padding: 2px 14px;
}

.documntrepodivmainsrch {
    width: 100%;
    position: relative;
}

.serchdivdctxtsrc {
    width: 100%;
    border: none;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 10px 50px 10px 10px;
    border-radius: 10px;
    position: relative;
}

.serchdivdctxtsrc:focus {
    outline: 0;
    border: none;
}

.serchiconimg {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 9px;
    right: 12px;
}

.serchiconimg img {
    width: 100%;
    height: 100%;
}

.documentbigsldimage {
    padding: 15px 0px 0px 0px;
}

.imgaelitedvbgmainbgaft {
    width: 100%;
    height: 400px;
    position: relative;
    z-index: 2;
}

.imgaelitedvbgmainbgaft img {
    width: 100%;
    height: 100%;
}

.imgaelitedvbgmainbgaft::after {
    position: absolute;
    content: "";
    background-color: #00000085;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 3;
}

.docuemtslidetextpdiv {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    width: 100%;
    max-width: 73%;
    margin: auto;
}

.docrepotxtspp {
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    font-family: poppinslight;
    line-height: 25px;
}

.documentRepositorycategorydiv {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
}

.documentRepositorycategoryflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 17px;
    row-gap: 17px;
    flex-wrap: wrap;
}

.documentRepositorycategorybg {
    width: 100%;
    max-width: 23.5%;
    min-width: 23.5%;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 10px;
    cursor: pointer;
    border-radius: 15px;
}

.certificateimgdivbg {
    width: 100%;
    height: 80px;
    position: relative;
}

.certificateimgdivbg::before {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #f0e6e6bd;
    content: "";
    z-index: 5;
}

.certificateimgdivbg img {
    width: 100%;
    height: 100%;
}

.topicviweflx {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 30px;
    padding-top: 9px;
}

.texyttpxvwpp {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-family: noirprolight;
    font-weight: 600;
    text-align: center;
}

.itemcategppp {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 7;
}

.itemcategppptext {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-family: notosans;
    font-weight: 600;
    letter-spacing: 1px;
}

.createdcocumentdiv {
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 5px;
    /* margin-bottom: 15px; */
}

.createdcocumentop {
    width: 100%;
    padding: 10px;
}

.createdcocumentflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.documentbrdrprfl {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-clip: padding-box, border-box;
    -webkit-background-clip: padding-box, border-box;
    background-image: #4c3b92;
    background-image: linear-gradient(#fff, #fff), radial-gradient(circle at top left, #ed3b4b, #4c3b92);
    background-origin: border-box;
    border: 4px double #0000;
}

.documentbrdrprflimg {
    width: 100%;
    max-width: 40px;
    height: 40px;
    min-width: 40px;
}

.dcmnttxtpppdf {
    font-size: 14px;
    color: #000;
    text-decoration: none;
    line-height: 18px;
    font-family: nopirprolight;
}

.dcmnttxtpppdflnk {
    text-decoration: none;
}

.documentbrdrprflimg img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    border-radius: 50%;
}

.textthghtpp {
    font-size: 18px;
    line-height: 22px;
    color: #000;
    font-weight: 600;
    font-family: noirprolight;
}

.createdcocumenbottom {
    border-top: 1px solid #ccc;
    padding: 10px;
}

.crtdcmntflx {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}

.articleiconimgdiv {
    width: 25px;
    height: 25px;
}

.articleiconimgdiv img {
    width: 100%;
    height: 100%;
}
.recentdocdivdivpading{
    padding-top: 20px;
}

.recentdocdiv {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    /* margin-bottom: 7px; */
    margin-top: 0px;
}

.recentdocdiv .top {
    width: 100%;
    height: auto;
    padding: 8px 12px 8px 12px;
    border-bottom: 1px solid rgba(198, 198, 210, 0.26);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.recentdoccdiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.articleiconimgdiv {
    width: 25px;
    height: 25px;
}

.articleiconimgdiv img {
    width: 100%;
    height: 100%;
}

.dpocsttxtppp {
    font-family: NoirProRegular;
    font-weight: 600;
    font-size: 20px;
    font-style: normal;
    font-size: 16px;
    line-height: normal;
    color: #263238;
    margin: 0;
}

.recentdocdiv .buttom {
    padding: 11px;
}

.docstxtpflxdaa {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    padding-bottom: 15px;
}

.articleiconimgdiv {
    width: 25px;
    height: 25px;
}

.articleiconimgdiv img {
    width: 100%;
    height: 100%;
}

.dcmnttxtpp {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-family: noirprolight;
    font-weight: 700;
}

.articleoagemain {
    width: 100%;
    height: auto;
    padding: 100px 0px 50px 0px;
}

.articlepagemainbgmain {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    margin-bottom: 7px;
}

.articlepagemainbgmain .top {
    width: 100%;
    height: auto;
    padding: 8px 12px 8px 12px;
    border-bottom: 1px solid rgba(198, 198, 210, 0.26);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.documntrepotxtimgflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.documentRepositoryimgicon {
    width: 25px;
    height: 25px;
}

.documentRepositoryimgicon img {
    width: 100%;
    height: 100%;
}

.prchsetsxppp {
    font-family: NoirProRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: normal;
    color: #263238;
    padding: 0;
    margin: 0;
}

.articlepagemainbgmain .buttom {
    padding: 10px;
}

.dcmntdwnldflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 25px;
    flex-wrap: wrap;
    row-gap: 20px;
    padding: 10px 20px 10px 30px;
}

.documentRepositorycategorybg {
    width: 100%;
    max-width: 22%;
    min-width: 22%;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 10px;
    cursor: pointer;
    border-radius: 15px;
}

.certificateimgdivbg {
    width: 100%;
    height: 80px;
    position: relative;
}

.certificateimgdivbg::before {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #f0e6e6bd;
    content: "";
    z-index: 5;
}

.certificateimgdivbg img {
    width: 100%;
    height: 100%;
}

.itemcategppp {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 7;
    width: 100%;
}

.itemcategppptext {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-family: notosans;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
}

.topicviweflx {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 30px;
    padding-top: 9px;
}

.datetxtpp {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-family: notosans;
    font-weight: 700;
    text-align: center;
}

.fullarticlesinglesection {
    width: 100%;
    height: auto;
    padding: 100px 0px 50px 0px;
}

.fullarticleviewbg {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 10px;
    border-radius: 10px;
}

.allarticlevwimgdivmai {
    width: 100%;
    max-width: 100%;
    height: 300px;
}

.allarticlevwimgdivmai img {
    width: 100%;
    height: 100%;
}

.purchaseprfolileflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.articleprofiletextflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.profilearticlebrdr {
    border: 2px solid #fac00d;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.purcahseprofile {
    width: 100%;
    max-width: 45px;
    min-width: 45px;
    height: 46px;
}

.purcahseprofile img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-position: center;
}

.purchseprflnametext {
    font-size: 15px;
    line-height: 18px;
    color: #000;
    font-weight: 700;
    font-family: noirprolight;
}

.downladimgmainpl {
    width: 25px;
    height: 25px;
    position: relative;
}

.downladimgmainpl img {
    width: 100%;
    height: 100%;
}

.articleversntext {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    line-height: 14px;
    font-family: noirprolight;
    padding: 5px 0px 3px 0px;
}

.articleversntext span {
    font-size: 16px;
    font-weight: 500;
}

.Sourcingtextppdivall {
    padding: 10px 0px 0px 0px;
}

.Sourcingtextpp {
    font-size: 18px;
    line-height: 22px;
    color: #000;
    font-weight: 800;
    font-family: notosans;
    padding: 5px 0px 10px 0px;
}

.purchsesrcingtext {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-weight: 500;
    font-family: noirprolight;
}

.Sourcingtextppdivall {
    padding: 10px 0px 0px 0px;
}

.purchasetextdivppcheckbx label {
    font-size: 14px;
    color: #000;
    font-weight: 600;
    line-height: 18px;
    font-family: noirprolight;
    margin-left: 10px;
    margin-bottom: 0px;
}

.purchasetextdivppcheckbx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0px 0px 0px;
}

.completionassenmntflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}

.completeaseentbtn {
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #d3a145;
    border: 1px solid #fac00d;
    color: #000;
    font-size: 14px;
    line-height: 18px;
    font-family: notosans;
    padding: 5px;
    border-radius: 10px;
    column-gap: 8px;
}

.Answerquizdivimg {
    width: 25px;
    height: 25px;
}

.Answerquizdivimg img {
    width: 100%;
    height: 100%;
}

.enlargedNewEnrollmentsection {
    width: 100%;
    height: auto;
    padding: 100px 0px 50px 0px;
}

.webdevloperenrollflx {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.developerbootcampdetails {
    width: 100%;
    max-width: 60%;
}

.developerbootcampvdo {
    width: 100%;
    max-width: 38%;
}

.devloperbtcmptxt {
    font-size: 30px;
    line-height: 35px;
    font-family: Notosans;
    color: #000;
    font-weight: 600;
}

.ratingclckrewardflx {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    column-gap: 20px;
    padding: 10px 0px;
}

.imgtxtdivmainddd {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    column-gap: 2px;
}

.strimgdiv {
    width: 25px;
    height: 25px;
}

.tsgyutppp {
    font-size: 11px;
    color: #000;
    font-weight: 600;
    font-family: 'NotoSans';
    line-height: 15px;
    align-self: center;
}

.descriptionanswerdiv {
    padding-top: 10px;
}

.destxtption {
    font-size: 21px;
    line-height: 23px;
    font-weight: 800;
    color: #000;
    font-family: 'NoirProRegular';
}

.lastmonthtxtdestxt {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: #000;
    font-family: noirprolight;
    padding-top: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    margin-bottom: 20px;
}

.trainingmaindivboot {
    width: 100%;
    height: 100%;
}

.traingmaindivmain {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.trainingmaindivboot img {
    width: 100%;
    height: 100%;
}

.enrolldivnowbtn {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    background-color: #d3a145;
    padding: 5px 20px;
    letter-spacing: 1px;
    font-family: notosans;
    border-radius: 5px;
    text-align: center;
    width: 100%;
    border: none;
    margin: 10px 0px;
}

.newenrollmentdivlrninclentnflx {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding-top: 20px;
}

.newlerndivmainwidth {
    width: 100%;
    max-width: 68%;
}

.newlerndivmainleft {
    width: 100%;
    max-width: 28%;
}

.suggestiondivflx {
    padding-top: 10px;
}

.lrnuniquetxt {
    font-size: 21px;
    line-height: 23px;
    font-weight: 800;
    color: #000;
    font-family: 'NoirProRegular';
    padding-bottom: 10px;
}

.suggestiondivflx .slick-next {
    line-height: 0;
    position: absolute;
    padding-top: 11px !important;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 4;
    top: 30%;
    right: -2%;
    width: 40px;
    height: 40px;
}

.suggestiondivflx .slick-next:before {
    content: '\f105' !important;
    font-family: FontAwesome !important;
    font-size: 27px !important;
    line-height: 1;
    opacity: 1 !important;
    color: rgb(255, 255, 255) !important;
    display: flex;
    background-color: #d3a145;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15), 1px 2px 5px 0px rgba(0, 0, 0, 0.15) inset;
}

.suggestiondivflx .slick-disabled {
    opacity: 0;
}

.suggestiondivflx .slick-prev {
    line-height: 0;
    position: absolute;
    padding-top: 11px !important;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 4;
    top: 30%;
    left: -1.5%;
    width: 40px;
    height: 40px;
}

.suggestiondivflx .slick-prev:before {
    content: '\f104' !important;
    font-family: FontAwesome !important;
    font-size: 27px !important;
    line-height: 1;
    opacity: 1 !important;
    color: rgb(255, 255, 255) !important;
    display: flex;
    background-color: #d3a145;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15), 1px 2px 5px 0px rgba(0, 0, 0, 0.15) inset;
}

.dotstxtpp span {
    color: #00000081;
    font-size: 15px;
    line-height: 19px;
    font-family: Noirprolight;
}

.quizebtnmmainiinbsibtn {
    border: none;
    background-color: #64a382;
    border-radius: 10px;
    color: #fff;
    font-family: noirprolight;
    font-size: 16px;
    font-weight: 800;
    line-height: 18px;
    padding: 7px 20px;
}

.qiizesectionmain {
    width: 100%;
    height: auto;
    padding: 100px 0px 80px 0px;
}

.quizedatadivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.quizedatadivleft {
    width: 100%;
    max-width: 76%;
    align-self: flex-start;
}

.quizedatadivright {
    width: 100%;
    max-width: 21%;
    align-self: flex-start;
}

.quizedatadivleftbgmain {
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 15px;
}

.quizedatadivleftbgmaintop {
    border-bottom: 1px solid #ccc;
    padding: 10px 10px;
    width: 100%;
}

.quizemainheadrtxtpp {
    font-family: NoirProRegular;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: normal;
    color: #263238;
    padding: 0;
    margin: 0;
    margin-left: 10px;
}

.quizedatadivleftbgmainbottom {
    height: 394px;
    overflow-y: auto;
    padding: 10px;
}

.feedbnckjhsdivul {
    padding-left: 20px;
}

.feedbnckjhsdivli {
    font-family: NoirProRegular;
    font-size: 20px;
    font-weight: 500;
    list-style-type: decimal;
    padding-bottom: 10px;
    padding-left: 8px;
}

.cjhekeddivtxtquizedrp label {
    font-size: 14px;
    color: #000;
    font-weight: 600;
    font-family: notosans;
    margin-bottom: 0px;
}

.cjhekeddivtxtquizedrp input {
    display: block;
    width: 100%;
    max-width: 15px;
    min-width: 15px;
    height: 15px;
    border: 2px solid #000;
}

.cjhekeddivtxtquizedrp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    margin: 8px 0px;
}

.quizedivwidthf {
    background-color: #fff;
    box-shadow: 0px 0pc 5px 0px #ccc;
    padding: 9px;
    border-radius: 5px;
}

.questiontxtquize {
    font-size: 15px;
    line-height: 20px;
    color: #000;
    font-family: notosans;
    font-weight: 600;
}

.radiobtntxtppquizeansradionew input {
    display: block;
    width: 100%;
    max-width: 15px;
    min-width: 15px;
    height: 15px;
    border: 2px solid #000;
}

.radiobtntxtppquizeansradionew label {
    font-size: 14px;
    color: #000;
    font-weight: 600;
    font-family: notosans;
    margin-bottom: 0px;
}

.radiobtntxtppquizeansradionew {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    margin: 8px 0px;
}

.sbmtdbtbhmaiondivquize {
    background-color: #64a382;
    padding: 8px 15px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    font-family: Noirprolight;
    border: none;
    border-radius: 5px;
    margin: auto;
    display: flex;
    justify-content: center;
}

.certificatediv {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
}

.certificatediv .top {
    width: 100%;
    padding: 6px;
    border-bottom: 1px solid #ccc;
}

.certificatedivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.mandatorydivimgquz {
    width: 25px;
    height: 25px;
}

.mandatorydivimgquz img {
    width: 100%;
    height: 100%;
}

.cmpltquzetxt {
    font-family: NoirProRegular;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: normal;
    color: #263238;
    padding: 0;
    margin: 0;
    margin-left: 10px;
}

.certificatediv .buttom {
    padding: 10px;
}

.certificatedivimage {
    width: 100%;
    height: 142px;
}

.certificatedivimage img {
    width: 100%;
    height: 100%;
}

.upnextdiv {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
}

.upnextdiv .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 6px;
    border-bottom: 1px solid #ccc;
}

.badgedivsectnflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.mandatorydivimgquz {
    width: 25px;
    height: 25px;
}

.mandatorydivimgquz img {
    width: 100%;
    height: 100%;
}

.cmpltquzetxt {
    font-family: NoirProRegular;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: normal;
    color: #263238;
    padding: 0;
    margin: 0;
    margin-left: 10px;
}

.upnextdiv .buttom {
    padding: 10px;
}

.mandatorydivbg {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 12px;
}

.traingmaindivmain {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.traingmaindivmain img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.mandttxtpp {
    color: #000;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    font-family: noirprolight;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 7px 3px;
    height: 37px;
}

.writertxtpp {
    color: #000;
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    font-family: noirprolight;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 7px 3px;
}

.imgtxtdivmaindddflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 8px;
}

.imgtxtdivmainddd {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    column-gap: 2px;
}

.strimgdiv {
    width: 25px;
    height: 25px;
}

.strimgdiv img {
    width: 100%;
    height: 100%;
}

.tsgyutppp {
    font-size: 11px;
    color: #000;
    font-weight: 600;
    font-family: 'NotoSans';
    line-height: 15px;
    align-self: center;
}

.upnexrttemphjjdivbtm {
    padding-top: 20px;
}

.crscntntdivtbl {
    width: 100%;
    max-width: 100%;
}

.cousreimgdmain {
    width: 100%;
    max-width: 100px;
    min-width: 100px;
    height: 76px;
}

.cousreimgdmain img {
    width: 100%;
    height: 100%;
}

.crsecntntxt {
    color: #000;
    font-family: notosans;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
}

.timeimgdiv {
    height: 25px;
    width: 25px;
}

.timeimgdiv img {
    width: 100%;
    height: 100%;
}

.hrsmnttxtpp {
    color: #000;
    font-family: noirprolight;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
}

.crsecntntxtdivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
}

.viothcntriioodiv {
    width: 100%;
    max-width: 18%;
    min-width: 18%;
}

.videocnnjdtxttth {
    width: 100%;
    max-width: 48%;
    min-width: 48%;
}

.videocnttimeth {
    width: 100%;
    max-width: 30%;
}

.coursevideoplayermainsection {
    width: 100%;
    height: auto;
    padding: 100px 0px 80px 0px;
}

.coursevideoplayedivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.coursevideoplayedleft {
    width: 100%;
    max-width: 76%;
    align-self: flex-start;
}

.coursevideoplayedright {
    width: 100%;
    max-width: 21%;
    align-self: flex-start;
}

.coursevideoplayedfdivbg {
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    max-width: 100%;
    padding: 10px;
    width: 100%;
}
.coursevieowdopaplerrdiv{
    width: 100%;
    max-width: 100%;
    height: 350px;
}
.coursevieowdopaplerrdiv iframe{
    width: 100%;
    max-width: 100%;  
    height: 100%;
}
.dotdivbgvdo {
    position: relative;
}
.dotdivbgvdo:before {
    background-color: #d3a145;
    border-radius: 50%;
    content: "";
    height: 20px;
    position: absolute;
    top: 3px;
    width: 20px;
}
.vdodescrptntxtppdiv {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    font-family: notosans;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    margin: 10px 0 10px 25px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.mplyetextppcrse {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #000;
    display: -webkit-box;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 8px 0;
    overflow: hidden;
    text-overflow: ellipsis;
}
.trainerdetailsdownloadshreflx {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
}
.ratinhghjdibbmnammain{
    margin: 5px 0px;
}
.trainerprfltrainerdtls {
    align-items: center;
    column-gap: 10px;
    display: flex;
    justify-content: flex-start;
}
.prfledtslbrdrtrainer {
    border: 3px solid #fac00d;
    border-radius: 50%;
}
.prfledtslbrdrtrainerimg {
    height: 100%;
    max-width: 47px;
    min-width: 47px;
    width: 47px;
}
.prfledtslbrdrtrainerimg img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
}
.trainermanetxt {
    color: #000;
    font-family: noirprolight;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
}
.trainermanetxt{
    color: #000;
    font-family: noirprolight;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
}
.dateshowlinkdivimghhshjh{
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
}
.dateshowlinkdivimghhshjh img{
    width: 100%;
    height: 100%;
}
.trainingbbrtrrfsghdicnmmabrdr{
    border-top: 1px solid #00000096;
}
.crtdtxtppspcl, .dsrtptxtpp, .empytxtppeng, .empytxtppeng {
    color: #000;
    font-family: notosans;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    padding-bottom: 5px;
}
.keywrdtagpp {
    color: #000;
    font-family: noirprolight;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}
.trainingbbrtrrfsghdicnmmabrdrpadinjjh{
    padding: 10px 0px 0px 0px;
}
.coursecontentcommapn{
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    margin-top: 0px;
}
.couusersecntntop{
    width: 100%;
    height: auto;
    padding: 8px 12px 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
}
.crssntshhtoptxtpp{
    font-family: NoirProRegular;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: normal;
    color: #263238;
    padding: 0;
    margin: 0;
    margin-left: 10px;
}
.couusersecntnbtm{
    padding: 10px;
}
.arrowdivtxtdivflx{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 5px 10px;
    border-radius: 5px;
}
.arrowdivtxtdivflx i{
    font-size: 14px;
    color: #000;
}
.vdoplylusttxtpp{
    font-size: 14px;
    color: #000;
    font-family: notosans;
    font-weight:600;
    line-height: 17px;
    margin-left: 6px;
}
.tableconttxtcrsrtdiv{
    width: 100%;
    border-collapse: separate;
}
.coustrsnttdibimhg {
    width: 100%;
    max-width: 60px;
    min-width: 60px;
    height: 60px;
}
.coustrsnttdibimhg img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.partcniidbdibbjjdflx{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px
}
.partttstnonjtxtp{
    font-size: 12px;
    color: #000;
    font-weight: 600;
    font-family: notosans;
    line-height: 14px;
}
.parttcnttsggtxfhupsjjdspo{
    font-size: 14px;
    color: #000;
    font-weight: 500;
    font-family: notosans;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    width: 100%;
    max-width: 90px;  
}
.timedimnhhshcnoyysbvdodibvflx{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    column-gap: 5px;
}
.timedivbndinnfjmimg{
    width: 100%;
    max-width: 20px;
    min-width: 20px;
    height: 20px;
}
.timedivbndinnfjmimg img{
    width: 100%;
    height: 100%;
}
.timeuygsggttxtcntdyttctx{
    font-size: 12px;
    color: #000;
    font-weight: 500;
    font-family: notosans;
    line-height: 14px; 
}
.tsblleuujcjoint{
    border-collapse: separate;
    padding: 0px 0px 4px 0px;
    display: block;
    border-bottom: 1px solid #ccc;
    margin: 4px 0px;

}
.tsblleuujcjoint:last-child{
    padding: 0px 0px 4px 0px;
}
.tableconttxtcrsrtdivmainbtm{
    height: 268px;
    overflow-y: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.tableconttxtcrsrtdivmainbtm::-webkit-scrollbar {
    display: none;
}
 
/* Hide scrollbar for IE, Edge and Firefox */
.tableconttxtcrsrtdivmainbtm {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.quizebtnnsjjkmaoincnthhskj {
    background-color: #396b4f;
    padding: 3px 25px;
    color: #fff;
    font-size: 15px;
    line-height: normal;
    text-align: center;
    border-radius: 20px;
    border: none;
    border: 2px solid #396b4f;
    width: 100%;
}
.quizebnnsdcrdbvooapggdivpashn{
    padding-top: 20px;
}
@media(max-width:1499px) {
    .videoongoing .top {
        padding: 5px 12px 5px 12px;
    }

    .videoongoing .top .head h4 {
        font-size: 15px;
    }

    .trainingmainimgdiv {
        width: 100%;
        max-width: 88px;
        min-width: 88px;
        height: 67px;
    }

    .lrmtxtpp {
        max-width: 70px;
        min-width: 70px;
    }

    .pointshttx {
        font-size: 11px;
    }

}

@media(max-width:1280px) {

    .educatemainleft {
        width: 100%;
        max-width: 72%;
        align-self: flex-start;
    }
    .educatemainright {
        width: 100%;
        max-width: 24%;
        align-self: flex-start;
    }
    .lrmtxtpp {
        max-width: 45px;
        min-width: 45px;
    }

    .dotsdiv {
        margin-right: 5px;
    }

    .dotstxtpp {
        font-size: 11px;

    }

    .prflimgfh {
        width: 100%;
        max-width: 55px;
        height: 55px;
        min-width: 55px;
    }

    .bghhkpp {
        font-size: 16px;
        line-height: 22px;
    }
    .quizedatadivleft{
        width: 100%;
        max-width: 72%;
        min-width: 72%
    }
    .quizedatadivright {
        width: 100%;
        max-width: 24%;
        min-width: 24%;
        align-self: flex-start;
    }
}

@media(max-width:1199px) {
    .bg-play {
        padding: 1px 7px;
        font-size: 12px;
        line-height: 16px;
    }

    .lrmtxtpp {
        color: #263238;
        font-size: 14px;
        line-height: 17px;
    }

    .trainingmainimgdiv {
        width: 100%;
        max-width: 65px;
        min-width: 65px;
        height: 60px;
    }

    .mandatorydivbgmain {
        width: 100%;
        max-width: 31%;
        min-width: 31%;
    }

    .videoongoinddivsingle {
        width: 100%;
        max-width: 30%;
        border-bottom: 1px solid #ccc;
    }

    .prfltxtflx {
        column-gap: 7px;
    }

    .prfltxtflx {
        column-gap: 7px;
    }

    .detailsdocleft {
        width: 100%;
        max-width: 67%;
        align-self: flex-start;
    }

    .detailsdocright {
        width: 100%;
        max-width: 30%;
        align-self: flex-start;
    }

    .educatemainleft {
        width: 100%;
        max-width: 70%;
        align-self: flex-start;
    }

    .educatemainright {
        width: 100%;
        max-width: 26%;
        align-self: flex-start;
    }
}

@media(max-width:991px) {
    .educatemainflx {
        flex-wrap: wrap;
        row-gap: 20px;
    }
    .quizedatadivflx{
        flex-wrap: wrap;
    }

    .detailsdocleft {
        width: 100%;
        max-width: 100%;
        align-self: flex-start;
    }

    .detailsdocright {
        width: 100%;
        max-width: 100%;
        align-self: flex-start;
    }

    .crtevnttxtthght_txtdivcrtdct {
        border: 1px solid #d3a150;
        border-radius: 40px;
        padding: 10px 14px;
        width: 100%;
    }

    .documentRepositorycategorybg {
        width: 100%;
        max-width: 31%;
        min-width: 31%;
    }

    .ongoingmainleft {
        width: 100%;
        max-width: 100%;
        align-self: flex-start;
    }

    .ongoingmainright {
        width: 100%;
        max-width: 100%;
        align-self: flex-start;
    }

    .educatemainleft {
        width: 100%;
        max-width: 100%;
        align-self: flex-start;
    }

    .educatemainright {
        width: 100%;
        max-width: 100%;
        align-self: flex-start;
    }

    .singleeducatemainflx {
        flex-wrap: wrap;
        row-gap: 20px;
    }
    .webdevloperenrollflx {
        flex-wrap: wrap;
      }
    
      .developerbootcampdetails {
        width: 100%;
        max-width: 100%;
      }
    
      .developerbootcampvdo {
        width: 100%;
        max-width: 100%;
        padding-top: 20px;
      }
    .newenrollmentdivlrninclentnflx{
        flex-wrap: wrap;
    }
    .newlerndivmainwidth {
        width: 100%;
        max-width: 100%;
    }
    .newlerndivmainleft {
        width: 100%;
        max-width: 100%;
        padding-top: 20px;
    }
    .enlargedNewEnrollmentsection {
        padding: 100px 0px 80px 0px;
    }
    .quizedatadivleft{
        width: 100%;
        max-width: 100%;
        min-width: 100%
    }
    .quizedatadivright {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        align-self: flex-start;
        padding-top: 20px;
    }
    .recentdocdiv{
        margin-top: 20px;
    }
}

@media(max-width:767px) {
    .educatemainthreedivs {
        flex-wrap: wrap;
        row-gap: 20px;
    }

    .videoongoinddivsingle {
        width: 100%;
        max-width: 48%;
        border-bottom: 1px solid #ccc;
    }

    .educatemainbg {
        width: 100%;
        max-width: 100%;
    }

    .trainingimg {
        width: 100%;
        height: 265px;
        position: relative;
    }

    .mandatorydivbgmain {
        width: 100%;
        max-width: 48%;
        min-width: 48%;
    }
}

@media(max-width:576px) {
    .videoongoinddivsingle {
        width: 100%;
        max-width: 100%;
        border-bottom: 1px solid #ccc;
    }

    .documentRepositorycategorybg {
        width: 100%;
        max-width: 47%;
        min-width: 47%;
    }

    .detailsdocrepomain {
        width: 100%;
        height: auto;
        padding: 83px 0px 80px 0px;
    }

    .educatemainflx {
        flex-wrap: wrap;
        row-gap: 12px;
    }

    .createdcocumentdiv {
        margin-bottom: 12px;
    }
}

@media(max-width:480px) {
    .trainingimg {
        width: 100%;
        height: 190px;
        position: relative;
    }

    .documentRepositorycategorybg {
        width: 100%;
        max-width: 98%;
        min-width: 98%;
    }

    .mandatorydivbgmain {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
}
.scrolltop_btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9;
}
.scrolltop_btn .chatbtimg {
    padding: 5px 5px;
    font-size: 20px;
    /* background-color: #0C9; */
    background-color: #0c9;
    /* background: linear-gradient(90deg, #e02142, #feb60c); */
    color: #fff;
    text-align: center;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: none;
    animation-name: uparrow;
    animation-duration: 2000ms;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    position: relative;
}
.scrolltop_btn .chatbtimg img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.chatboxdesign {
    background-color: #fff;
    border-radius: 15px;
    bottom: -24px;
    box-shadow: 0 4px 16px #00000040;
    box-shadow: 0 0 5px 0 #ccc;
    height: auto;
    margin: 20px;
    overflow: hidden;
    position: absolute;
    right: 27px;
    width: 319px;
    z-index: 9;
}
.chatbottop {
    align-items: center;
    background-color: #cbc2c23d;
    box-shadow: 0 0 5px 0 #ccc;
    display: flex;
    justify-content: flex-start;
    padding: 10px 15px;
}
.chatbotimg {
    height: 47px;
    max-width: 49px;
    min-width: 49px;
    position: relative;
    width: 100%;
}
.chatbotimg img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
}
.chatdot {
    background: #69de40;
    border: 2px;
    border-radius: 10px;
    bottom: 2px;
    height: 8px;
    left: 37px;
    position: absolute;
    width: 8px;
}
.chatonlnflx {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 11px;
    width: 100%;
}
.ChatBottxt {
    color: #000;
    font-family: fangsong;
    font-size: 23px;
    font-weight: 600;
    line-height: 25px;
}
.chatonln, .txtppchat {
    color: #000;
    font-size: 14px;
    line-height: 20px;
}
.clsusediv {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.clsusediv i {
    color: #000;
    cursor: pointer;
    font-size: 12px;
}
.chatbotttom {
    padding: 10px;
}
.inpittdivcchtbot {
    position: relative;
    width: 100%;
}
.inpittdivcchtbot input {
    background-color: #dadcfd;
    border: none;
    border-radius: 10px;
    font-family: poppinslight;
    font-size: 14px;
    padding: 10px 40px 10px 20px;
    position: relative;
    width: 100%;
}
.postdivomnchatbot {
    height: 25px;
    max-width: 25px;
    min-width: 25px;
    position: absolute;
    right: 8px;
    top: 6px;
    width: 100%;
}
.postdivomnchatbot img {
    height: 100%;
    width: 100%;
}
@media(max-width:991px){
    .scrolltop_btn {
        position: fixed;
        bottom: 76px;
        right: 10px;
        z-index: 9;
    }
}
@media(max-width:480px){
    .chatboxdesign {
        bottom: 17px;
        width: 320px;
        z-index: 9;
    }
}
@media(max-width:420px){
    .chatboxdesign {
        bottom: 28px;
        width: 336px;
        z-index: 9;
        right: 0px;
    }
}
@media (max-width: 391px) {
    .chatboxdesign {
        bottom: 28px;
        width: 275px;
        z-index: 9;
        right: 0px;
    }
}
.thopughtmodabgmain {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #00000069;
    z-index: 99;
}

.thopughtmodabblck {
    width: 100%;
    max-width: 810px;
    background-color: #fff;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* height: 300px; */
    border-radius: 10px;
    overflow: hidden;
}

.crossiconmofdl i {
    font-size: 20px;
    color: #000;
}

.thoyghttop {
    background-color: #dec19c;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 20px;
    padding: 10px;
}

.crtnewpost {
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
}

.crtnewpost img {
    width: 100%;
    height: 100%;
}

.crtpsttxty {
    color: #000;
    font-family: "MontHeavy";
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 30px;
}

.divenrolflx {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.enrolllnowdiv {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    background-color: #d3a145;
    padding: 5px 20px;
    letter-spacing: 1px;
    font-family: notosans;
    border-radius: 5px;
    text-align: center;
    margin-top: 10px;
    border: none;
}

.thoghtmid {
    position: relative;
    width: 100%;
}

.thghtimgmainimhgbrdr {
    display: flex;
    justify-content: center;
    width: 99px;
    height: 102px;
    border-radius: 50%;
    border: 6px solid #fff;
    position: absolute;
    left: 70px;
    top: -21px;
}

.thghtimgmainimhg {
    width: 100%;
    max-width: 90px;
    min-width: 90px;
    height: 91px;
    padding: 0;
}

.thghtimgmainimhg img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.textshthoght {
    width: 100%;
}

.textshthoght textarea {
    height: 70px;
    width: 100%;
    border: 0px solid #ccc;
    resize: none;
    margin-top: 100px;
    margin-bottom: 10px;
    padding: 5px 87px;
    font-size: 14px;
    color: #000;
    font-family: poppinslight;
}

.textshthoght textarea:focus {
    border: none;
    outline: 0;
}

.textshthoght textarea::placeholder {
    color: #d3a15077;
    font-size: 30px;
    font-weight: 400;
    font-family: noirprolight;
}

.thoghtbtm {
    border-top: 1px solid #ccc;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.switch {
    position: relative;
    display: inline-block;
    width: 51px;
    height: 22px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.sliderown {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0px;
    right: 0;
    bottom: 0;
    background-color: #00000029;
    border-radius: 5px
}

.sliderown::before {
    position: absolute;
    content: "";
    height: 21px;
    width: 23px;
    left: 1px;
    bottom: 1px;
    background-color: #fac00d;
    transition: 0.5s all ease-in-out;
    border-radius: 5px;
    transition: 0.5s all ease-in-out;
}

.sliderown.actv::before {
    transform: translateX(26px);
}

.swictvchpublickdiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
}

.empListSelectdiv {
    width: 100%;
    font-size: 13px;
    font-weight: 600;
    font-family: 'Plus Jakarta Sans';
    outline: none;
    border: 1px solid #00000070;
    border-radius: 5px;
    padding: 4px;
    height: 30px;
}

.emplListDiv {
    width: 100%;
}

.allcicnsgrtdivflx {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 20px;
}

.attachshnimgync {
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
    position: relative;

}
.unfollowmodabgmain{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #00000069;
    z-index: 99;  
}
.unfollowbgmmmmm{
    background-color: #fff;
    border-radius: 10px;
    left: 50%;
    max-width: 450px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 2;
}
.unfollowcrsdivfkx{
    align-items: center;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.unfollowdfpppbk{
    color: #000;
    font-family: notosans;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
}
.crossflwax i {
    color: red;
    cursor: pointer;
    font-size: 16px;
}
.unfoolowttxyupdiv {
    padding: 10px;
}
.unfoolowttxyupdivdicflx {
    align-items: center;
    column-gap: 20px;
    display: flex;
    justify-content: flex-start;
}
.profilefollowimg {
    height: 40px;
    max-width: 40px;
    min-width: 40px;
    width: 100%;
}
.profilefollowimg img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
}
.unfoolowttxyupllktxt {
    color: #000;
    font-family: poppinslight;
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
}
.canclebtbnunfolowbtndivflx {
    align-items: center;
    column-gap: 10px;
    display: flex;
    justify-content: center;
    padding-top: 10px;
}
.canclebtbndiv {
    border: 2px double #396b4f   !important;
    border-radius: 40px !important;
    color: #000 !important;
    padding: 5px 16px;
    position: relative;
    font-family: poppinslight;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
}
.unfolowbtndiv {
    background: #396b4f  ;
    border: none;
    border-radius: 40px;
    color: #fff;
    outline: 0;
    padding: 7px 16px;
    font-family: poppinslight;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
}
.attachshnimgync input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    background: transparent;
    border: none;
    box-shadow: none;
    opacity: 0;
}

.attachshnimgync img {
    width: 100%;
    height: 100%;
}

.prt_img {

    width: 100%;
    max-width: 120px;
    min-width: 120px;
    height: 120px;
    position: relative;
}

.close_icon_img {
    position: absolute;
    top: 0;
    right: 0;
}

.prt_img_flx {
    margin-left: 87px;
}

.prt_img img {
    width: 100%;
    height: 100%;
}

.thought_moadal_main .bottom .file_upload {
    margin: 0 9px 0 0;
    position: relative;
    width: 28px;
    height: 30px;
}

.mandatorymodalmaincontent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    background-color: #fff;
    width: 100%;
    max-width: 545px;
    padding: 10px;
}

.vdeoimgmain {
    width: 100%;
    height: 300px;
    position: relative;
}

.vdeoimgmain img {
    width: 100%;
    height: 100%;
}

.divenallbootcmp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
    width: 100%;
    margin: 10px 0px;
}

.divstarimgmain {
    /* width: 100%; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 6px;
}

.divstarimgmainimg {
    width: 25px;
    height: 25px;
}

.divstarimgmainimg img {
    width: 100%;
    height: 100%;
}

.omintxttppp {
    font-size: 12px;
    color: #000;
    font-weight: 500;
}

.decrtxtpp {
    font-size: 18px;
    line-height: 22px;
    font-family: notosans;
    color: #000;
    font-weight: 600;
}

.descriptiondetails {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-weight: 300;
    font-family: noirprolight;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    margin: 10px 0px;
}

.showdescripttext {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    font-weight: 500;
    font-family: Notosans;
    color: #fff;
    cursor: pointer;
    background-color: #d3a145;
    width: 100%;
    max-width: 20%;
    margin: auto;
    padding: 6px;
    border-radius: 8px;
    display: block;
    text-decoration: none;
}

.ediotemodalbg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 717px;
    background-color: #fff;
    z-index: 22;
    border-radius: 10px;
}

.editetopmodal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 8px 20px;

}

.editetxtppbjdl {
    font-size: 18px;
    color: #000;
    font-weight: 600;
    font-family: noirproregular;
    line-height: 22px;
}

.cersgeditpdg i {
    font-size: 16px;
    color: #ea0d0d;
    cursor: pointer;
}

.editebottommodal {
    padding: 15px 20px;
}

.descrptiontxtpp {
    font-size: 18px;
    color: #000;
    font-weight: 600;
    font-family: Poppinslight;
    line-height: 22px;
}

.descriptttxtare {
    width: 100%;
    height: auto;
}

.descriptttxtare textarea {
    width: 100%;
    height: 50px;
    border: none;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 10px;
    padding: 10px;
    resize: none;
    margin: 10px 0px;
}

.descriptttxtare textarea:focus {
    outline: 0;
    border: none;
}

.editcmntmodalbg {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 22;
    max-width: 717px;
}

.editbottommodal {
    width: 100%;
    height: auto;
    padding: 10px 20px;

}

.updtbrnbmain {
    background-color: rgb(0 101 0 / 77%);
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    font-family: noirprolight;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 135px;
    padding: 8px 15px;
    border-radius: 10px;
    margin: 0 auto;
}

.modalallcontentbg {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
    background-color: rgb(0 0 0 / 62%);
}


.srchinputfrm {
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: relative;
}

.crossdivfrm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #fff;
    cursor: pointer;
    margin-left: auto;
}

.srchinputfrm input {
    width: 100%;
    max-width: 100%;
    padding: 15px 20px 15px 50px;
    border: none;
    background-color: #fff;
    outline: 0;
    border-radius: 5px;
    position: relative;
}

.contentfield {
    width: 100%;
    max-width: 100%;
    padding: 10px 15px;
    border: none;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 10px;
}

.bsimgicon .comment-emoji-icon {
    font-size: 25px;
    color: #f7be0d;
    position: relative;
}

.bsimgicon .EmojiPickerReact {
    height: 290px !important;
    width: 350px !important;
    position: absolute;
    top: -181px;
    right: 77px;
}

.srchimggdiv {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 13px;
    left: 10px;
}

.srchimggdiv img {
    width: 100%;
    height: 100%;
}

.listcontntulli {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    line-height: normal;
    padding: 5px 0px 5px 0px;
    list-style-type: none;
}

.crossdivfrm i {
    color: #ffffff;
    font-size: 18px;
}

.srchformcontent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    max-width: 727px;
    width: 100%;
}

.srchinpufldcontntchngfld {
    padding: 10px 0px 0px 0px;
}

.topdivrepstdiv .left {
    display: flex;
    column-gap: 10px;
}

.cmntpprfcdivflxedtjb {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    padding: 10px 15px;
    ;
}

.topdivrepstdiv {
    padding: 0px 15px;
}

.sharethghtsimgrepdygd {
    width: 100%;
    height: 400px;
}

.sharethghtsimgrepdygd img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.top_nextsedipsg {
    padding: 0px 15px;
}

.gallery_areareptsdiv {
    padding: 10px 15px;
}

.cmntpprfcdivflxsigfghbvh {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    width: 100%;
}

.allcardbigbg {
    background-color: #fff;
    border-radius: 10px;
    left: 50%;
    max-width: 955px;
    padding: 10px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 999;
}

.allcardsectionbg {
    max-width: 47%;
    width: 100%;
}

.thankyoucrdbg {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    height: 277px;
    padding: 8px 10px;
    position: relative;
    width: 100%;
}

.profilebgthanky {
    align-items: center;
    align-self: center;
    background-color: #d3a145;
    border-radius: 50%;
    display: flex;
    height: 100px;
    justify-content: center;
    margin: 10px auto auto;
    max-width: 100px;
    width: 100%;
}

.profilebgthankyimg {
    height: 80px;
    max-width: 80px;
    min-width: 80px;
    width: 100%;
}

.profilebgthankyimg img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
}

.thankyubrdrdiv {
    border: 1px solid #c0852f;
    border-radius: 30px;
    color: #000;
    font-family: NotoSans;
    font-size: 14px;
    font-weight: 800;
    margin: 13px auto auto;
    max-width: 38%;
    padding: 5px;
    text-align: center;
    width: 100%;
}

.thankyoutxtppcrd {
    color: green;
    font-family: notosans;
    font-size: 13px;
    font-weight: 500;
    padding: 5px 51px;
    text-align: center;
}

.allcardsectionflx {
    align-items: center;
    column-gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 19px;
    padding-left: 5px;
    padding-top: 19px;
    row-gap: 20px;
}

.happybrthdivmain {
    padding: 20px 0 4px;
    text-align: center;
}

.birthtxtcrdpp,
.happytxtcrdpp {
    color: #fff;
    font-family: notosans;
    text-transform: uppercase;
}

.birthtxtcrdpp {
    font-size: 30px;
    font-weight: 800;
    letter-spacing: .4px;
    line-height: 35px;
}

.profilebgthankywh {
    align-items: center;
    align-self: center;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    height: 96px;
    justify-content: center;
    margin: auto auto 6px;
    max-width: 96px;
    min-width: 96px;
    width: 100%;
}

.profilebgthankyimg {
    height: 80px;
    max-width: 80px;
    min-width: 80px;
    width: 100%;
}

.profilebgthankyimg img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
}

.happybrthdivmain {
    padding: 20px 0 4px;
    text-align: center;
}

.happybrthdivmaintxtp {
    color: #fff;
    font-family: notosans;
    font-size: 13px;
    font-weight: 500;
    padding: 5px 51px;
    text-align: center;
}

.textimagemainwelcmdivflx {
    align-items: center;
    column-gap: 10px;
    display: flex;
    justify-content: space-between;
    padding: 80px 0 0;
}

.algnonbrddivmainwh {
    max-width: 48%;
    width: 100%;
}

.wlcmcrdtxtpp {
    color: #fff;
    font-family: notosans;
    font-size: 20px;
    font-weight: 400;
    line-height: 15px;
    padding-bottom: 15px;
}

.algnonbrddivmainimg {
    max-width: 48%;
    width: 100%;
}

.profilebgthankywh {
    align-items: center;
    align-self: center;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    height: 96px;
    justify-content: center;
    margin: auto auto 6px;
    max-width: 96px;
    min-width: 96px;
    width: 100%;
}

.profilebgthankyimg {
    height: 80px;
    max-width: 80px;
    min-width: 80px;
    width: 100%;
}

.profilebgthankyimg img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
}

.happyannyversytxtppflx {
    align-items: center;
    column-gap: 10px;
    display: flex;
    justify-content: space-between;
    padding-left: 17px;
    padding-right: 15px;
    padding-top: 51px;
}

.happyannyversyimgmain {
    background-color: #fd8f6c;
    max-width: 38%;
    padding: 10px;
    transform: skew(15deg, 348deg);
    width: 100%;
}

.happyannyversyimg {
    height: 100px;
    max-width: 124px;
    min-width: 124px;
    transform: skew(359deg, 1turn);
    width: 100%;
}

.happyannyversyimg img {
    height: 100%;
    width: 100%;
}

.txthappyanniversydiv {
    max-width: 56%;
    width: 100%;
}

.yrscmpltdivmain {
    padding-bottom: 10px;
}

.fiveyrsrtxtpp {
    color: #fd8f6c;
    font-family: notosans;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
}

.fiveyrsrtxtppcmplt {
    color: #fd8f6c;
    font-family: notosans;
    font-weight: 500;
    text-align: center;
}

.happypritydivtxt {
    padding-bottom: 10px;
}

.happyannyvrtxtpp {
    letter-spacing: .6px;
    line-height: 26px;
    color: #fd8f6c;
    font-family: notosans;
    font-weight: 500;
    text-align: center;
}

.wishyutxtpgdpp {
    color: #fd8f6c;
    font-family: notosans;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .6px;
    line-height: 20px;
}

.wishyutxtpgdppsrpp {
    color: #fd8f6c;
    font-family: notosans;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .6px;
    line-height: 20px;
    text-align: center;
}

.happyannyvrtxtppmanme {
    letter-spacing: .6px;
    line-height: 26px;
    color: #fd8f6c;
    font-family: notosans;
    font-weight: 500;
    text-align: center;
}

.allcardonebigbg {
    background-color: #fff;
    border-radius: 10px;
    left: 50%;
    max-width: 425px;
    padding: 10px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 999;
}

.allcardsectionbgonecard {
    max-width: 100%;
    width: 100%;
}

.toinpyrgpltpp {
    width: 100%;
}

.thankyoucrdbg {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    height: 277px;
    padding: 8px 10px;
    position: relative;
    width: 100%;
}

.profilebgthanky {
    align-items: center;
    align-self: center;
    background-color: #d3a145;
    border-radius: 50%;
    display: flex;
    height: 100px;
    justify-content: center;
    margin: 10px auto auto;
    max-width: 100px;
    width: 100%;
}

.profilebgthankyimg {
    height: 80px;
    max-width: 80px;
    min-width: 80px;
    width: 100%;
}

.profilebgthankyimg img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
}

.thankyubrdrdiv {
    border: 1px solid #c0852f;
    border-radius: 30px;
    color: #000;
    font-family: NotoSans;
    font-size: 14px;
    font-weight: 800;
    margin: 13px auto auto;
    max-width: 38%;
    padding: 5px;
    text-align: center;
    width: 100%;
}

.thankyoutxtppcrd {
    color: green;
    font-family: notosans;
    font-size: 13px;
    font-weight: 500;
    padding: 5px 51px;
    text-align: center;
}

.toinpyrgpltpp {
    width: 100%;
}

.toinpyrgpltpp input {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px 0 #ccc;
    width: 100%;
}

.toinpyrgpltpp input {
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 10px;
}

.sndbtnmaibbg {
    align-items: center;
    border-radius: 10px;
    color: #000;
    display: flex;
    font-family: noirprolight;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    margin: auto;
    max-width: 20%;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px 0 #ccc;
    width: 100%;
}

.birthtxtcrdpphead {
    color: #fff;
    font-family: notosans;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
}

.birthtxtcrdpphead {
    font-size: 14px;
    line-height: 18px;
    padding-top: 10px;
    color: #fff;
    font-family: notosans;
    font-weight: 500;
}

.jointimgdgtxtmain {
    display: flex;
    justify-content: flex-start;
    column-gap: 20px;
    padding: 10px 15px;
}

.event_imgmfdll {
    width: 100%;
    max-width: 100px;
    min-width: 100px;
    height: 80px;
}

.event_imgmfdll img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.evtnttsiohkipopx {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: rgb(38, 50, 56);
    display: -webkit-box;
    font-size: 16px;
    font-style: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    max-width: 500px;
    font-weight: 500;
}

.evtnttsiohkipopx span {
    font-size: 15px;
    font-weight: 400;
    margin-left: 10px;
}

.shareimghivh {
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
}

.shareimghivh img {
    width: 100%;
    height: 100%;
}

.shareusereventflx {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 20px;
    margin-left: auto;
}

.evntShortDesc {
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    color: rgb(38, 50, 56);
    display: -webkit-box;
    font-size: 16px;
    font-style: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
}

.evntShortDesc span {
    font-size: 15px;
    font-weight: 400;
    margin-left: 10px;
}



.rewrsdtlsdthf {
    text-align: left;
    font-size: 16px;
    font-family: Noirproregular;
    font-weight: 600;
    padding: 10px 0px;
    width: 100%;
    max-width: 300px;
}
.rewrsdtlsdtdfrwdrdiv{
    text-align: right;
    font-size: 16px;
    font-family: Noirproregular;
    font-weight: 600;
    padding: 10px 0px;
    width: 100%;
    max-width: 300px;
}

.rewrsdtlsdtdf {
    text-align: left;
    font-size: 16px;
    font-family: Noirproregular;
    font-weight: 700;
    padding: 10px 0px;
}


.rewrsdtlsdthfdiv{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    border-bottom: 1px solid #cccccc80;
    padding: 4px 15px;
}


.yutubeyrlsdivimg {
    width: 100%;
    height: 214px;
    max-width: 750px;
    min-width: 750px;
    margin: auto;
}

.yutubeyrlsdivimg img {
    width: 100%;
    height: 100%;
}

.yutubeyrlsdivimgmaindiv {
    padding: 10px;
}

.frmdivmainyuoutuber {
    padding: 5px 30px;
}

.fromnsyiuybusnm {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    color: #000;
    display: -webkit-box;
    font-family: notosans;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 4px;
    overflow: hidden;
    padding-bottom: 5px;
    text-overflow: ellipsis;
}

.fromnsyiuybusnm span {
    font-size: 16px;
    font-weight: 600;
}

.ediotemodalbgrewrddiv {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 450px;
    background-color: #fff;
    z-index: 22;
    border-radius: 10px;
}
.retakeqiizebgmain {
    width: 100%;
    height: 100%;
    background-color: #00000060;
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
}
.retakeqiizebgmainbgwh{
    display: flex;
    width: 100%;
    max-width: 450px;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #d2d2dc;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0px rgb(161, 163, 164);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    border-radius: 10px;
}
.trphyimhgmain {
    width: 100%;
    max-width: 241px;
    min-width: 241px;
    height: 152px;
    margin: auto;
}
.trphyimhgmain img {
    width: 100%;
    height: 100%;
}
.congarttscyyppdiv {
    padding-top: 20px;
}
.congarttscyypp {
    color: #000;
    font-size: 18px;
    line-height: 22px;
    font-family: poppinslight;
    text-align: center;
    font-weight: 600;
    padding-bottom: 10px;
    letter-spacing: 0.5px;
}
.ablernytxtpp {
    color: #000;
    font-size: 16px;
    line-height: 20px;
    font-family: poppinslight;
    text-align: center;
    font-weight: 600;
    padding-bottom: 20px;
    text-align: center;
}
.sbmtdbtbhmaiondivdivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    justify-content: center;
}
.sbmtdbtbhmaiondivno {
    background-color: rgb(222, 11, 11);
    padding: 5px 10px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    font-family: Noirprolight;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}
.sbmtdbtbhmaiondivyes {
    background-color: rgb(0 167 0);
    padding: 5px 10px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    font-family: Noirprolight;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}
.trphyimhgmaindivmnain {
    padding: 20px;
}
.sbmtdbtbhmaiondivdwnldbtn{
    background-color: rgb(0 167 0);
    padding: 10px;
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    font-family: Noirprolight;
    border: none;
    border-radius: 5px;
    margin: auto;
    display: flex;
    justify-content: center;
}
@media(max-width:991px){
    .allcardsectionflx {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .allcardsectionbg {
        max-width: 450px;
        width: 100%;
        min-width: 450px;
       
    }
    .allcardbigbg{
        width: 100%;
        max-width: 845px;
        overflow-x: auto;
    }
}

@media(max-width:576px){
    .textshthoght textarea::placeholder {
        color: #d3a15077;
        font-size: 20px;
        font-weight: 400;
        font-family: noirprolight;
    }
}
@media(max-width:480px){
    .textshthoght textarea::placeholder {
        color: #d3a15077;
        font-size: 18px;
        font-weight: 400;
        font-family: noirprolight;
    }  
    .share_thoughts .top .shrthgprflimg {
        width: 100%;
        max-width: 40px;
        min-width: 40px;
        height: 40px;
        padding: 0;
    }
    .thankyoucrdbg {
        height: 332px;
    }
    .profiletabfrst {
        background-color: #396b4f;
        padding: 3px 12px;
        margin: 5px 0px 5px 0px;
    }
    
    
}
@media(max-width:420px){
    .sharethfghbrdr {
        border: 1px solid rgb(0 101 0 / 77%);
        padding: 2px 15px;
    }
    .thght_txt {
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
    }
    .share_thoughts .top .shrthgprflimg {
        width: 100%;
        max-width: 30px;
        min-width: 30px;
        height: 30px;
        padding: 0;
    }
    .engagedivmaimiddle .share_thoughts .top {
        gap: 3%;
    }
}
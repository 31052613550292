.messagesectionmain {
    width: 100%;
    height: auto;
    padding: 100px 0px 50px 0px;

}

.messagediv_maindiv {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.messagedivleft {
    max-width: 20%;
    width: 100%;
}

.messagedivright {
    max-width: 77%;
    width: 100%;
}

.messagedivbgg_top {
    border-bottom: 1px solid #ccc;
    padding: 10px 10px;
    width: 100%;
}

.inptdivtxtdippplcd {
    position: relative;
}

.inptdivtxtdippplcd input {
    border: none;
    outline: 0;
    padding: 10px 30px 10px 40px;
    width: 100%;
}

.srchdivimgicn {
    height: 25px;
    left: 0;
    max-width: 25px;
    min-width: 25px;
    position: absolute;
    top: 11px;
    width: 100%;
}

.srchdivimgicn img {
    height: 100%;
    width: 100%;
}

.messagedivbgg_bottom {
    height: 100vh;
    overflow-y: auto;
    padding: 15px 10px;
    width: 100%;
}

.msgprofiledsgtmflc {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}

.msgprofjdsgfxl {
    align-items: center;
    column-gap: 8px;
    display: flex;
    justify-content: flex-start;
}

.prfldivimhbg {
    height: 50px;
    max-width: 50px;
    min-width: 50px;
    width: 100%;
}

.prfldivimhbg img {
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.profilenamemsgtxt,
.profilenamemsgdsgtxtp {
    -webkit-box-orient: vertical;
    color: #000;
    display: -webkit-box;
    font-family: noirprolight;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    -webkit-line-clamp: 2;
    font-size: 12px;
    line-height: 14px;
    max-width: 135px;
    margin-bottom: 5px;
}

.timedivhpp {
    align-self: flex-start;
}

.timemsgppp {
    color: #000;
    font-family: noirprolight;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
}

.messagedivbgg {
    background-color: #fff;
    border-radius: 15px;
    padding-bottom: 10px;
}

.messagedivrightbg {
    background-color: #fff;
    border-radius: 15px;
    width: 100%;
}

.messagedivrighttop {
    border-bottom: 1px solid #ccc;
    padding: 10px 10px;
    width: 100%;
}

.messagerighttopflxdiv {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.messagerighttopflxdiv {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.messagedivprfldsgrightflx {
    align-items: center;
    column-gap: 10px;
    display: flex;
    justify-content: flex-start;
}

.profilenamemsgdsgtxtprightdsgtop,
.profilenamemsgtxtrighttop {
    -webkit-box-orient: vertical;
    color: #000;
    display: -webkit-box;
    font-family: noirprolight;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    -webkit-line-clamp: 1;
    font-size: 14px;
    line-height: normal;
    max-width: 450px;
    padding-bottom: 5px;
}

.infoimgsicvimg {
    height: 25px;
    max-width: 25px;
    min-width: 25px;
    width: 100%;
}

.infoimgsicvimg img {
    width: 100%;
    height: 100%;
}

.messagedivrightbottom {
    height: 100vh;
    overflow-y: auto;
    padding: 15px 10px;
    width: 100%;
}

.profileotherdiv {
    column-gap: 20px;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 20px;
}

.profileotherdivbg {
    background-color: #deebf7;
    border-radius: 15px;
    max-width: 500px;
    padding: 10px;
    width: 100%;
}

.profileotherdivtxt,
.profileotherdivtxttime {
    color: #000;
    font-family: NoirProLight;
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
}

.profileowndiv {
    column-gap: 20px;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 40px;
    padding-top: 20px;
}

.profileotherdivbgsntimg {
    align-items: flex-end;
    column-gap: 10px;
    display: flex;
    justify-content: flex-start;
}

.profileowndivbg {
    background-color: #deebf7;
    border-radius: 15px;
    max-width: 500px;
    padding: 10px;
    position: relative;
    width: 100%;
}

.profilearrowdiv {
    background-color: #deebf7;
    border-bottom-right-radius: 80px;
    border-top-right-radius: 80px;
    display: block;
    height: 13px;
    position: absolute;
    right: -21px;
    top: 0;
    width: 60px;
}

.snttxtpppcbh {
    color: #000;
    font-family: NoirProLight;
    font-size: 14px;
    font-weight: 800;
    line-height: normal;
}

.senncheckdivimg {
    height: 20px;
    max-width: 20px;
    min-width: 20px;
    width: 100%;
}

.senncheckdivimg img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
}

.messagedivrightbottomsend {
    width: 100%;
    border-top: 1px solid #ccc;
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    flex: none;
    width: 100%;
    min-height: 65px;
    padding: 0;
}

.texticonimgsaa {
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    position: relative;
    width: 100%;
    z-index: 2;
    padding-right: 8px;
}

.inputtypeprfls {
    height: 30px;
    left: 0;
    max-width: 30px;
    min-width: 30px;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4;
}

.inputtypeprfls input {
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    width: 100%;
}

.texticonimgsaa img {
    height: 100%;
    width: 100%;
}

.cameraspclarea {
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    width: 100%;
    padding-right: 8px;
}

.cameraspclarea img {
    height: 100%;
    width: 100%;
}

.micaaspclarea {
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    width: 100%;
    padding-right: 8px;
}

.micaaspclarea img {
    height: 100%;
    width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.messagedivrightbottom::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.messagedivrightbottom {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.messagedivbgg_bottom::-webkit-scrollbar {
    display: none;
}

.messagedivbgg_bottom {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.textardibbrdrdivmaindiv {
    max-height: 11.76em;
    min-height: 1.47em;
    -webkit-user-select: text;
    user-select: text;
    white-space: pre-wrap;
    word-break: break-word;
}

.postmessage {
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 52px;
    padding: 5px 0;
}

.postmessage img {
    width: 100%;
    height: 100%;
}

.messagedivsrchdivflx {
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    max-width: 100%;
    min-height: 64px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #f0f2f5;
    border-left: 1px solid #e9edef;
    /* border-radius: 10px; */
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.msgicondivallsivtextflx {
    position: relative;
    flex: 1;
    width: 100%;
    min-width: 0;
    min-height: 52px;
}

.msgicondivallsiv_aklli {
    display: flex;
    align-items: flex-end;
}

.msgicondivallsiv_akllitilu {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 52px;
    padding: 5px 0;
}

.textardibbrdrdivspclakiltuy {
    display: flex;
    flex: 1;
    align-items: end;
    min-width: 0;
}

.textardibbrdrdivcntntdiv {
    box-sizing: border-box;
    flex: 1 1 auto;
    width: inherit;
    min-width: 0;
    min-height: 20px;
    padding: 9px 12px;
    margin: 5px 8px;
    font-size: 15px;
    font-weight: normal;
    line-height: 20px;
    background-color: #fff;
    border-color: #fff;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    outline: none;
    will-change: width;
    display: flex;
}

.scallabletetxtedivmsg {
    -webkit-user-select: text;
    text-indent: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    direction: ltr;
    user-select: text;
}

.textardibbrdrdivmaindiv:focus {
    outline: 0;
    border: none;
}
.textardibbrdrdivmaindiv.actvnew {
    max-height: 11.76em;
    min-height: 1.47em;
    -webkit-user-select: text;
    user-select: text;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-y: auto
}
.sidebardivensurediv {
    width: 100%;
    max-width: 250px;
    background-color: #4caf50b8;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 110px;
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: auto;
    padding-bottom: 30px;
}


.dashborddivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dsgbrdrttpp {
    color: #000000;
    font-size: 18px;
    line-height: 18px;
    font-family: popinslight;
}

.dashbrdricondivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.dashbrdricondivflx i {
    font-size: 18px;
    color: #000000;
}

.envhjtygljspbn {
    display: block;
    color: #000000;
    background-color: #000000;
    width: 100%;
    max-width: 8px;
    min-width: 8px;
    height: 8px;
    border-radius: 50%;
}

.envhjtygljspbndivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    padding-bottom: 10px;
}

.bsrdivtabdivlft {
    padding-left: 45px;
    padding-top: 10px;
    padding-bottom: 20px;
}

.bsrdivtabdivlftdivflx {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.princoiplebtnmain {
    background-color: #fff;
    padding: 3px 25px;
    color: #000;
    font-size: 15px;
    line-height: normal;
    text-align: center;
    border-radius: 20px;
    border: none;
    margin-bottom: 15px;
}

.princoiplebtnmainactb {
    background-color: #298bab;
    padding: 3px 25px;
    color: #fff;
    font-size: 15px;
    line-height: normal;
    text-align: center;
    border-radius: 20px;
    border: none;
    margin: 15px 0px 0px 20px;
}

.princoiplebtnmainactbchng {
    background-color: #298bab;
    padding: 3px 25px;
    color: #ffffff;
    font-size: 15px;
    line-height: normal;
    text-align: center;
    border-radius: 20px;
    border: none;
}

.princoiplebtnmainkk {
    background-color: #fff;
    padding: 3px 25px;
    color: #000;
    font-size: 15px;
    line-height: normal;
    text-align: center;
    border-radius: 20px;
    border: none;
    margin: 15px 0px 0px 0px;
}

.simlettxghjpemidjjlklettxp {

    font-size: 15px;
    color: #000;
    font-weight: 600;
    font-family: notosans;
    line-height: 16px;
    text-align: center;
}

.emplkkdjujhtxttjopstxtp {
    text-align: left;
    font-size: 15px;
    color: #000;
    font-weight: 500;
    font-family: notosans;
    line-height: 16px;
}

.brstgdutxtpp {
    font-size: 14px;
    line-height: normal;
    color: #000;
    padding-bottom: 10px;
}

.empliyesworkesdivflxdsjkdivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 140px;
}

.borderdivleft {
    padding-top: 20px;
    position: relative
}

/* .borderdivleft::before{
    position: absolute;
    content: "";
    border-left: 1px solid #0000007a;
    width: 1px;
    height: 482px;
    left: 4px;
    top: 30px;
} */

.envhjtygljspbndivflx i {
    font-size: 18px;
}

.envhjtygljspbndivflxbotmiocn {
    padding-top: 20px;
}

.sidebardetialsdivmain {
    padding-top: 110px;
    padding-left: 260px;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.sidebardivensurediv::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebardivensurediv {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* PrincilpleOne Css Start */
.principletxtpp {
    font-size: 20px;
    font-weight: 600;
    font-family: 'NotoSans';
    color: #000;
    line-height: 25px;
}

.borderessentialin {
    border: 1px solid #0006;
    margin: 30px 0px 10px 0px;
}

.pricpletacvwidh {
    width: 100%;
    max-width: 95%;
    margin: auto;
}

.idicatrstxtpp {
    font-size: 16px;
    color: #000;
    font-weight: 800;
    font-family: 'NotoSans';
    text-align: center;
    border-bottom: 1px solid #0006;
    padding: 5px;
}

.tddivmainbrdrlhhhnewhil {
    border-top: 1px solid #0006;
    border-bottom: 0px solid #0006;
    padding: 4px;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    font-family: noirprolight;
    place-items: start;
    vertical-align: top;
}

.listoftblxsdiv {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    font-family: poppinslight;
    margin-left: 12px;
    margin-bottom: 15px;
    padding-left: 10px;
}

.listoftblxsdivul {
    padding: 10px 15px;
    list-style-type: decimal;
}



.tablbrdrmain {
    border: 1px solid #0006;
    padding: 10px;
    /* margin: 20px; */
    border-collapse: collapse;
    width: 100%;
}

.thdivmainbrdr {
    border: 1px solid #0006;
    padding: 10px;
    color: #000;
    font-size: 15px;
    font-weight: 600;
    font-family: 'NotoSans';
    line-height: 19px;
    vertical-align: top;
    text-align: center;
}


.thdivmainbrdrprincplethree {
    border: 1px solid #0006;
    padding: 10px;
    color: #000;
    font-size: 17px;
    font-weight: 800;
    font-family: noirproregular;
    width: 100%;
    max-width: 10%;
}

.thdivmainbrdrnewtwo {
    padding: 10px;
    color: #000;
    font-size: 18px;
    font-weight: 800;
    font-family: noirproregular;
    width: 100%;
    max-width: 9%;
}

.tddivmainbrdr {
    border: 1px solid #0006;
    padding: 4px;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    font-family: noirprolight;
    place-items: start;
    vertical-align: top;
}

/* .tddivmainbrdr textarea {
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    resize: none;
    height: 60px;
    width: 100%;
} */
/* 
.tddivmainbrdr textarea:focus {
    outline: 0;
    border: none;
} */

.tddivmainbrdrspclnewone {
    border: 1px solid #0006;
    padding: 4px;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    vertical-align: top;
    font-family: noirprolight;
    /* width: 100%;
    max-width: 20%; */
}

.tddivmainbrdrspclnewone textarea {
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    resize: none;
    height: 60px;
    width: 100%;
}

.tddivmainbrdrspclnewone textarea:focus {
    outline: 0;
    border: none;
}

.listoftblxsdivuldiv {
    padding: 10px 0px;
}

.monitrxybtdrdr {
    border-top: 1px solid #0006;
    border-left: 1px solid #0006;
    border-right: 1px solid #0006;
}

.monitrxybtdrdrtop {
    border-left: 1px solid #0006;
    border-right: 1px solid #0006;
}

.mnrtfctxtpp {
    text-align: center;
    color: #000;
    font-size: 18px;
    font-weight: 800;
    font-family: notosans;
}

.raditrbtntxt label {
    font-size: 18px;
    line-height: normal;
    font-weight: 600;
    font-family: 'NotoSans';
    margin-left: 5px;
    margin-bottom: 0px;
}

.raditrbtntxt input {
    display: block;
    width: 18px;
    height: 18px;
}

.raditrbtntxt {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.yestrxtwarea textarea {
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    resize: none;
    height: 60px;
    width: 100%;
    margin-top: 6px;
}

.yestrxtwarea textarea:focus {
    outline: 0;
    border: none;
}

.thdivmainbrdrcategry {
    border: 1px solid #000;
    padding: 10px;
    color: #000;
    font-size: 17px;
    font-weight: 800;
    font-family: noirproregular;
    width: 100%;
    max-width: 10%;
}

.thdivmainbrdrcpercnt {
    padding: 10px;
    color: #000;
    font-size: 17px;
    font-weight: 800;
    font-family: noirproregular;
    text-align: center;
}

.gap {
    border-top: 1px solid #0006;
}

.listoftblxsdiv span {
    font-weight: bold;
}

.adddinciondivimg {
    width: 100%;
    max-width: 140px;
    min-width: 140px;
    height: 25px;
    margin-left: auto;
    margin-top: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    border: none;
    background-color: transparent;
    color: #205901;
    cursor: pointer;
}

.adddinciondivimg img {
    width: 25px;
    height: 25px;
    margin-right: 4px;
}

.adddinciondivimg span {
    font-size: 15px;
    font-weight: 500;
}


.btdrffsumtrddibpadindiv {
    font-size: 14px;
    color: #000;
    font-weight: 500;
    font-family: notosans;
    line-height: 17px;
    margin: 0px 0px 5px 0px;
}

.dtlsttfvdvgppphjp {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    font-family: notosans;
    line-height: 17px;
    margin: 5px 0px;
}

.healthysftydivul {
    list-style-type: lower-alpha;
    padding: 0px 15px;
}

.healthysftydivli {
    font-size: 17px;
    color: #000;
    font-weight: 400;
    font-family: poppinslight;
    margin-left: 30px;
    margin-bottom: 10px;
}

.healthysftydivli span {
    font-weight: bold;
}

.yesnobtndivmain {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 20px;
    padding: 5px 0px
}

.raditrbtntxtnemko {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.raditrbtntxtnemko input {
    display: block;
    width: 18px;
    height: 18px;
}

.raditrbtntxtnemko label {
    font-size: 20px;
    line-height: normal;
    font-weight: 700;
    font-family: noirproregular;
    margin-left: 5px;
    margin-bottom: 0;
}

.thdivmainbrdrthredprcl {
    border: 1px solid #000;
    padding: 10px;
    color: #000;
    font-size: 17px;
    font-weight: 800;
    font-family: noirproregular;
    width: 100%;
    max-width: 48%;
}

.thdivmainbrdrthredprclwhett {
    border: 1px solid #000;
    padding: 10px;
    color: #000;
    font-size: 17px;
    font-weight: 800;
    font-family: noirproregular;
    width: 100%;
    max-width: 33%;
}

.healthysftydivulmain {
    padding-bottom: 15px;
}

.thdivmainbrdrnewleft {
    border-left: 1px solid #0006;
}

.thdivmainbrdrtotalleft {
    border-left: 1px solid #0006;
    padding: 10px;
    color: #000;
    font-size: 17px;
    font-weight: 800;
    font-family: noirproregular;
}

.idicatrstxtppsixprncple {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    font-family: noirproregualr;
    text-align: center;
    padding: 5px;
}

.principlexisindicatorsdiv {
    padding: 20px 0px;
}

.frmnewabledivtr {
    font-size: 14px;
    color: #000;
    font-weight: 600;
    text-align: left;
}

.frmnewabledivtrcnrtr {
    font-size: 14px;
    color: #000;
    font-weight: 600;
    text-align: center;
    border-bottom: 1px solid #000;
}

.tddivmainbrdrspclnewone span {
    font-weight: 600;
    font-size: 14px;
}

.noteindicatepp {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    line-height: 18px;
    padding-top: 10px;
    color: #000;
    font-family: notosans;
}

.noteindicatepp span {
    font-weight: 600;
    color: #000;
    /* margin-right: 10px; */
}

.rporschtxtpp {
    font-size: 17px;
    color: #000;
    font-weight: 400;
    font-family: poppinslight;
    margin-left: 30px;
    margin-bottom: 15px;
}

.listoftblxsdivsicxprivn {
    font-size: 17px;
    color: #000;
    font-weight: 400;
    font-family: poppinslight;
    margin-left: 42px;
    margin-bottom: 15px;
    list-style-type: lower-roman;
}

.areatextaresdiv textarea {
    width: 100%;
    max-width: 90%;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    resize: none;
    height: 60px;
    width: 100%;
    margin-top: 0px;
    margin-left: 40px;
}

.areatextaresdiv textarea:focus {
    outline: 0;
}

.sixdivmaiondio {
    margin-bottom: 10px;
}

.thdivbrdrwdthfrst {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.thdivbrdrwdthscnd {
    width: 100%;
    max-width: 23%;
    min-width: 23%;
}

.thdivbrdrwdththrd {
    width: 100%;
    max-width: 47%;
    min-width: 47%;
}

.thdivbrdrwdthlst {
    width: 100%;
    max-width: 17%;
    min-width: 17%;
}

.thchngwidthfrst {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.thchngwidthscnd {
    width: 100%;
    max-width: 18%;
    min-width: 18%;
}

.thchngwidththrd {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.thchngwidthfrth {
    width: 100%;
    max-width: 12%;
    min-width: 12%;
}

.thchngwidthffth {
    width: 100%;
    max-width: 27%;
    min-width: 27%;
}

.thchngwidthsxth {
    width: 100%;
    max-width: 5%;
    min-width: 5%;
}

.casethdivwidthfrst {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.casethdivwidthscnd {
    width: 100%;
    max-width: 60%;
    min-width: 60%;
}

.currentdivwidthfrst {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.currentdivwidthscnd {
    width: 100%;
    max-width: 43%;
    min-width: 43%;
}

.currentdivwidththrd {
    width: 100%;
    max-width: 43%;
    min-width: 43%;
}

.currenttsfinnnawidthfrst {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.currenttsfinnnawidthscnd {
    width: 100%;
    max-width: 43%;
    min-width: 43%;
}

.currenttsfinnnawidththrd {
    width: 100%;
    max-width: 43%;
    min-width: 43%;
}

.thdivmainbrdremniwdthfrst {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.thdivmainbrdremniwdthscnd {
    width: 100%;
    max-width: 21.5%;
    min-width: 21.5%;
}

.thdivmainbrdremniwdththrd {
    width: 100%;
    max-width: 21.5%;
    min-width: 21.5%;
}

.thdivmainbrdremniwdthfrth {
    width: 100%;
    max-width: 21.5%;
    min-width: 21.5%;
}

.thdivmainbrdremniwdthffth {
    width: 100%;
    max-width: 21.5%;
    min-width: 21.5%;
}

.thdivmainbrdrfinnsmwiipdinfrst {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.thdivmainbrdrfinnsmwiipdinscnd {
    width: 100%;
    max-width: 43%;
    min-width: 43%;
}

.thdivmainbrdrfinnsmwiipdinthrd {
    width: 100%;
    max-width: 43%;
    min-width: 43%;
}

.thdivmainbrdrninewidthfrst {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.thdivmainbrdrninewidthscnd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.thdivmainbrdrninewidththrd {
    width: 100%;
    max-width: 33.5%;
    min-width: 33.5%;
}

.thdivmainbrdrninewidthfrth {
    width: 100%;
    max-width: 33.5%;
    min-width: 33.5%;
}

.thdivmainbrdrindictrrfsrt {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.thdivmainbrdrindictrrscnd {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.thdivmainbrdrindictrrthrd {
    width: 100%;
    max-width: 35%;
    min-width: 35%;
}

.sectionctxttpppprooitp {
    font-size: 18px;
    font-weight: 600;
    font-family: 'NotoSans';
    color: #000;
    line-height: 20px;
    margin-bottom: 15px;
}

.thisisectiontxtpppricnconettxp {
    font-size: 16px;
    font-weight: 400;
    font-family: notosans;
    color: #000;
    line-height: 20px;
    margin-bottom: 15px;
}

.tablebottom_note {
    font-size: 16px;
    font-weight: 600;
    font-family: notosans;
    color: #000;
    line-height: normal;
    margin-bottom: 5px;
    margin-top: 10px;
}

.textardibbrdrdivmain {
    background-color: #fff;
    width: 100%;
    height: auto;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 10px;
    overflow-y: auto;
    outline: none;
    white-Space: pre-wrap;
    word-Break: break-word;
    font-size: 12px;
    color: #000;
    font-weight: 400;
    font-family: notosans;
}

/* .txtstttqooehyughhxyyx{
    white-Space: pre-wrap;
    word-Break: break-word;
    font-size: 12px;
    color: #000;
    font-weight: 400;
    font-family: notosans;
} */
.rggsgttxbbedxgtsprijnd {
    padding: 10px 0px 0px 0px;
}

.principkttwopomuntfsrt {
    width: 100%;
    max-width: 13%;
}

.principkttwopomuntscnd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.principkttwopomuntthrd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.principkttwopomuntfrth {
    width: 100%;
    max-width: 47%;
    min-width: 47%;
}

.noiriittxoppdjoyyypp {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 5px;
    color: #000;
    font-family: notosans;
    font-weight: 600;
    margin-bottom: 5px;
}

.clsfrextarpading {
    margin-bottom: 10px;
}

.tbshhhqchehcjjtabbdivcsjkio {
    width: 100%;
    border-collapse: collapse;
}

.enitytgtcakksdivfrst {
    width: 100%;
    max-width: 60%;
    min-width: 60%;
}

.enitytgtcakksdivscnd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.enitytgtcakksdivthrd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.ebjsjnftfhshdibfrsrt {
    width: 100%;
    max-width: 9%;
    min-width: 9%;
}

.ebjsjnftfhshdibscnd {
    width: 100%;
    max-width: 9%;
    min-width: 9%;
}

.ebjsjnftfhshdibthrd {
    width: 100%;
    max-width: 11%;
    min-width: 11%;
}

.ebjsjnftfhshdibfrth {
    width: 100%;
    max-width: 9%;
    min-width: 9%;
}

.ebjsjnftfhshdibffth {
    width: 100%;
    max-width: 9%;
    min-width: 9%;
}

.ebjsjnftfhshdibsxth {
    width: 100%;
    max-width: 17%;
    min-width: 17%;
}

.ebjsjnftfhshdibsvnthg {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.ebjsjnftfhshdibegth {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.thhdivvvProductfrst {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.thhdivvvProductscbnd {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.thhdivvvProductthrd {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.thdfIndicatedibppadingfrst {
    width: 100%;
    max-width: 50%;
    min-width: 50%;
}

.thdfIndicatedibppadingscnd {
    width: 100%;
    max-width: 50%;
    min-width: 50%;
}

.thdivmainbrdrhjhjsttxpp {
    border: 0px solid #0006;
    padding: 10px;
    color: #000;
    font-size: 15px;
    font-weight: 600;
    font-family: 'NotoSans';
    line-height: 19px;
    vertical-align: top;
    text-align: center;
}

.Indicatedibjhafsrttsdivfrst {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.Indicatedibjhafsrttsdivscnd {
    width: 100%;
    max-width: 60%;
    min-width: 60%;
}

.enkjhkjgblnmkdivfsrttst {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.enkjhkjgblnmkdivfsrscnnd {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.enkjhkjgblnmkdivfsrthhfrd {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.foresrtggsgochhbrdrrbtmmndiv {
    border-bottom: 1px solid #0006;
}

.thdfIndicatedibppadingthrd {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.thdfIndicatedibppadingfrth {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.enkjhkjgblnmkdivfsrthfrth {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

/* .enkjhkjgblnmkdivfsrthhffth{
    width: 100%;
    max-width: ;
} */
.enkjhkjgblnmkdivfsrthhffth {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.enkjhkjgblnmkdivfsrthhsxth {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.enkjhkjgblnmkdivfsrthhegth {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.enkjhkjgblnmkdivfsrthnnth {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.enkjhkjgblnmkdivfsrthhtntrth {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.enkjhkjgblnmkdivfsrthhfelvnth {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.principlethreefisrcatedivvfrst {
    width: 100%;
    max-width: 8%;
    min-width: 8%;
}

.principlethreefisrcatedivvscnd {
    width: 100%;
    max-width: 7%;
    min-width: 7%;
}

.principlethreefisrcatedivvthrd {
    width: 100%;
    max-width: 17%;
    min-width: 17%;
}

.principlethreefisrcatedivvfrth {
    width: 100%;
    max-width: 17%;
    min-width: 17%;
}

.principlethreefisrcatedivvffth {
    width: 100%;
    max-width: 17%;
    min-width: 17%;
}

.principlethreefisrcatedivvsxth {
    width: 100%;
    max-width: 17%;
    min-width: 17%;
}

.principlethreefisrcatedivvsvnth {
    width: 100%;
    max-width: 17%;
    min-width: 17%;
}

.numberpermanenrepmlydivfrst {
    width: 100%;
    max-width: 8.5%;
    min-width: 8.5%;
}

.numberpermanenrepmlydivscnd {
    width: 100%;
    max-width: 8.5%;
    min-width: 8.5%;
}

.numberpermanenrepmlydivthrd {
    width: 100%;
    max-width: 8.5%;
    min-width: 8.5%;
}

.numberpermanenrepmlydivfrth {
    width: 100%;
    max-width: 8.5%;
    min-width: 8.5%;
}

.numberpermanenrepmlydivfvth {
    width: 100%;
    max-width: 8.5%;
    min-width: 8.5%;
}

.numberpermanenrepmlydivsxth {
    width: 100%;
    max-width: 8.5%;
    min-width: 8.5%;
}

.numberpermanenrepmlydivegtrh {
    width: 100%;
    max-width: 8.5%;
    min-width: 8.5%;
}

.numberpermanenrepmlydivnnth {
    width: 100%;
    max-width: 8.5%;
    min-width: 8.5%;
}

.numberpermanenrepmlydivtnth {
    width: 100%;
    max-width: 8.5%;
    min-width: 8.5%;
}

.numberpermanenrepmlydivtelv {
    width: 100%;
    max-width: 8.5%;
    min-width: 8.5%;
}

.numberpermanenrepmlydivthrtn {
    width: 100%;
    max-width: 8.5%;
    min-width: 8.5%;
}

.prcnttxttemplytxtpp {
    text-align: center;
    font-size: 15px;
    color: #000;
    font-weight: 600;
    font-family: 'NotoSans';
    line-height: 18px;
}

.cttsggrttxtpppc {
    text-align: center;
    font-size: 15px;
    color: #000;
    font-weight: 600;
    font-family: Notosans;
    line-height: 20px;
}

.totyauttxttppttxpa {
    text-align: center;
    font-size: 16px;
    color: #000;
    font-weight: 500;
    font-family: noirproregular;
    line-height: 20px;
}

.mnumbbrttprinvthreetxttppp {
    text-align: center;
    font-size: 15px;
    color: #000;
    font-weight: 600;
    font-family: 'NotoSans';
    line-height: 18px;
}

.Malettsgvsgphsmkjghhutxtpp {
    text-align: left;
    font-size: 15px;
    color: #000;
    font-weight: 500;
    font-family: notosans;
    line-height: 16px;
}

.Malettsgvsgphsmkjghhutxtppttlyd {
    text-align: left;
    font-size: 15px;
    color: #000;
    font-weight: 600;
    font-family: notosans;
    line-height: 20px;
}

.spclebrdrleftwwww {
    border-right: 1px solid #0006;
}

.spclrdvrdrrrightbnbbdiv {
    border-left: 1px solid #0006;
}

.csrtsfibnakjwyystrrdivfstt {
    width: 100%;
    max-width: 33%;
    min-width: 33%;
}

.csrtsfibnakjwyystrrdivscnd {
    width: 100%;
    max-width: 33%;
    min-width: 33%;
}

.csrtsfibnakjwyystrrdivthrd {
    width: 100%;
    max-width: 33%;
    min-width: 33%;
}

.bentsdtffdfsudigbbdffsrt {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.bentsdtffdfsudigbbdfscnd {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.bentsdtffdfsudigbbdfthrd {
    width: 100%;
    max-width: 12%;
    min-width: 12%;
}

.bentsdtffdfsudigbbdffrth {
    width: 100%;
    max-width: 12%;
    min-width: 12%;
}

.bentsdtffdfsudigbbdffth {
    width: 100%;
    max-width: 12%;
    min-width: 12%;
}

.bentsdtffdfsudigbbdsxth {
    width: 100%;
    max-width: 12%;
    min-width: 12%;
}

.bentsdtffdfsudigbbdfsvnth {
    width: 100%;
    max-width: 12%;
    min-width: 12%;
}

.bentsdtffdfsudigbbdfegtth {
    width: 100%;
    max-width: 12%;
    min-width: 12%;
}

.gennjdhhjhbdtdivfrst {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.gennjdhhjhbdtdivscnd {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.gennjdhhjhbdtdivthrddr {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.gennjdhhjhbdtdivfrth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.gennjdhhjhbdtdivffth {
    width: 100%;
    max-width: 15%;
    min-width: 15%
}

.wokekjkekjdnnbjansupyudggdivfrst {
    width: 100%;
    max-width: 50%;
    min-width: 50%;
}

.wokekjkekjdnnbjansupyudggdivscnd {
    width: 100%;
    max-width: 50%;
    min-width: 50%;
}

.categiidggdhgbvdibfrst {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.categiidggdhgbvdibscnd {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.categiidggdhgbvdibthrd {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.categiidggdhgbvdibfrth {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.categiidggdhgbvdibffth {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.categiidggdhgbvdibscxth {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.categiidggdhgbvdibsvnth {
    width: 100%;
    max-width: 13%;
    min-width: 13%
}

.safetysoimnjdivhbhfvfsrt {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.safetysoimnjdivhbhfvscnd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.safetysoimnjdivhbhfvthrd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.safetysoimnjdivhbhfvffrth {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.njsdjkjsbkbkxtxgigdispp {
    font-size: 12px;
    color: #000;
    font-weight: 400;
    font-family: notosans;
    line-height: 14px;
    margin: 5px 0px 0px 0px;
}

.filedduringggsdivvvbsfrstataspcl {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.filedduringggsdivvvbsfrst {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.filedduringggsdivvvbsscnd {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.filedduringggsdivvvbsthrd {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.filedduringggsdivvvbsfrth {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.filedduringggsdivvvbsffth {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.filedduringggsdivvvbssxth {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.plantsdgtdcyrmmnbbvdivfrst {
    width: 100%;
    max-width: 60%;
    min-width: 60%;
}

.plantsdgtdcyrmmnbbvdivscnd {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.jhgsgjgiuhkhdjjdpojajklenm {
    padding-top: 10px;
}

.emplyeeetstdivfrst {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.emplyeeetstdivscnd {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.emplyeeetstdivthrd {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.emplyeeetstdivfrth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.emplyeeetstdivffth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.extrapagdhijspscvvbdiv {
    margin-bottom: 0px;
}

.jsgdjgcarttegdijjdivfrst {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.jsgdjgcarttegdijjdivscnd {
    width: 100%;
    max-width: 14%;
    min-width: 14%;
}

.jsgdjgcarttegdijjdivthrd {
    width: 100%;
    max-width: 14%;
    min-width: 14%;
}

.jsgdjgcarttegdijjdivfrth {
    width: 100%;
    max-width: 14%;
    min-width: 14%;
}

.jsgdjgcarttegdijjdivsxth {
    width: 100%;
    max-width: 14%;
    min-width: 14%;
}

.jsgdjgcarttegdijjdivsvnth {
    width: 100%;
    max-width: 14%;
    min-width: 14%;
}

.jsgdjgcarttegdijjdivegth {
    width: 100%;
    max-width: 14%;
    min-width: 14%;
}

.categjhggsprinciplesvndvfrst {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.categjhggsprinciplesvndvscnd {
    width: 100%;
    max-width: 8%;
    min-width: 8%;
}

.categjhggsprinciplesvndvffth {
    width: 100%;
    max-width: 8%;
    min-width: 8%;
}

.categjhggsprinciplesvndvtrhrd {
    width: 100%;
    max-width: 17%;
    min-width: 17%;
}

.categjhggsprinciplesvndvfrth {
    width: 100%;
    max-width: 17%;
    min-width: 17%;
}

.categjhggsprinciplesvndvsxth {
    width: 100%;
    max-width: 17%;
    min-width: 17%;
}

.categjhggsprinciplesvndvsvnth {
    width: 100%;
    max-width: 17%;
    min-width: 17%;
}

.categjhggsprinciplesvndvegth {
    width: 100%;
    max-width: 8.5%;
    min-width: 8.5%;
}

.categjhggsprinciplesvndvnnnth {
    width: 100%;
    max-width: 8.5%;
    min-width: 8.5%;
}

.categjhggsprinciplesvndvntenth {
    width: 100%;
    max-width: 8.5%;
    min-width: 8.5%;
}

.categjhggsprinciplesvndvnelevnth {
    width: 100%;
    max-width: 8.5%;
    min-width: 8.5%;
}

.categjhggsprinciplesvndvnelthrtn {
    width: 100%;
    max-width: 8.5%;
    min-width: 8.5%;
}

.categjhggsprinciplesvndvnelfrteen {
    width: 100%;
    max-width: 8.5%;
    min-width: 8.5%;
}

.categjhggsprinciplesvndvnelffteen {
    width: 100%;
    max-width: 8.5%;
    min-width: 8.5%;
}

.categjhggsprinciplesvndvnelsixtin {
    width: 100%;
    max-width: 8.5%;
    min-width: 8.5%;
}

.poinuttdsixhghdsepeicdivfrst {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.poinuttdsixhghdsepeicdivscnd {
    width: 100%;
    max-width: 11%;
    margin: 11%;
}

.poinuttdsixhghdsepeicdivthrd {
    width: 100%;
    max-width: 11%;
    margin: 11%;
}

.poinuttdsixhghdsepeicdivfrth {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.poinuttdsixhghdsepeicdivffth {
    width: 100%;
    max-width: 11%;
    margin: 11%;
}

.poinuttdsixhghdsepeicdivsixtrh {
    width: 100%;
    max-width: 11%;
    margin: 11%;
}

.poinuttdsixhghdsepeicdivsevnth {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.sebvenpoinytpriciplehhdhhdivfrst {
    width: 100%;
    max-width: 50%;
    min-width: 50%;
}

.sebvenpoinytpriciplehhdhhdivscnd {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.sebvenpoinytpriciplehhdhhdivthrd {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.tenprindiplrkdivfrst {
    width: 100%;
    max-width: 50%;
    min-width: 50%;
}

.tenprindiplrkdivscnd {
    width: 100%;
    max-width: 50%;
    min-width: 50%;
}

.leaderixhipindictysydiffrst {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.leaderixhipindictysydifscnd {
    width: 100%;
    max-width: 60%;
    min-width: 60%;
}

.categrypeinsipletwodivfrst {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.categrypeinsipletwodivscnd {
    width: 100%;
    max-width: 42%;
    min-width: 42%;
}

.categrypeinsipletwodivthrd {
    width: 100%;
    max-width: 42%;
    min-width: 42%;
}

.categrypeinsipletwodivtfrth {
    width: 100%;
    max-width: 14%;
    min-width: 14%;
}

.categrypeinsipletwodivtffth {
    width: 100%;
    max-width: 14%;
    min-width: 14%;
}

.categrypeinsipletwodivtsixth {
    width: 100%;
    max-width: 14%;
    min-width: 14%;
}

.categrypeinsipletwodivtegth {
    width: 100%;
    max-width: 14%;
    min-width: 14%;
}

.categrypeinsipletwodivtnnth {
    width: 100%;
    max-width: 14%;
    min-width: 14%;
}

.categrypeinsipletwodivttinnth {
    width: 100%;
    max-width: 14%;
    min-width: 14%;
}

.prikjmjmsdibbsjnttsfbkdivfst {
    width: 100%;
    max-width: 5%;
    min-width: 5%;
}

.prikjmjmsdibbsjnttsfbkdivscnd {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.prikjmjmsdibbsjnttsfbkdivthrd {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.prikjmjmsdibbsjnttsfbkdivtfrth {
    width: 100%;
    max-width: 23%;
    min-width: 23%;
}

.prikjmjmsdibbsjnttsfbkdivffth {
    width: 100%;
    max-width: 23%;
    min-width: 23%;
}

.prikjmjmsdibbsjnttsfbkdivsxth {
    width: 100%;
    max-width: 23%;
    min-width: 23%;
}

.principlefiepointthredivfrst {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.principlefiepointthredivscnd {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.principlefiepointthredivthrd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.principlefiepointthredivfrth {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.principlefiepointthredivssxth {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.principlefiepointthredivsffth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.principlefiepointthredivsvnth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.principlefiepointthredivsvnth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.principlesxdivpntonefrst {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.principlesxdivpntonescnd {
    width: 100%;
    max-width: 30%;
    min-width: 30%
}

.principlesxdivpntonethrd {
    width: 100%;
    max-width: 15%;
    min-width: 15%
}

.principlesxdivpntonefrth {
    width: 100%;
    max-width: 15%;
    min-width: 15%
}

.principlesixpinielvedivfrst {
    width: 100%;
    max-width: 5%;
    min-width: 5%;
}

.principlesixpinielvedivscnd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.principlesixpinielvedivthrd {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.principlesixpinielvedivfrth {
    width: 100%;
    max-width: 45%;
    min-width: 45%;
}

.princlplesixpinttwlddicfrst {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.princlplesixpinttwlddicscnd {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.princlplesixpinttwlddicthrd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.princlplesixpinttwlddicfrth {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.princlplesixpinttwlddicffth {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.princlplesixpinttwlddicsxth {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.priciplethrtendivfrst {
    width: 100%;
    max-width: 5%;
    min-width: 5%;
}

.priciplethrtendivscnd {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.priciplethrtendivthrd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.priciplethrtendivfrth {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.priciplethrtendivffth {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.areatextaresdiv {
    margin: 0px 0px 0px 30px;
}

.prnciplesixldrshipdivfrst {
    width: 100%;
    max-width: 5%;
    min-width: 5%;
}

.prnciplesixldrshipdivscnd {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.prnciplesixldrshipdivthrd {
    width: 100%;
    max-width: 35%;
    min-width: 35%;
}

.prnciplesixldrshipdivfrth {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.principlesvnndjdibsvndivfrst {
    width: 100%;
    max-width: 5%;
    min-width: 5%;
}

.principlesvnndjdibsvndivscnd {
    width: 100%;
    max-width: 47%;
    min-width: 47%;
}

.principlesvnndjdibsvndivthrd {
    width: 100%;
    max-width: 47%;
    min-width: 47%;
}

.prncplesvndivfrstscnddivfrst {
    width: 100%;
    max-width: 33%;
    min-width: 33%;
}

.prncplesvndivfrstscnddivscnd {
    width: 100%;
    max-width: 33%;
    min-width: 33%;
}

.prncplesvndivfrstscnddivthrd {
    width: 100%;
    max-width: 33%;
    min-width: 33%;
}

.adddinciondivimgright {
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    /* margin-right: auto; */
    margin-top: 5px;
    margin-bottom: 5px;
}

.adddinciondivimgright img {
    width: 100%;
    height: 100%;
}

.princlplelastsevebndivfsrt {
    width: 100%;
    max-width: 5%;
    min-width: 5%;
}

.princlplelastsevebndivscnd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.princlplelastsevebndivthrd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.princlplelastsevebndivfrth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.princlplelastsevebndivffth {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.princlplelastsevebndivsxth {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.priclioledfivetheeedivfrst {
    width: 100%;
    max-width: 5%;
    min-width: 5%;
}

.priclioledfivetheeedivscnd {
    width: 100%;
    max-width: 55%;
    min-width: 55%;
}

.priclioledfivetheeedivthrd {
    width: 100%;
    max-width: 45%;
    min-width: 45%;
}

.princlpeegthpintonedivfrst {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.princlpeegthpintonedivscnd {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.princlpeegthpintonedivthrd {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.princlpeegthpintonedivfrth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.princlpeegthpintonedivffth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.princlpeegthpintonedivsxth {
    width: 100%;
    max-width: 45%;
    min-width: 45%;
}

.princpleeghtdscnddivfrst {
    width: 100%;
    max-width: 5%;
    min-width: 5%;
}

.princpleeghtdscnddivscnd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.princpleeghtdscnddivthrd {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.princpleeghtdscnddivfrth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.princpleeghtdscnddivffth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.princpleeghtdscnddivsxth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.princpleeghtdscnddivegth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.princpleforsctdibfrst {
    width: 100%;
    max-width: 55%;
    min-width: 55%;
}

.princpleforsctdibscnd {
    width: 100%;
    max-width: 22%;
    min-width: 22%;
}

.princpleforsctdibthrd {
    width: 100%;
    max-width: 22%;
    min-width: 22%;
}

.prncipleghtfivdivfrst {
    width: 100%;
    max-width: 55%;
    min-width: 55%;
}

.prncipleghtfivdivsvnd {
    width: 100%;
    max-width: 22%;
    min-width: 22%;
}

.prncipleghtfivdivthrd {
    width: 100%;
    max-width: 22%;
    min-width: 22%;
}

.princlpidrshpindrrtdidfrst {
    width: 100%;
    max-width: 55%;
    min-width: 55%;
}

.princlpidrshpindrrtdidscnd {
    width: 100%;
    max-width: 45%;
    min-width: 45%;
}

.prnclpeghtldrindictsscnddivfrst {
    width: 100%;
    max-width: 5%;
    min-width: 5%;
}

.prnclpeghtldrindictsscnddivscnd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.prnclpeghtldrindictsscnddivthrd {
    width: 100%;
    max-width: 45%;
    min-width: 45%;
}

.prnclpeghtldrindictsscnddivfrth {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.prncpleegthldrindifrrdivfrst {
    width: 100%;
    max-width: 5%;
    min-width: 5%;
}

.prncpleegthldrindifrrdivscnd {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.prncpleegthldrindifrrdivsthrd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.prncpleegthldrindifrrdivfrth {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.prncpleegthldrindifrrdivsffth {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.prmcncpleeghtldrshpindictedivfrst {
    width: 100%;
    max-width: 33%;
    min-width: 33%;
}

.prmcncpleeghtldrshpindictedivscnd {
    width: 100%;
    max-width: 33%;
    min-width: 33%;
}

.prmcncpleeghtldrshpindictedivthrd {
    width: 100%;
    max-width: 33%;
    min-width: 33%;
}

.prnclpegthsxdivfrst {
    width: 100%;
    max-width: 5%;
    min-width: 5%;
}

.prnclpegthsxdivscnd {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.prnclpegthsxdivthrd {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.prnclpegthsxd5ivfrth {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.princplnnthdscnddivfrst {
    width: 100%;
    max-width: 55%;
    min-width: 55%;
}

.princplennthdscnddivscnd {
    width: 100%;
    max-width: 45%;
    min-width: 45%;
}

.pribdmnsonnhredivfrst {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.pribdmnsonnhredivscnd {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.pribdmnsonnhredivthrd {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.pribdmnsonnhredivfrth {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.pribdmnsonnhredivffth {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.pribdmnsonnhredivsxth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.pribdmnsonnhredivsvnth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.pribdmnsonnhredivegth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.pribdmnsonnhredinnth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.princjnineifroddivfsrt {
    width: 100%;
    max-width: 55%;
    min-width: 55%;
}

.princjnineifroddivscnd {
    width: 100%;
    max-width: 22%;
    min-width: 22%;
}

.princjnineifroddivthrd {
    width: 100%;
    max-width: 22%;
    min-width: 22%;
}

.imghsadddscrddftfadivdflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
}

.csrfsttdffgttxttpp {
    text-align: left;
    font-size: 15px;
    color: #000;
    font-weight: 500;
    font-family: 'NotoSans';
    line-height: 18px;
}

.perincninedivlastdivfrfrfst {
    width: 100%;
    max-width: 5%;
    min-width: 5%;
}

.perincninedivlastdivfrfscnd {
    width: 100%;
    max-width: 55%;
    min-width: 55%;
}

.perincninedivlastdivfrthrd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.perincninedivlastdivfrfrth {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.thsectionporntdivfrst {
    width: 100%;
    max-width: 5%;
    min-width: 5%;
}

.thsectionporntdivscnd {
    width: 100%;
    max-width: 40%;
}

.thsectionporntdivthrd {
    width: 100%;
    max-width: 55%;
    min-width: 55%;
}

.sectiondivponiscnddibvfsrt {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.sectiondivponiscnddibvscnd {
    width: 100%;
    max-width: 70%;
    min-width: 70%;
}

.sectgpontthredivfsrt {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.sectgpontthredivscnd {
    width: 100%;
    max-width: 70%;
    min-width: 70%;
}

.listoftblxsdivromainstyhhpp {
    font-size: 15px;
    font-weight: 600;
    font-family: notosans;
    line-height: 18px;
    margin: 0px 0px 10px 0px;
}

.listoftblxsdivseca {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    font-family: poppinslight;
    margin-left: 22px;
    margin-bottom: 15px;
    padding-left: 10px;
}

.sectionfrthdivmndivfsrt {
    width: 100%;
    max-width: 5%;
    min-width: 5%;
}

.sectionfrthdivmndivscnd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.sectionfrthdivmndivthrd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.sectionfrthdivmndivfrth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.sectionfrthdivmndivffth {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.sectionfrthdivmndivsxth {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.sectiondivsixthfrst {
    width: 100%;
    max-width: 5%;
    min-width: 5%;
}

.sectiondivsixthscnd {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.sectiondivsixththrd {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.sectiondivsixthfrth {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.sectiondivsixthffth {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.sectiondivsevnththfrst {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.sectiondivsevnththscnd {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.sectiondivsevnthththrd {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.sectiondivsevnththfrth {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.onnomemnewdibvlistdivsttspp {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    font-family: poppinslight;
    margin-left: 22px;
    margin-bottom: 10px;
    padding-left: 10px;
}

.sectiondiveightthfrst {
    width: 100%;
    max-width: 55%;
    min-width: 55%;
}

.sectiondiveightthscnd {
    width: 100%;
    max-width: 45%;
    min-width: 45%;
}

.sectindivnnthduvfrst {
    width: 100%;
    max-width: 45%;
    min-width: 45%;
}

.sectindivnnthduvscnd {
    width: 100%;
    max-width: 55%;
    min-width: 55%;
}

.sectiondivtenthdivfrst {
    width: 100%;
    max-width: 5%;
    min-width: 5%;
}

.sectiondivtenthdivscnd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.sectiondivtenthdivthrd {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.sectiondivtenthdivfrth {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.sectiondivtenthdivffth {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.sectiondivtenthdivsxth {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.sectiondivelvnthdivfrst {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.sectiondivelvnthdivscnd {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.sectiondivelvnthdivthrd {
    width: 100%;
    max-width: 50%;
    min-width: 50%;
}

.sectiondivelvnthdivfrth {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.sectiondivelvnthdivffth {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.sectiondivtenthdivsvcnth {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.sectiondivtenthdivegth {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.sectiondivtenthdivnnth {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.sectiondivtenthdivetnth {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.sectiondivtenthdiveelvnth {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.sectiondivtenthdivetwlvth {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.sectiondivtwentondedivfrst {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.sectiondivtwentondedivscnd {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.sectiondivtwentondedivthrd {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.sectiondivtwentondedivfrth {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.sectiondivtwentondedivffth {
    width: 100%;
    max-width: 8%;
    min-width: 8%;
}

.sectiondivtwentondedivsxth {
    width: 100%;
    max-width: 8%;
    min-width: 8%;
}

.sectiondivtwentondedivsvnth {
    width: 100%;
    max-width: 8%;
    min-width: 8%;
}

.sectiondivtwentondedivegth {
    width: 100%;
    max-width: 8%;
    min-width: 8%;
}

.sectiondivtwentondedivninth {
    width: 100%;
    max-width: 8%;
    min-width: 8%;
}

.sectiondivtwentondedivtinth {
    width: 100%;
    max-width: 8%;
    min-width: 8%;
}

.sectiondivtwentondedivelvnth {
    width: 100%;
    max-width: 8%;
    min-width: 8%;
}

.sectiondivtwentondedivtwlvnth {
    width: 100%;
    max-width: 8%;
    min-width: 8%;
}

.sectiondivtwentondedivthrtn {
    width: 100%;
    max-width: 8%;
    min-width: 8%;
}

.sectionromanfivdivfrst {
    width: 100%;
    max-width: 5%;
    min-width: 5%;
}

.sectionromanfivdivscnd {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.sectionromanfivdivthrd {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.sectionromanfivdivfrth {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.sectionromanfivdivffth {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.sectionromansevenscnddivfsrt {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.sectionromansevenscnddivscnd {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.sectionromansevenscnddivthrd {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.sectionromansevenscnddivfrth {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.sectionromansevenscnddivffth {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.sectionromansevenscnddivsxth {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.sectionromansevenscnddivsvnth {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.sectionromansevenscnddivegth {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.sectionromansevenscnddivnnth {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.sectionromansevenscnddivtnth {
    width: 100%;
    max-width: 13%;
    min-width: 13%;
}

.sectionlsttwnfuvdivfrst {
    width: 100%;
    max-width: 5%;
    min-width: 5%;
}

.sectionlsttwnfuvdivscnd {
    width: 100%;
    max-width: 15%;
    min-width: 15%;
}

.sectionlsttwnfuvdivthrd {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.sectionlsttwnfuvdivfrth {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.sectionlsttwnfuvdivffth {
    width: 100%;
    max-width: 25%;
    min-width: 25%;
}

.sectionlsttwnfuvdivsxth {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.setioncsrrmnscdivfdrst {
    width: 100%;
    max-width: 10%;
    min-width: 10%;
}

.setioncsrrmnscdivfscnd {
    width: 100%;
    max-width: 50%;
    min-width: 50%;
}

.setioncsrrmnscdivthrd {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.sectinfrsttwnfrromahgdhdivfrst {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.sectinfrsttwnfrromahgdhdivscnd {
    width: 100%;
    max-width: 70%;
    min-width: 70%;
}

.consoltedtetxstppvfspp {
    text-align: left;
    font-size: 15px;
    color: #000;
    font-weight: 600;
    font-family: 'NotoSans';
    line-height: 18px;
    margin-bottom: 10px;
}

.sectionpointondivfrst {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.sectionpointondivscnd {
    width: 100%;
    max-width: 70%;
    min-width: 70%;
}

.plytxtpmanmgmenttxpprcdivpp {
    font-size: 16px;
    font-weight: 600;
    font-family: notosans;
    line-height: 20px;
    padding: 5px 0px 10px 0px;
}

.checkbpsosdivpstn {
    background-color: #fff;
    height: auto;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 10px;
    width: 100%;
    outline: none;
    height: 38px;
}

.chekdivsfgsgdivdflrtdov {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cjheckboscxdibbdffrst {
    width: 100%;
    max-width: 7%;
    min-width: 7%;
}

.chekdivsfgsgdivdflrtdov i {
    font-size: 18px;
    color: #000;
    font-weight: 600;
    font-family: 'FontAwesome';
}

.sectnbbdivtendivfrst {
    width: 100%;
    max-width: 20%;
    min-width: 20%;
}

.sectnbbdivtendivscnd {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.sectnbbdivtendivsthrd {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.princoiplebtnmainactbdiv {
    background-color: #298bab;
    padding: 3px 25px;
    color: #fff;
    font-size: 15px;
    line-height: normal;
    text-align: center;
    border-radius: 20px;
    border: none;
    margin-bottom: 15px;
}
.contenttabbardivflsx{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.bardivmainchjy i{
font-size: 18px;
color: #000;
font-weight: 400;
font-family: Fontawesome;
}
.sidebardivensuredivnewres{
    width: 100%;
    max-width: 250px;
    background-color: #4caf50b8;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 110px;
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: auto;
    padding-bottom: 30px;
    left: -250px; 
    transition: .3s all ease-in-out;  
}
.notedivadmoredivflx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}
.notetgvxtygfspopphjhsttxp{
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0px;
    color: #fff;
    font-family: notosans;
    font-weight: 400;
    cursor: pointer;
    background-color: #78bf7b;
    padding: 2px 6px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px #ccc;
    display: inline;

}
.textardibbrdrdivmainitlc{
    background-color: #fff;
    width: 100%;
    height: auto;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 10px;
    overflow-y: auto;
    outline: none;
    white-Space: pre-wrap;
    word-Break: break-word;
    font-size: 12px;
    color: #000;
    font-weight: 400;
    font-family: notosans;
    font-style: italic;  
}
.raditrbtntxt_div_horizntl{
    display: flex;
    align-items: center;
    column-gap: 40px;
    justify-content: flex-start;
}
.simeerttetxstlppp{
    font-size: 14px;
    line-height: 16px;
    margin: 5px 0px;
    color: #000000;
    font-family: notosans;
    font-weight: 500;  
}
@media(max-width:1399px) {
    .sidebardivensurediv {
        width: 100%;
        max-width: 230px;
    }

    .bsrdivtabdivlft {
        padding-left: 30px;
    }

    .sidebardetialsdivmain {
        padding-top: 110px;
        padding-left: 235px;
    }
}
@media(max-width:1199px){
    .sidebardivensurediv {
        width: 100%;
        max-width: 220px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .bsrdivtabdivlft {
        padding-left: 24px;
    }
    .sidebardetialsdivmain {
        padding-top: 110px;
        padding-left: 212px;
    }
}

@media(max-width:991px){
    .sidebardivensurediv{
        display: none;
    }
    .sidebardetialsdivmain {
        padding-top: 110px;
        padding-left: 0px;
    }
    .sidebardivensuredivnewres.actv{
        transition: .3s all ease-in-out;  
        left: 0;
        background-color: #4caf4f;
    }
}


/* PrincilpleOne Css End */
.allcertificatemainsection {
    width: 100%;
    height: auto;
    padding: 100px 0px 80px 0px;
}

.allcertificatemaibg {
    width: 100%;
    max-width: 80%;
    margin: auto;
    height: auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 5px 0px #ccc;
}

.cerificatemainbhbrdr {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 #cfcfcf;
    margin-bottom: 20px;
    max-width: 100%;
    padding: 15px 30px;
    width: 100%;
}

.cerificatemainbhbrdrdivflx {
    align-items: center;
    column-gap: 25px;
    display: flex;
    justify-content: flex-start;
}

.certificateimage {
    height: 115px;
    max-width: 126px;
    min-width: 126px;
    width: 100%;
}

.certificateimage img {
    height: 100%;
    width: 100%;
}

.certificatetyoetxtpp {
    color: #000;
    font-family: noirproregular;
    font-size: 28px;
    font-weight: 800;
    line-height: 32px;
    margin-bottom: 8px;
}

.coursttiltlecertifacttxtp {
    color: #000;
    font-family: NoirProLight;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
}

.coursttiltlecertifacttxtp span {
    color: #000;
    font-family: notosans;
    font-size: 18px;
    font-weight: 800;
    line-height: 22px;
    margin-left: 10px;
}

/* certificate of Education Start */
.cerficatefofedumnainsection {
    width: 100%;
    height: auto;
    padding: 50px 0px 0px 50px;
}

.cerficatefofedubg {
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 0px 5px 0px #ccc;
    margin: 10px 0px 20px 0px;
}

.cerficatefofedubgtop {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    column-gap: 15px;
    align-items: center;
    padding: 10px 20px;
}

.traimsignbvjshnamamsnbtxtuicerdr {
    font-Size: 40px;
    font-Weight: 600;
    font-Family: "sans-serif";
    line-Height: normal;
    text-Transform: uppercase;
    color: #2a4e83;
    margin-right: 30px;
}

.downliadimgicndibmimg {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px 0 #ccc;
    height: 38px;
    /* margin-bottom: 10px; */
    max-width: 36px;
    min-width: 36px;
    padding: 6px;
    width: 100%;
}

.downliadimgicndibmimg img {
    width: 100%;
    height: 100%;
}

.certificateimagebg {
    background-Position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
}

.cerfytvatedallin {
    padding: 50px 0;
}

.cerfytvatedalmainkft {
    margin-left: 100px;
}

.comaopnayslogoedu {
    background-Position: 50%;
    max-Width: 86px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 85px;
    min-width: 86px;
    text-align: left;
    width: 100%;
}

.certificatenametxtpp {
    font-Size: 100px;
    font-Weight: bolder;
    margin-Top: 10px;
    font-Family: "Noto Sans, sans-serif";
    line-Height: normal;
    margin-Bottom: 2px;
    color: #b91912;
    text-align: left;
}

.certificatenametxtppnich {
    font-Size: 35px;
    text-Align: left;
    font-Family: sans-serif;
    line-Height: normal;
    font-Weight: 400;
    letter-Spacing: 10px;
    text-Transform: uppercase;
    margin-Top: 0;
    margin-Bottom: 0;
    color: #2a4e83;
}

.CertificateGalaSealimg {
    background-Position: 50%;
    max-Width: 300px;
    min-Width: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    width: 100%;
}

.delightedtxtt {
    font-Size: 21px;
    font-Weight: 400;
    font-Family: "Noto Sans, sans-serif";
    line-Height: normal;
    color: #9d9d9d;
    margin: 30px 0 20px;
}

.traineeNametxtpp {
    font-Size: 50px;
    font-Weight: bolder;
    font-Family: goudy-old-style;
    line-Height: normal;
    color: #b91912;
    margin: 30px 0;
}

.educationttxppcrtt {
    font-Size: 21px;
    font-Weight: 400;
    font-Family: "Noto Sans, sans-serif";
    line-Height: normal;
    color: #9d9d9d;
    margin: 30px 0 20px;
}

.completingtxtppjocertxded {
    font-Family: "sans-serif";
    line-Height: normal;
    text-Transform: uppercase;
    color: #2a4e83;
    font-Size: 30px;
    font-Weight: 400;
    margin: 30px 0 0;
}

.traimsignbvjshnamamsnbtxtuicerdr {
    font-Family: "sans-serif";
    line-Height: normal;
    text-Transform: uppercase;
    color: #2a4e83;
    font-Size: 40px;
    font-Weight: 600;
    margin: 10px 0;
}

.certficateeduftrui {
    padding: 50px 60px;
}

.elitelogoeducrtdfdivimg {
    background-Position: 50%;
    max-Width: 142px;
    min-Width: 142px;
    background-repeat: no-repeat;
    background-size: contain;
    height: 76px;
    width: 100%;
}

.Certificatenotxttpopp {
    font-Size: 18px;
    text-Align: center;
    font-Weight: 400;
    font-Family: "Noto Sans, sans-serif";
    line-Height: normal;
    color: #000000;
    text-Transform: uppercase;
    margin-Bottom: 12px;
}

.certificateNoppplctrxrtp {
    font-Size: 18px;
    text-Align: center;
    font-Weight: 400;
    font-Family: "Noto Sans, sans-serif";
    line-Height: normal;
    color: #000000;
}

.certficateeduftruitbls {
    width: 90%;
    margin: auto;
}

.certificateimagebgpading {
    padding: 0px 20px 15px 20px;
}

/* CertificateExcellence Css */
.certificateddivmargnlftright {
    margin: 0px 80px;
}

.certficattsfghdbttxppplljjk {
    color: #2a4e83;
    font-size: 30px;
    font-Weight: 100;
    font-Family: 'goudy-old-style';
    text-Align: center;
    text-Transform: uppercase;
    letter-Spacing: 5px;
    line-Height: normal;
    margin-Bottom: 2px;
    margin-Top: 20px;
}

.certificatelogocmpnybgmainnkhj {
    background-Position: center;
    background-Size: contain;
    background-repeat: no-repeat;
    width: 100%;
    text-Align: left;
    max-Width: 142px;
    min-Width: 128px;
    height: 95px;
}

.certifacelitelogiomimgbggtryh {
    background-Position: center;
    background-Size: contain;
    background-Repeat: no-repeat;
    width: 100%;
    max-Width: 160px;
    min-Width: 160px;
    height: 60px
}

.brtfdgtygvscerwxcvbbdbrtdcg {
    width: 100%;
    max-Width: 500px;
    min-Width: 500px;
    margin: auto;
    border-Bottom: 1px solid #969191;
}

.beggungcerexcellencettxppp {
    color: #2a4e83;
    font-Size: 25px;
    font-Family: sans-serif;
    line-Height: normal;
    font-Weight: 400;
    text-Transform: uppercase;
    margin: 30px 0px 10px 0px;
    letter-Spacing: 2px;
    text-Align: center
}

.withthiscertexcellencetxtpp {
    color: #000;
    font-Family: Noto Sans, sans-serif;
    font-Size: 25px;
    font-Weight: 300;
    text-Align: center;
    margin: 30px 0px 20px;
}

.employeeofthemnthtxttppphhb {
    color: #2a4e83;
    font-Size: 35px;
    font-Family: 'sans-serif';
    line-Height: normal;
    font-Weight: 500;
    text-Transform: uppercase;
    margin: 20px 0px 10px 0px;
    letter-Spacing: 6px;
    text-Align: center;
}

.certficateeduftruitblscertijdexcellence {
    width: 100%;
    max-width: 60%;
    margin: auto;
}

.cerexcellencendgalsbgnmmskln {
    background-Position: center;
    background-Size: contain;
    background-Repeat: no-repeat;
    width: 100%;
    max-Width: 200px;
    min-Width: 200px;
    height: 170px;
}

.Certificatenotxttpopptoppading {
    padding-top: 10px;
}

/*CertificateEngagement Css  */
.certaficateengagedivrightlecfty {
    margin-Left: 60px;
    margin-Right: 60px
}

.certofiengagementxttpppvgujop {
    color: #970f11;
    font-Size: 85px;
    font-Weight: 800;
    text-Align: center;
    margin-Top: 10px;
    font-Family: "Jacquard", "Old English Text MT" serif;
    line-Height: normal;
    margin-Bottom: 2px;
}

.logocompanycertificatecmggemntimg {
    background-Position: center;
    background-Size: contain;
    background-Repeat: no-repeat;
    width: 100%;
    text-Align: left;
    max-Width: 121px;
    min-Width: 100px;
    height: 88px;
    margin: auto;
    padding-Top: 30px
}

.brdrednaggemntrdtnfrdrdiv {
    width: 100%;
    max-Width: 989px;
    min-Width: 989px;
    margin: auto;
    border-Top: 1px solid #969191;
}

.certificateengagnameklrttxttpp {
    color: #b91912;
    font-Size: 45px;
    font-Weight: bolder;
    text-Align: center;
    letter-Spacing: 8px;
    margin-Top: 10px;
    font-Family: 'goudy-old-style';
    line-Height: normal;
    margin: 10px 0px 10px 0px
}

.withthiscertificaetttxtppptttxttuo {
    color: #000000;
    font-Family: 'Noto Sans, sans-serif';
    font-Size: 20px;
    font-Weight: 300;
    text-Align: center;
    margin: 25px 0px 25px;
}

.forthwparticipanttxtppshhcertenggemnt {
    color: #b91912;
    font-Size: 25px;
    font-Family: 'Noto Sans, sans-serif';
    line-Height: normal;
    font-Weight: 400;
    text-Transform: uppercase;
    margin: 30px 0px 10px 0px;
    letter-Spacing: 2px;
    text-Align: center;
}

.certificatteenggemnetdivbtmjksm {
    width: 100%;
    max-Width: 500px;
    min-Width: 500px;
    margin: auto;
    border-Bottom: 1px solid #b91912
}

.echnologiestxttcerttengagemttxtpp {
    color: #b91912;
    font-Size: 30px;
    font-Family: Noto Sans, sans-serif;
    line-Height: normal;
    font-Weight: 500;
    text-Transform: uppercase;
    margin: 10px 0px 10px 0px;
    letter-Spacing: 6px;
    text-Align: center;
}

.elitelogoengagementdivbjhio {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    max-width: 130px;
    min-width: 130px;
    height: 70px;
}

.certificategalasecllsumnhj {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    max-width: 177px;
    min-width: 177px;
    height: 157px;
    margin: auto;
}


/* Eloquence start */
.eloquennsnpapjsjsiidery {
    padding: 20px 0px;
}

.eloquennsnpapjsjsiiderylrftrightopo {
    margin-left: 160px;
    margin-right: 130px;
    margin-top: 28px;
}

.cerifivateqleloquencettstypppbjo {
    color: #b91912;
    font-Size: 53px;
    font-Weight: bolder;
    text-Align: left;
    margin-Top: 0px;
    font-Family: 'Montserrat, sans-serif';
    line-Height: normal;
    writing-mode: vertical-lr;
    margin-Bottom: 0px;
    text-Transform: uppercase;
    letter-Spacing: 15px;
    margin-Left: 0;
    margin-Right: 10px;
}

.eloquencettxtppgjiooppp {
    color: #b91912;
    font-Size: 35px;
    font-Weight: 500;
    text-Align: left;
    margin-Top: 0px;
    font-Family: 'Montserrat, sans-serif';
    line-Height: normal;
    margin-Bottom: 0px;
    writing-Mode: vertical-lr;
    text-Transform: uppercase;
    letter-Spacing: 19px;
    margin-Left: 0px;
    margin-Right: 10px;
}

.delightexteloquentegttxpp {
    font-Size: 21px;
    color: #000000;
    font-Weight: 400;
    font-Family: 'Noto Sans, sans-serif';
    line-Height: normal;
    margin: 0px 0px 45px 0px;
}

.logocompamyysycertieloquueyoimhj {
    background-Position: center;
    background-Size: contain;
    background-Repeat: no-repeat;
    width: 100%;
    text-Align: left;
    max-Width: 180px;
    min-Width: 124px;
    height: 107px
}

.certifietaceeloquencetxttpppwikjejtxp {
    color: #2a4e83;
    font-Size: 45px;
    font-Weight: bolder;
    text-Align: left;
    margin-Top: 10px;
    font-Family: 'goudy-old-style';
    line-Height: normal;
    margin: 2px 0px;
}

.withcertidficavgwleluqujttxppp {
    font-Size: 21px;
    color: #9d9d9d;
    text-Align: left;
    font-Weight: 400;
    font-Family: 'Noto Sans, sans-serif';
    line-Height: normal;
    margin: 0px 0px 40px 0px;
}

.recongnizooeqloquenxtrrttxrrpp {
    color: #2a4e83;
    font-Size: 20px;
    font-Family: 'Noto Sans, sans-serif';
    text-Align: left;
    line-Height: normal;
    font-Weight: 400;
    text-Transform: uppercase;
    margin: 40px 0px 0px 0px;
}

.nextgeneloquencettxtpppp {
    color: #2a4e83;
    font-Size: 50px;
    font-Weight: 400;
    text-Align: left;
    margin-Top: 10px;
    font-Family: 'Noto Sans, sans-serif';
    line-Height: normal;
    margin: 20px 0px 0px 0px;
    text-Transform: uppercase;
}
.cerifivattetgteloquuefrlytrrfcbli{
    width: 100%;
    max-width: 100%;
    margin: auto;
}
.galacealimgeloquemnnhgsttdivimg{
    background-Position: center; 
    background-Size: contain;
    background-Repeat: no-repeat;
    width: 100%; 
    max-Width: 200px; 
    min-Width: 200px; 
    height: 145px;
}
.eloquencecertbrdrresignnj{
border-Bottom: 1px solid #afafafcc; 
width: 100%;
}
.elitelogoecerificateeloquentlogoing{
    background-Position: center;
    background-Size: contain;
    background-Repeat: no-repeat; 
    width:100%;
    max-Width: 130px; 
    min-Width: 130px; 
    height: 50px; 
    margin: auto
}



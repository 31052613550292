.engagedivmaileft {
  height: 83vh;
  overflow-y: auto;
  position: fixed;
  top: 108px;
  transition: all .7s cubic-bezier(.645, .045, .355, 1);
  width: 18%;
  z-index: 7;
  left: 107px;
}

.reactplayerivmain {
  width: 100% !important;
  height: 345px !important;
  padding-top: 10px;
}

.reactplayerivmain iframe {
  width: 100% !important;
  height: 100% !important;
}

.engagedivmaileft::-webkit-scrollbar {
  display: none;
}

.engagedivmaileft {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.engagedivmainflx {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.engagedivmairight {
  animation: fadeInDown .95s ease-in-out 0s 1 normal none running;
  height: 83vh;
  overflow-y: auto;
  position: fixed;
  top: 108px;
  transition: all .7s cubic-bezier(.645, .045, .355, 1);
  width: 18%;
  z-index: 7;
  right: 107px;
}

.engagedivmairigh::-webkit-scrollbar {
  display: none;
}

.engagedivmairight {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.engagedivmaimiddle {
  margin: 0 auto;
  padding-top: 108px;
  width: 47%;
}

.engagedivmaimiddleprfl {
  margin: 0 auto;
  width: 47%;
}

.bulletin_boardengage {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-top: 0px;
  padding-bottom: 7px;
}

.editdeletedibvflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
  border: 1px solid #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  padding: 3px 11px;
  border-radius: 2px;
  position: absolute;
  right: 8px;
}

.dotsticondrdtdlytrlrt {
  position: relative;
}

.editedleted i {
  font-size: 12px;
  color: red;
}

.reactionicon {
  width: 100%;
  max-width: 25px;
  min-width: 25px;
  height: 25px;
}

.reactionicon img {
  width: 100%;
  height: 100%;
}

.reactionicondivflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  padding: 5px 15px;
  border-radius: 20px;
  position: absolute;
  bottom: 39px;
  opacity: 0;
  transition: 0.5s all ease-in-out;
}

.reactionicondivflx.actvgchng {
  opacity: 1;
  transition: 0.5s all ease-in-out;
}

/* .reactionmainsc:hover .reactionicondivflx.actvgchng{
  opacity: 1;
  transition: 0.5s all ease-in-out;
 }
.reactionmainsc:hover .reactionicondivflx{
 opacity: 1;
 transition: 0.5s all ease-in-out;
} */
.reactionmainscdivflx {
  display: flex;
  align-items: center;
  column-gap: 5px;
  justify-content: center;
}

.editedleted {
  display: contents;
}

.reactionmainsc {
  position: relative;
  cursor: pointer;
}

/* 
.engagediv {
  padding: 120px 0px 20px 0px;
} */

.bulletin_boardengage .top {
  width: 100%;
  height: auto;
  padding: 8px 12px 8px 12px;
  border-bottom: 1px solid rgba(198, 198, 210, 0.26);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bulletin_boardengage .bulleBoardFig {
  width: 25px;
  height: 25px;
  /* margin: 0 3px 0 0; */
}

.bulletin_boardengage .bulleBoardHead {
  display: flex;
  align-items: center;
}

.bulletin_boardengage .bulleBoardFig img {
  width: 100%;
  height: 100%;
}

.bulletin_boardengage .top .head h4 {
  font-family: NoirProRegular;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  color: #263238;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

.bulletin_boardengage .add_seeallflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
}

.bulletin_boardengage .tyEvMainDiv {
  margin-right: 7px;
}

.bulletin_boardengage .bulleBoardFig {
  width: 25px;
  height: 25px;
}


.bulletin_boardengage .bottom {
  width: 100%;
  max-height: 156px;
  padding: 0px 10px 0px 10px;
  overflow-y: auto;
}

.bulletin_boardengage .bottom .card_area {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  padding: 5px 5px;
  position: relative;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.bulletin_boardengage .bottom .card_area .event_img {
  width: 100%;
  max-width: 75px;
  min-width: 75px;
  height: 65px;
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 2px 0px #CCC;

}

.bulletin_boardengage .bottom .card_area .event_img img {
  width: 100%;
  height: 100%;
}

.bulletin_boardengage .bottom .card_area .textbillentin h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #263238;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 7px;
  font-family: NoirProLight;
}

.bulletin_boardengage .bottom .card_area .textbillentin p {
  font-family: "Mandaufont";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  /* padding: 10px 0px 0px 0px; */
  margin: 0px;
}

.event_imgtextbillentin {
  display: flex;
  column-gap: 6px;

}

.viewbtn {
  font-family: NotoSans;
  border: none;
  box-shadow: 0px 0px 5px 0px #ccc;
  padding: 3px 6px;
  color: #000;
  font-size: 11px;
  /* line-height: 12px; */
  font-weight: 700;
  border-radius: 5px;
  background-color: #ffffff;
  text-decoration: none;

}

.dotviewflx {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.divdot {
  color: #000000;
  position: relative;
}

.proflenamedtflx {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  align-items: center;
  padding: 5px 0px;
}

.prflimgperfrm {
  width: 100%;
  max-width: 49px;
  height: 49px;
  border-radius: 50%;
  overflow: hidden;
}

.prflimgperfrm img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.txtprlnm p {
  font-family: NoirProLight;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #263238;
  padding: 0;
  margin-bottom: 4px;
  margin-right: 7px;
}

.prfltxtflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.datediv p {
  font-family: Mandaufont;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  text-transform: uppercase;
  position: relative;
}

.performer_boardengage {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-top: 1px;
  padding-bottom: 6px;
}

.halloddiv {
  padding-top: 20px;
}

.performer_boardengage .top {
  width: 100%;
  height: auto;
  padding: 8px 12px 8px 12px;
  border-bottom: 1px solid rgba(198, 198, 210, 0.26);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.performer_boardengage .bulleBoardFig {
  width: 25px;
  height: 25px;
  /* margin: 0 3px 0 0; */
}

.performer_boardengage .bulleBoardHead {
  display: flex;
  align-items: center;
}

.performer_boardengage .bulleBoardFig img {
  width: 100%;
  height: 100%;
}

.performer_boardengage .top .head h4 {
  font-family: NoirProRegular;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  color: #263238;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

.performer_boardengage .add_seeallflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
}

.performer_boardengage .tyEvMainDiv {
  margin-right: 7px;
}

.performer_boardengage .bulleBoardFig {
  width: 25px;
  height: 25px;
}

.performer_boardengage .bottom {
  width: 100%;
  max-height: 220px;
  padding: 0px 12px 0px 12px;
  overflow-y: auto;
}

.employee_boardengage {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 7px;
  margin-top: 0px;
}

.employee_boardengage .top {
  width: 100%;
  height: auto;
  padding: 8px 12px 8px 12px;
  border-bottom: 1px solid rgba(198, 198, 210, 0.26);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.employee_boardengage .bulleBoardFig {
  width: 25px;
  height: 25px;
  /* margin: 0 3px 0 0; */
}

.employee_boardengage .bulleBoardHead {
  display: flex;
  align-items: center;
}

.employee_boardengage .bulleBoardFig img {
  width: 100%;
  height: 100%;
}

.employee_boardengage .top .head h4 {
  font-family: NoirProRegular;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
  color: #263238;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
  color: #263238;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

.employee_boardengage .add_seeallflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
}

.employee_boardengage .tyEvMainDiv {
  margin-right: 7px;
}

.employee_boardengage .bulleBoardFig {
  width: 25px;
  height: 25px;
}

.employee_boardengage .bottom {
  width: 100%;
  max-height: 220px;
  padding: 0px 12px 0px 12px;
  overflow-y: auto;
}

.events_boardengage {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-top: 0px;
  padding-bottom: 7px;

}

.myeventcrd {
  padding-top: 20px;
}

.events_boardengage .top {
  width: 100%;
  height: auto;
  padding: 8px 12px 8px 12px;
  border-bottom: 1px solid rgba(198, 198, 210, 0.26);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.events_boardengage .bulleBoardFig {
  width: 25px;
  height: 25px;
  /* margin: 0 3px 0 0; */
}

.events_boardengage .bulleBoardHead {
  display: flex;
  align-items: center;
}

.events_boardengage .bulleBoardFig img {
  width: 100%;
  height: 100%;
}

.events_boardengage .top .head h4 {
  font-family: NoirProRegular;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  color: #263238;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

.events_boardengage .add_seeallflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
}

.events_boardengage .tyEvMainDiv {
  margin-right: 7px;
}

.events_boardengage .bottom {
  width: 100%;
  max-height: 227px;
  padding: 0px 12px 0px 12px;
  overflow-y: auto;
}

.events_boardengage .bottom .card_area {
  width: 100%;
  height: auto;
  /* border-radius: 10px; */
  overflow: hidden;
  display: flex;
  /* padding: 10px; */
  padding: 5px 5px;
  /* align-items: center; */
  /* margin-bottom: 8px; */
  /* background: rgb(0 101 0 / 77%); */
  position: relative;
  /* margin-top: 8px; */
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.events_boardengage .bottom .card_area .event_img {
  width: 100%;
  max-width: 75px;
  min-width: 74px;
  height: 65px;
  overflow: hidden;
  border-radius: 10px;
}

.events_boardengage .bottom .card_area .event_img img {
  width: 100%;
  height: 100%;
}

.events_boardengage .bottom .card_area .textbillentin h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  width: 100%;
  max-width: 137px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 7px;
  font-family: NoirProLight;
}

.events_boardengage .bottom .card_area .textbillentin p {
  font-family: "Mandaufont";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  padding: 10px 0px 0px 0px;
  margin: 0px;
}

.referal_boardengage {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 7px;
  margin-top: 0px;
}

.referal_boardengage .top {
  width: 100%;
  height: auto;
  padding: 8px 12px 8px 12px;
  border-bottom: 1px solid rgba(198, 198, 210, 0.26);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.referal_boardengage .bulleBoardFig {
  width: 25px;
  height: 25px;
  /* margin: 0 3px 0 0; */
}

.referal_boardengage .bulleBoardHead {
  display: flex;
  align-items: center;
}

.referal_boardengage .bulleBoardFig img {
  width: 100%;
  height: 100%;
}

.referal_boardengage .top .head h4 {
  font-family: NoirProRegular;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
  color: #263238;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
  color: #263238;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

.referal_boardengage .add_seeallflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
}

.referal_boardengage .tyEvMainDiv {
  margin-right: 7px;
}

.referal_boardengage .bottom {
  width: 100%;
  max-height: 220px;
  padding: 0px 12px 0px 12px;
  overflow-y: auto;
}

.referal_boardengage .bottom .card_area {
  width: 100%;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  padding: 5px 5px;
  margin-bottom: 8px;
  background: rgb(0 101 0 / 77%);
  position: relative;
  margin-top: 8px;
  padding: 6px 10px;
}

.referal_boardengage .bottom .shrdiv {
  width: 25px;
  height: 25px;
  background-color: #fff;
  padding: 1%;
  border-radius: 6px;
}

.referal_boardengage .bottom .shrdiv img {
  width: 100%;
  height: 100%;
}

.flxsgrtxt {
  display: flex;
  justify-content: space-between;

}

.referal_boardengage .bottom .card_area .ttltxtpp h4 {
  font-family: NoirProLight;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.005em;
  text-transform: capitalize;
  color: #ffffff;
  padding: 0;
  margin: 0;
}

.referal_boardengage .bottom .card_area .ttltxtpp p {
  font-family: NoirProLight;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.005em;
  text-transform: capitalize;
  color: #ffffff;
  padding: 0;
  margin-bottom: 5px;
  margin-top: 5px;
}

.referal_boardengage .bottom::-webkit-scrollbar {
  display: none;
}

.referal_boardengage .bottom {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.performer_boardengage .bottom::-webkit-scrollbar {
  display: none;
}

.performer_boardengage .bottom {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.employee_boardengage .bottom::-webkit-scrollbar {
  display: none;
}

.employee_boardengage .bottom {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.events_boardengage .bottom {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

.events_boardengage .bottom::-webkit-scrollbar {
  display: none;
}

.txtevent {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 7px;
  font-family: NoirProLight;
  max-width: 120px;
}

.addicon i {
  font-size: 10px;
  color: #000;
  margin-right: 5px;
  cursor: pointer;
}

.doticon i {
  font-size: 14px;
  color: #000;
  position: relative;
  cursor: pointer;
}

.divdot i {
  font-size: 14px;
  color: #000000;
}

.dotaddipcn {
  display: flex;
  justify-content: flex-start;
  column-gap: 2px;
  flex-direction: column;
  row-gap: 20px;
}

/* .hstdadty {
  padding: 23px 0px 0px 0px;
} */

.engagedivmaimiddle .share_thoughts {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 18px;
  max-width: 100%;
}

.engagedivmaimiddle .share_thoughts .top {
  width: 100%;
  height: auto;
  padding: 11px 21px 11px;
  border-bottom: 1px solid rgba(198, 198, 210, 0.48);
  display: flex;
  align-items: center;
  gap: 5%;
  justify-content: flex-start;
}

.share_thoughts .top .shrthgprflimg {
  width: 100%;
  max-width: 50px;
  min-width: 50px;
  height: 50px;
  padding: 0;
}

.share_thoughts .top .shrthgprflimg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.share_thoughts .top .prflbrdrmain {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-clip: padding-box, border-box;
  -webkit-background-clip: padding-box, border-box;
  background-image: #4c3b92;
  background-image: linear-gradient(#fff, #fff), radial-gradient(circle at top left, #ed3b4b, #4c3b92);
  background-origin: border-box;
  border: 4px double #0000;
}

.crtevnttxtthght_txtdiv {
  border: 1px solid #d3a150;
  border-radius: 40px;
  max-width: 100%;
  padding: 10px;
  width: 100%;
}

.topsharemain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 16px;

}

.sgahrethgthimg {
  width: 100%;
  max-width: 25px;
  min-width: 25px;
  height: 25px;
}

.sgahrethgthimg img {
  width: 100%;
  height: 100%;
}

.shtretxtpp {
  font-family: noirprolight;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
}

.sharethfghbrdr {
  border: 1px solid rgb(0 101 0 / 77%);
  padding: 4px 20px;
  border-radius: 30px;
  column-gap: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.sharethfghbrdr:hover {
  text-decoration: none;
}

.thght_txtdiv .thght_txt {
  color: #d3a145;
  font-family: NotoSans;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
  cursor: pointer;
}

.share_thoughts .bottom {
  padding: 10px 15px 10px;
  justify-content: space-between;
  display: flex;
}

.imgtxtshrediv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
}

.imgsgardivshare {
  width: 25px;
  height: 25px;
}

.imgsgardivshare img {
  width: 100%;
  height: 100%;
}

.crtevnttxt p.crttxtppdshr {
  color: #000000;
  font-family: NoirProLight;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.1px;
  white-space: nowrap;
}

.engagedivmaimiddle .postthoughts_area {
  width: 100%;
  /* height: 180vh; */
  margin-bottom: 20px;
  height: auto;
  /* position: relative; */
  /* overflow-y: scroll; */
  border-radius: 10px;
}

.engagedivmaimiddle .sara_tylor {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  margin-bottom: 8px;
}

.engagedivmaimiddle .sara_tylor .top {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding: 15px 20px;
}

.engagedivmaimiddle .sara_tylor .top .left {
  display: flex;
  column-gap: 9px;
}

.prflimgshrthgh {
  width: 39px;
  height: 39px;
  border-radius: 50%;
}

.prflimgshrthgh img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.sharedetails .name {
  display: flex;
  align-items: center;
  column-gap: 5px;
  padding-bottom: 4px;
}

.brnztztpp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 2px;
}

.imgbrnz {
  width: 25px;
  height: 25px;
}

.imgbrnz img {
  width: 100%;
  height: 100%;
}

.nametxthh {
  font-family: NoirProLight;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #1d36ad;
  padding: 0;
  margin: 0 10px 0 0;
}

.brnzttxy {
  font-family: NoirProLight;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
}

.emplyrtxtpp {
  font-family: Mandaufont;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #7c7979;
  margin-bottom: 3px;

}

.sara_tylor .top_next {
  width: 100%;
  height: auto;
  padding: 5px 40px 12px;
}

.sara_tylor .top_next {
  padding: 10px 20px 20px;
}



.sharethghtsimg {
  width: 100%;
  height: 100%;
  margin-bottom: 5px;
}

.sharethgtsbg {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  /* border-radius: 10px; */
  width: 100%;
  height: 100%;
}

.sharethghtsimg img {
  width: 100%;
  height: 100%;
}

.like_comment_areasec {
  width: 100%;
  height: auto;
  padding: 10px 30px 10px;
  border-bottom: 1px solid rgba(198, 198, 210, 0.48);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rightshare {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
}

.plpotxt {
  font-family: NotoSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(38, 50, 56, 0.92);
  padding: 0;
  margin: 0;
}

.cmntdtxt {
  font-family: NotoSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(38, 50, 56, 0.92);
  padding: 0;
  margin: 0;
}

.shrtxt {
  font-family: NotoSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(38, 50, 56, 0.92);
  padding: 0;
  margin: 0;
}

.reaction_areasec {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 25px;
  margin-bottom: 8px;
}

.reactionmainsc {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
}

.rcmgdivmai {
  width: 25px;
  height: 25px;
  position: relative;
  color: #f7be0d;
}

.rcmgdivmai img {
  width: 100%;
  height: 100%;
}

.txtrecppp {
  color: #000;
  font-size: 12px;
  line-height: 14px;
  font-family: Noirprolight;
  font-weight: 500;
}

.advcy_mainright {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 7px;
  margin-top: 0px;

}

.advcy_mainright .top {
  width: 100%;
  height: auto;
  padding: 8px 12px 8px 12px;
  border-bottom: 1px solid rgba(198, 198, 210, 0.26);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.advcy_mainright .bulleBoardFig {
  width: 25px;
  height: 25px;
  /* margin: 0 3px 0 0; */
}

.advcy_mainright .bulleBoardHead {
  display: flex;
  align-items: center;
}

.advcy_mainright .bulleBoardFig img {
  width: 100%;
  height: 100%;
}

.advcy_mainright .top .head h4 {
  font-family: NoirProRegular;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
  color: #263238;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
  color: #263238;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

.advcy_mainright .add_seeallflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
}

.advcy_mainright .tyEvMainDiv {
  margin-right: 7px;
}

.advcy_mainright .bulleBoardFig {
  width: 25px;
  height: 25px;
}

.advcy_mainright .bulleBoardFig img {
  width: 100%;
  height: 100%;
}

.sharediv {
  width: 100%;
  height: 85px;
  padding: 6px 10px;
}

.sharediv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.sharedivbg {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 10px;
  margin: 2%;
}

.advcytxtflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(198, 198, 210, 0.26);
  /* border-top: 1px solid #fff; */
  padding: 5px 10px;
}

.viwbtndiv {
  font-family: NotoSans;
  border: none;
  box-shadow: 0px 0px 5px 0px #ccc;
  padding: 3px 6px;
  color: #000;
  font-size: 10px;
  font-weight: 700;
  border-radius: 5px;
  background-color: #ffffff;
}

.rtstpp {
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  width: 100%;
  max-width: 183px;
}

.joiniative_mainright {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding-bottom: 7px;
}

.initiativediv {
  padding-top: 20px;
}

.mytemtabmaindiv .react-tabs__tab-panel {
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.mytemtabmaindiv .react-tabs__tab-panel::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.mytemtabmaindiv .react-tabs__tab-panel {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.joiniative_mainright .top {
  width: 100%;
  height: auto;
  padding: 8px 12px 8px 12px;
  border-bottom: 1px solid rgba(198, 198, 210, 0.26);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.joiniative_mainright .bulleBoardFig {
  width: 25px;
  height: 25px;
  /* margin: 0 3px 0 0; */
}

.joiniative_mainright .bulleBoardHead {
  display: flex;
  align-items: center;
}

.joiniative_mainright .bulleBoardFig img {
  width: 100%;
  height: 100%;
}

.joiniative_mainright .top .head h4 {
  font-family: NoirProRegular;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  color: #263238;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

.joiniative_mainright .add_seeallflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
}

.joiniative_mainright .tyEvMainDiv {
  margin-right: 7px;
}

.joiniative_mainright .bulleBoardFig {
  width: 25px;
  height: 25px;
}

.joiniative_mainright .bulleBoardFig img {
  width: 100%;
  height: 100%;
}

.joiniative_mainright .bottom {
  width: 100%;
  max-height: 170px;
  padding: 0px 12px 0px 12px;
  overflow-y: auto;
}

.joiniative_mainright .bottom .card_area {
  width: 100%;
  height: auto;
  /* border-radius: 10px; */
  overflow: hidden;
  padding: 5px 5px;
  margin-bottom: 8px;
  /* background: rgb(0 101 0 / 77%); */
  position: relative;
  margin-top: 8px;
  border-bottom: 1px solid #ccc;
}

.txstyppp {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #000;
  width: 100%;
  max-width: 170px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 3px;
  font-family: NoirProLight;
}

.initidtdltsdivflxmain {
  display: flex;
  justify-content: space-between;
}

.rwdtxtpp {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  width: 100%;
  max-width: 137px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 3px;
  font-family: NoirProLight;
}

.training_mainright {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 7px;
  margin-top: 0px;
}

.training_mainright .top {
  width: 100%;
  height: auto;
  padding: 8px 12px 8px 12px;
  border-bottom: 1px solid rgba(198, 198, 210, 0.26);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.training_mainright .bulleBoardFig {
  width: 25px;
  height: 25px;
  /* margin: 0 3px 0 0; */
}

.training_mainright .bulleBoardHead {
  display: flex;
  align-items: center;
}

.training_mainright .bulleBoardFig img {
  width: 100%;
  height: 100%;
}

.training_mainright .top .head h4 {
  font-family: NoirProRegular;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
  color: #263238;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
  color: #263238;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

.training_mainright .add_seeallflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
}

.training_mainright .tyEvMainDiv {
  margin-right: 7px;
}

.training_mainright .bulleBoardFig {
  width: 25px;
  height: 25px;
}

.training_mainright .bulleBoardFig img {
  width: 100%;
  height: 100%;
}

.training_mainright .bottom {
  width: 100%;
  max-height: 248px;
  padding: 0px 12px 0px 12px;
  overflow-y: auto;
}

.training_mainright .bottom {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.training_mainright .bottom::-webkit-scrollbar {
  display: none;
}

.training_mainright .bottom .card_area {
  width: 100%;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  padding: 5px 5px;
  margin-bottom: 8px;
  background: rgb(0 101 0 / 77%);
  position: relative;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

.cardimagetrainingflx {
  display: flex;
  justify-content: flex-start;
  column-gap: 4px;
  /* align-items: center; */
}

.trainingpartimg {
  width: 100%;
  max-width: 85px;
  height: 72px;
}

.trainingpartimg img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.trainpartdetails h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  width: 100%;
  max-width: 116px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  font-family: NoirProLight;
}

.trainpartdetails p {
  font-family: "Mandaufont";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #ffffff;
  padding: 4px 0px 0px 0px;
  margin: 0px;
}

.dotdiv i {
  font-size: 14px;
  color: #fff;
}

.affinity_mainright {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 7px;
  margin-top: 0px;
}

.affinity_mainright .top {
  width: 100%;
  height: auto;
  padding: 8px 12px 8px 12px;
  border-bottom: 1px solid rgba(198, 198, 210, 0.26);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.affinity_mainright .bulleBoardFig {
  width: 25px;
  height: 25px;
  /* margin: 0 3px 0 0; */
}

.affinity_mainright .bulleBoardHead {
  display: flex;
  align-items: center;
}

.affinity_mainright .bulleBoardFig img {
  width: 100%;
  height: 100%;
}

.affinity_mainright .top .head h4 {
  font-family: NoirProRegular;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
  color: #263238;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
  color: #263238;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

.affinity_mainright .add_seeallflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
}

.affinity_mainright .tyEvMainDiv {
  margin-right: 7px;
}

.affinity_mainright .bulleBoardFig {
  width: 25px;
  height: 25px;
}

.affinity_mainright .bulleBoardFig img {
  width: 100%;
  height: 100%;
}

.affinity_mainright .react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #aaa;
  margin: 0px 0px 0px 0px;
  padding: 0;
}

.affinity_mainright .react-tabs__tab--selected {
  background: transparent;
  border: 0;
  font-weight: 600;
  border-bottom: 4px solid rgb(235 111 28);
}

.affinity_mainright .react-tabs__tab {
  width: 49%;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  font-family: NotoSans;
}

.affinity_mainright .bottom {
  width: 100%;
  max-height: 391px;
  padding: 20px 20px 0px;
  overflow-y: auto;
  padding: 3px 12px 0px 12px;
}

.affinity_mainright .bottom .card_area {
  width: 100%;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  padding: 5px 5px;
  margin-bottom: 8px;
  background: rgb(0 101 0 / 77%);
  position: relative;
  margin-top: 8px;
  /* display: flex; */
  /* justify-content: space-between; */
}

.imgprflgrp {
  width: 50px;
  min-width: 50px;
  height: 50px;
}

.imgprflgrp img {
  width: 100%;
  min-width: 100%;
  height: 100%;
  border-radius: 50%;
}

.sharedivicon {
  width: 25px;
  height: 25px;
  background-color: #fff;
  padding: 1%;
  border-radius: 6px;
  margin-left: auto;
}

.sharedivicon img {
  width: 100%;
  height: 100%;
}

.imhprofileaffinityflx {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  column-gap: 12px;
}

.grptxtpp {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  width: 100%;
  max-width: 116px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  font-family: NoirProLight;
}

.creatdtxtpp {
  color: #ffffff;
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 3px 0px;
}

.grpmsgbrdr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px 0 3px;
  margin-top: 16px;
  border-top: 1px solid #ffffff;
}

.pstdivbg {
  width: 25px;
  height: 25px;
}

.pstdivbg img {
  width: 100%;
  height: 100%;
}

.searchdivmain {
  width: 100%
}

.searchdivmain input {
  width: 95%;
  padding: 8px 10px;
  border-radius: 5px;
  border: none;
}

.searchdivmain input:focus {
  outline: none;
}

.pstdivbgmain {
  width: 100%;
  max-width: 14%;
  background-color: #fff;
  background-color: #fff;
  /* padding: 1%; */
  border-radius: 6px;
  padding: 3px 1px;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.affinitydivheight {
  width: 100%;
  max-height: 391px;
  overflow-y: auto;
}

.affinitydivheight {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.affinitydivheight::-webkit-scrollbar {
  display: none;
}

.vwbtndiv {
  font-family: NotoSans;
  border: none;
  box-shadow: 0px 0px 5px 0px #ccc;
  padding: 3px 6px;
  color: #000;
  font-size: 10px;
  /* line-height: 12px; */
  font-weight: 700;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
}

/* .divtxtppmain{
  padding-top: 8px;
} */


/* Bulletin Details Page Start */
.detailspagecontainer {
  width: 100%;
  max-width: 84%;
  margin: 0 auto;
}

.bulletindetailsbg {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;

}

.singlepagetop {
  width: 100%;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.singllepageicon {
  width: 100%;
  max-width: 25px;
  min-width: 25px;
  height: 25px;
}

.singllepageicon img {
  width: 100%;
  height: 100%;
}

.singlepagetoptxt {
  font-family: NoirProRegular;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
  color: #263238;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

.singlebulletinflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}

.detailsdordivflx {
  display: flex;
  justify-content: space-between;
}

.bulletindetailssection {
  padding: 100px 0px 90px 0px;
}

.singleimagebulletin {
  width: 100%;
  max-width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.singleimagebulletin::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: #00000057;
  z-index: 1;
}

.singleimagebulletin img {
  width: 100%;
  height: 100%;
}

.singlepagebottomm {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 28px;
  row-gap: 20px;
  padding: 15px 15px 15px 20px;
  flex-wrap: wrap;
}

.singlepagebottommmaindv {
  width: 100%;
  max-width: 23%;
  min-width: 23%;
  width: 100%;
  height: auto;
  background: #eaebfb;
  border-radius: 10px;
  overflow: hidden;
  background: rgb(255, 255, 255);
  box-shadow: rgb(204, 204, 204) 0px 0px 5px 0px;
}

.bulletindetailsbottom {
  padding: 10px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.bulletintxtppsingle {
  font-family: noirprolight;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #000;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 7px;
  margin-top: 7px;
}

.bulletintxtppsingledate {
  font-family: noirprolight;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #000;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 7px;
  margin-top: 7px;
}

.bulletintxtppsingledate span {
  margin-left: 5px;
  font-size: 15px;
}

.viebtndivmain {
  align-self: flex-start;
}

.thredotsbtn {
  position: absolute;
  top: 10px;
  right: 11px;
  bottom: 92%;
  z-index: 5;
}

.thredotsbtn i {
  color: #fff;
  font-size: 18px;
}

/* Bulletin Details Page End */

/* Hall of fame Page Start */
.hallofdetailssection {
  padding: 100px 0px 50px 0px;
}

.singlehallofdetilasdivbg {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 10px;
  width: 100%;
  max-width: 19%;
  min-width: 19%;
}

.halloffameprofile {
  width: 100%;
  max-width: 70px;
  min-width: 70px;
  height: 70px;
  margin: auto;
}

.halloffameprofile img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.halloftxtppspcldiv {
  font-family: NoirProLight;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #263238;
  padding: 0;
}

.halloftxtppspcldivflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.singlehallofdetilasdivbottom {
  padding: 10px 15px;
}

.singlehallofdetilasdivtop {
  padding: 10px 15px;
}

.rsnhalloftxtp {
  font-family: Noirprolight;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #7c7979;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin-bottom: 6px;
}

.rsnhalloftxtp span {
  font-family: Noirprolight;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #7c7979;
  margin: 0px 5px 0px 0px;
}

.singlepagebottommhalloff {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  padding: 15px 20px;
}

.editdltdivflxmain {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  border: 1px solid #fff;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 5px;
  position: absolute;
  right: 3px;
  padding: 4px 10px;
  border: 1px solid #ccc;
}

.plismnisn i {
  font-size: 12px;
  color: red;
}

.plismnisn {
  width: 17px;
  height: 18px;
  display: flex;
}

.plismnisn img {
  width: 100%;
  height: 100%;
}


/* Hall of fame Page End */


/* Initiative  Details Page Start */
.initiativedetailsection {
  padding: 100px 0px 80px 0px;
}

.inititaivedivbottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  row-gap: 10px;
  padding: 15px 20px;
  flex-wrap: wrap;
}

.inititaivedivbottobg {
  background: rgb(242, 233, 220);
  width: 100%;
  max-width: 24%;
  min-width: 24%;
  border-radius: 10px;
  padding: 10px 15px;
}

.datevwdivflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.intiatvtxtppdtls {
  font-family: Noirprolight;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin-bottom: 10px;
  width: 100%;
  max-width: 214px;
}

.intiatvtxtppdtls span {
  font-family: Noirprolight;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 15px;
  color: #000;
  margin: 0px 5px 0px 0px;
}

.initiativethreetxtppflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.initiativethree i {
  color: #fff;
  font-size: 14px;
}

/* Initiative Details Page End */

/* Event Details Page Start */
.eventdetailssection {
  padding: 100px 0px 50px 0px;
}

.doticondyls {
  position: absolute;
  z-index: 99;
  top: 10px;
  right: 26px;
  z-index: 99;
}

.doticondyls i {
  font-size: 18px;
  color: #fff;
}

/* Event Details Page End */

/* Bulletin Single Page Start */
.bulletinsinglemain {
  width: 100%;
  height: auto;
  padding: 100px 0px 90px 0px;
}

.bulletinimgmain {
  width: 100%;
  height: 350px;
  position: relative;
}

.bulletinimgmain img {
  width: 100%;
  height: 100%;
}

.bulletinimgmaindtlstxt {
  background-color: #fff;
  width: 100%;
  height: auto;
  max-width: 80%;
  padding: 20px;
  position: relative;
  left: 50%;
  transform: translatex(-50%);
  margin-top: -84px;
  border: 1px solid #ccc;
}

.builletindtlstsxtpp {
  font-size: 14px;
  line-height: normal;
  color: #000;
  font-family: poppinslight;
  font-weight: 600;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 50;
  overflow: hidden;
}

.builletindtlstsxtpp span {
  font-size: 14px;
  line-height: normal;
  color: #000;
  font-family: poppinslight;
  font-weight: 500;
  margin-left: 5px;
}

/* Bulletin Single Page End */

/* Create Event Page Start */
.createeventdetails {
  width: 100%;
  height: auto;
  padding: 100px 0px 50px 0px;
}

.createdivfrmmain {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px 70px;
  border-radius: 30px;
}

.trainingpageleftdivmain {
  padding-bottom: 10px;
}

.rsntxrpnewtxt {
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  font-family: noirprolight;
  color: #000;
}

.typstrningwhselctnew {
  width: 100%;
  padding: 17px 15px;
  border-radius: 4px;
  margin: 10px 0px 10px 0px;
  border: none;
  box-shadow: 0px 4px 9px 0px #ccc;
}

.isHighLightedRadiodivnewdiv {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  padding: 10px;
  margin: 10px 0px 0px 0px;
}

.isHighLightedRadiodivnew {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
}

.isHighLightedRadiodivnew input {
  width: 22px;
  height: 17px;
}

.isHighLightedRadiodivnew label {
  margin: 0px;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  font-weight: 500;
  font-family: noirprolight;
}

.txtdivallartadvcacytfnew {
  width: 100%;
  height: 116px;
  resize: none;
  margin: 10px 0px 10px 0px;
  border-radius: 4px;
  padding: 12px;
  border: none;
  box-shadow: 0px 4px 9px 0px #ccc;
}

.vdoulddivpbupldgnewmmll {
  width: 100%;
  max-width: 100%;
}

.trainingcreatepagdivflx {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  column-gap: 40px;
}

.bgcrdupload {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 #ccc;
  flex-direction: column;
  height: 100%;
  padding: 5px;
}

.trainernametextinputdiv {
  width: 100%;
  max-width: 48%;
}

.cjhekeddivtxtdivmain {
  padding-top: 15px;
}

.cjhekeddivtxtdivmain input {
  margin-left: 10px;
}

.cjhekeddivtxt {
  margin-bottom: 5px;
}

.bgcontaineruplddiv {
  background-color: #dedede;
  border-radius: 5px;
  height: 80px;
  max-width: 76px;
  min-width: 76px;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.uploaddocumentbrdraddbtnflx {
  column-gap: 11px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.uplddwnlddivbrtnmain {
  max-width: 20%;
  width: 100%;
}

.uploaddocumentbrdraddbtn {
  max-width: 100%;
  width: 100%;
}

.imageuplddivcrttraingnew {
  width: 100%;
  max-width: 40px;
  min-width: 40px;
  height: 36px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.imageuplddivcrttraingnew img {
  width: 100%;
  height: 100%;
}

.bgcontainerupldfiledivabsltcrttraindiv {
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translate(-33%, -50%);
  z-index: 9;
}

.imageupldcrstraingcrt {
  position: absolute;
  top: -11px;
  right: -11px;
  z-index: 9;
}

.mytemadmaindiv {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  /* margin-bottom: 19px; */
  margin-top: 0px;
  padding-bottom: 7px;
}

.myteamdiv {
  padding-top: 20px;
  padding-bottom: 20px;
}

.mytemadmaindiv .top {
  width: 100%;
  height: auto;
  padding: 8px 12px 8px 12px;
  border-bottom: 1px solid rgba(198, 198, 210, 0.26);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mytemadmaindiv .top .head h4 {
  font-family: NoirProRegular;
  color: #263238;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  color: #263238;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

.mytemadmaindiv .bulleBoardHead {
  display: flex;
  align-items: center;
}

.mytemadmaindiv .add_seeallflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
}

.mytemadmaindiv .bulleBoardFig {
  width: 25px;
  height: 25px;
}

.mytemadmaindiv .bulleBoardFig img {
  width: 100%;
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.bulletin_boardengage .bottom::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.bulletin_boardengage .bottom {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.joiniative_mainright .bottom::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.joiniative_mainright .bottom {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.lrmtxtpptxtpp {
  color: #263238;
  font-family: Noirprolight;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  /* max-width: 92px;
  min-width: 92px; */
  padding: 5px 0px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.imageupldcrstraingcrt i {
  font-size: 14px;
  color: red;
}

.lrmtxtppfeddpst {
  font-family: Mandaufont;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #263238;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin: 5px 0px;
}

.uploaddocumentbrdr {
  align-items: center;
  border: 1px solid #000;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin: 5px 0 0;
  max-width: 100%;
  padding: 4px;
  width: 100%;
}

.bgcontaineruplddocumnet {
  background-color: #dedede;
  border-radius: 50%;
  height: 40px;
  max-width: 42px;
  min-width: 42px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

.bgcrdupload {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

.addimgicondivgg {
  height: 27px;
  width: 26px;
}

.addimgicondivgg img {
  height: 100%;
  width: 100%;
}

.upldimagediv {
  height: 100%;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 4;
}

.addmirfgghdivny {
  background-color: #ccc;
  border: none;
  border-radius: 5px;
  color: #000;
  font-family: notosans;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  margin-top: 4px;
  outline: 0;
  padding: 2px 6px;
}

.btnalignt {
  position: absolute;
  right: 0;
  top: -3px;
}

.btnalignt i {
  color: #ff2020;
  font-size: 12px;
}

.cjhekeddivtxt label,
.radiobtntxtpp label {
  color: #000;
  font-family: noirprolight;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  margin-left: 5px;
}

.cjhekeddivtxtdivmain input {
  margin-left: 10px;
}

.bgcontainerupldboxsgwgrdiv {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 15px 0px 15px 0px;
  box-shadow: 0px 4px 9px 0px #ccc;
}

.bgcrd {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  padding: 5px;
  position: relative;
}

.bgcontaineruplddivnew {
  width: 100%;
  max-width: 100px;
  min-width: 100px;
  height: 100px;
  background-color: #dedede;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: relative;
}

.bgcontainerupld {
  width: 100%;
  max-width: 60px;
  min-width: 60px;
  height: 50px;
  background-color: #dedede;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.rsntxrpnewtxt {
  color: #000;
  font-family: noirprolight;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
}

.addimgicondivggview {
  cursor: pointer;
  height: 25px;
  position: relative;
  width: 25px;
}

.addimgicondivggview img {
  width: 100%;
  height: 100%;
}

.divtrainingmainflx {
  align-items: center;
  column-gap: 10px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.divtraininhfrst {
  max-width: 48%;
  width: 100%;
}

.hivermespaecial {
  bottom: 19px;
  color: #000;
  display: none;
  font-family: notosans;
  font-size: 12px;
  font-weight: 600;
  left: 0;
  position: absolute;
  right: 0;
  transition: all .5s ease-in-out;
  white-space: nowrap;
  width: 100%;
}

.viewicontextflxshpwd {
  align-items: center;
  column-gap: 10px;
  display: flex;
  justify-content: flex-start;
}

.addimgicondivggview:hover .hivermespaecial {
  display: block;
}

.logouplddiv {
  width: 45px;
  height: 45px;
}

.homePgCreModSubmitBtn {
  background: #64a382;
  border: 0;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-family: NoirProLight;
  font-size: 16px;
  font-weight: 500;
  margin: 7px;
  padding: 7px 15px;
  text-align: center;
}

.logouplddiv img {
  width: 100%;
  height: 100%;
}

.upldimagediv {
  position: absolute;
  top: 50%;
  z-index: 4;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.bgcontainerupldfiledivabslt {
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-44%, -50%);
  z-index: 8;
}

.cmntstocnobdivflcpdadad .EmojiPickerReact {
  height: 361px !important;
  width: 303px !important;
  position: absolute;
  top: 0;
  right: 97px;
}

.imageuplddiv {
  width: 100%;
  max-width: 85px;
  min-width: 85px;
  height: 79px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.imageuplddiv img {
  width: 100%;
  height: 100%;
}

.imageupldcrs {
  position: absolute;
  top: -20px;
  right: -8px;
  z-index: 9;
}

.imageupldcrs i {
  color: red;
  font-size: 18px;
}

.upldtxtppdiv {
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  font-family: noirprolight;
  color: #000;
  padding-top: 10px;
}

.bulletinsbmtbtndivv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
}

.bulletinsbmtbtn {
  border: 0;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  padding: 5px 12px;
  border-radius: 8px;
  font-family: notosans;
  text-align: center;
  cursor: pointer;
  margin: 10px 0px;
  background-color: #c39845;
  ;
}

/* Create Event Page End */

/* Initiative Single Page Start */
.initiativesinglesection {
  padding: 100px 0px 50px 0px;
}

.initiatvesinglebg {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

.mytemtabmaindiv .react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  display: flex;
  justify-content: space-between;
}

.mytemtabmaindiv .react-tabs__tab-list {
  margin: 0 0 0px;
  padding: 0;
}

.mytemtabmaindiv .react-tabs__tab {
  width: 49%;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  font-family: NotoSans;
}

/* .mytemtabmaindiv .react-tabs__tab:focus {
  outline: none;
} */

.mytemtabmaindiv .react-tabs__tab--selected {
  background: #0000;
  border: 0;
  border-bottom: 4px solid #eb6f1c;
  font-weight: 600;
}

.evetCreMainDiv {
  padding: 5px 0px;
  border-radius: 0px;
  border-bottom: 1px solid #ccc;
  margin: 0px 6px;
}

.eveCreAfisDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 3px 0px;
  column-gap: 10px;
  padding-bottom: 10px;
}

.profile_pic {
  width: 100%;
  max-width: 50px;
  min-width: 50px;
  height: 50px;
}

.profile_pic img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.hubEvCreParamainpp {
  color: #263238;
  font-family: 'NoirProLight';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 8px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 4px;
  padding-bottom: 4px;
}

.myAffCreBtnsDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.myAffCrePara {
  color: #263238;
  font-family: 'NoirProLight';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.myAffTypeBtnInpDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 7px 3px 3px;
  margin-top: 12px;
  background-color: #fdfdfd;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 5px;
}

.myAffChatTypeInp {
  max-width: 100%;
  width: 100%;
  padding: 8px 10px;
  border-radius: 4px;
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  border: 0;
  margin-right: 7px;
}

.shareimgmn {
  width: 20px;
  height: 20px;
}

.shareimgmn img {
  width: 100%;
  height: 100%;
}

.jnbtndiv {
  border: none;
  box-shadow: 0px 0px 5px 0px #ccc;
  padding: 2px 8px;
  color: #fff;
  font-size: 11px;
  line-height: 15px;
  font-weight: 700;
  border-radius: 5px;
  background-color: #c39845;
  ;
  outline: 0;
}

.jnbtndivothvtr {
  border: none;
  padding: 2px 8px;
  color: #64a382;
  font-size: 11px;
  line-height: 15px;
  font-weight: 700;
  border-radius: 5px;
  background-color: #ffffff;
  outline: 0;
  margin-bottom: 9px;
}

.jnbtndivothvtrmain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.jnbtndivmainjh {
  margin-left: auto;
}

.dotsticondrdtdlyt i {
  font-size: 18px;
  color: #000;
  cursor: pointer;
  position: relative;
}

.addinconhjfpnh {
  width: 100%;
  max-width: 25px;
  min-width: 25px;
}

.addinconhjfpnh img {
  width: 100%;
  height: 100%;
}

/* Initiative Single Page End */

.myaffinitygrpmainsection {
  padding: 100px 0px 50px 0px;
}

.myaffinitydetailsbg {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc
}

.afiifnitygrpbgmin {
  width: 100%;
  max-width: 18%;
  background-color: #f1e5dd;
  border-radius: 10px;
}

.affinitysinammai {
  width: 100%;
  max-width: 80px;
  min-width: 80px;
  height: 80px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 2px;
  margin: auto;
}

.hubEvCrePara {
  color: #000;
  font-family: 'NoirProLight';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: -1px 8px 13px 8px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 261px;
  text-align: center;
}

.affinitysinammai img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.affinitysinammaibrdr {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 10px;
}

.affinitygrpbtm {
  border-top: 2px solid #fff;
  padding: 15px 20px;
  height: 135px;
}

.affinytdtlksttxpp {
  font-size: 15px;
  color: #000;
  font-weight: 400;
  font-family: Noirprolight;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin-bottom: 6px;
}

.affinytdtlksttxpp span {
  font-family: Noirprolight;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-right: 7px;
}

.singlepagebottommaffinity {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 29px;
  row-gap: 21px;
  padding: 15px 40px;
  flex-wrap: wrap;
}

.admincrtdbtnmdivflclm {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.initiativethreedlf {
  position: relative;
}

.initiativethreedlf i {
  font-size: 14px;
  color: #000;
}

.initiativethreedlfown {
  position: relative;
}

.wiillrndivflcx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  column-gap: 10px;
  padding-bottom: 10px;
}

.coursrincldflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 2px;
  width: 100%;
  max-width: 32%;
}

.cousrsedidicnimg {
  width: 100%;
  max-width: 25px;
  min-width: 25px;
  height: 25px;
}

.cousrsedidicnimg img {
  width: 100%;
  height: 100%;
}

.cousredtlstxtppas {
  font-size: 16px;
  line-height: 19px;
  font-family: noirprolight;
  color: #000;
  font-weight: 600;
  margin-left: 15px;
  /* margin-right: 10px; */
  margin-bottom: 5px;
}

.wiillrndiv {
  padding-top: 20px;
}

.wiillrndivflcxdiv {
  padding-top: 10px;
}

.newlerndivmainleftbrdr {
  width: 100%;
  border: 2px solid rgb(233, 210, 0);
  /* padding: 10px; */
  border-radius: 10px;
  background-color: #FFF;
  padding: 10px;
}

.crsecntnttxtppp {
  font-family: NoirProRegular;
  font-size: 20px;
  line-height: normal;
  padding: 0;
  color: #000000;
  margin: 0;
}

.Introductiontxtanglebgmain {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 10px;
  padding: 10px;
  margin: 15px 0px 8px 0px;
}

.Introductiontxtangle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.Introductiontxtangle span {
  font-size: 17px;
  font-weight: 700;
  color: #000;
}

.intrdivppptxts {
  font-size: 16px;
  line-height: 20px;
  color: #000;
  font-weight: 600;
  font-family: notosans;
}

.undrstndgppp {
  font-size: 14px;
  line-height: 18px;
  color: #000;
  font-family: notosans;
  font-weight: 500;
  padding: 4px 0px;
}

.contetxtxttimeflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
}

.undrstndgppp {
  font-size: 14px;
  line-height: 18px;
  color: #000;
  font-family: notosans;
  font-weight: 500;
  padding: 4px 0px;
}



.commentsectionare {
  width: 100%;
  height: auto;
}

.profilemcmnrtimg {
  width: 100%;
  max-width: 40px;
  min-width: 40px;
  height: 40px;
}

.profilemcmnrtimg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.cmnttsttxt {
  width: 100%;
  max-width: 100%;
  position: relative;
}

.cmnttsttxt textarea {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px 120px 10px 20px;
  border-radius: 40px;
  position: relative;
  resize: none;
  height: 45px;
}

.cmnttsttxtreply {
  width: 100%;
  max-width: 100%;
  position: relative;
}

.cmnttsttxtreply textarea {
  width: 100%;
  background-color: transparent;
  border: 0px solid #ccc;
  padding: 10px 0px 10px 0px;
  position: relative;
  resize: none;
  height: 70px;
}

.cmnttsttxtreply textarea:focus {
  border: 0px solid #ccc;
  outline: 0;
}

.cmnttxnmdivthreeotdidtfl {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.treplyrxrtdivflx {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.trepkcttcbbh {
  font-size: 15px;
  color: #000;
  font-weight: 600;
  font-family: poppinslight;
  line-height: 18px;
}

.cmntpprfcdivflxrplyanothrr {
  display: flex;
  justify-content: flex-start;
  column-gap: 20px;
  padding: 19px 0px 10px 65px;
}

.cmntpprfcdivflxchnng {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  padding: 19px 0px 10px 0px;
}

.cmntstocnobdivflcpdadad .comment-emoji-icon {
  font-size: 24px;
  color: #f7be0d;
  position: relative;
}

.threedotscmntddiv {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 15px;
  right: 21px;
  background-color: #fff;
}

.treplyrxrtdivflx {
  padding-top: 5px;
}

.edoiytdltreptyicon {
  display: contents;
}

.edoiytdltreptyicon i {
  font-size: 18px;
  color: #ff0000;
}

.threedotscmntdownmmd {
  position: relative;
}

.threedotscmntd {
  position: relative;
}

.threedotscmntd i {
  font-size: 18px;
  color: #000;
  cursor: pointer;
}

.cmnttsttxtreply textarea::-webkit-scrollbar {
  display: none;
}

.cmnttsttxtreply textarea {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for IE, Edge and 
/* Hide scrollbar for Chrome, Safari and Opera */
.cmnttsttxt textarea::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.cmnttsttxt textarea {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.cmnttsttxt textarea:focus {
  outline: 0;
  border: 1px solid #ccc;

}

.cmntpprfcdivflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
}

.cmntpprfcdivflxrply {
  display: flex;
  justify-content: flex-start;
  column-gap: 20px;
  padding: 20px 0px 10px 20px;
}

.cmntscedtdiv {
  width: 100%;
}

.cmnttxnmdivthreeobrdrs {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px 20px 10px 20px;
  background: #f6f6f6;
  border-radius: 9px;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.08);
}

.commentsectionare {
  width: 100%;
  padding: 0px 20px;
}

.cmntstocnobdivflcpdadad {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
  position: absolute;
  top: 8px;
  right: 23px;
}

.imagjdbreokd {
  position: relative;
  width: 100%;
  max-width: 80px;
  min-width: 80px;
  height: 80px;
}

.imagjdbreokd img {
  width: 100%;
  height: 100%;
}

.crsyhhimbcfg {
  position: absolute;
  top: -13px;
  right: -17px;
}

.crsyhhimbcfg i {
  font-size: 16px;
  color: red;
}

.imagjdbreokddiv {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px 20px 10px 20px;
  background: #f6f6f6;
  border-radius: 9px;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0);
  max-width: 20%;
}

.imagesinglepost {
  width: 100%;
  max-width: 200px;
  min-width: 200px;
  height: 200px;
  margin: auto;
}

.imagesinglepost img {
  width: 100%;
  height: 100%;
}


.cmnstrply {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
}

.cmntprfgname {
  color: rgba(0, 0, 0, 0.92);
  font-family: noirproregular;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 4px;
  padding: 0;
}

.grptesting {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 19px;
  padding: 10px 0px;
}

.grptestingtop {
  padding: 10px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.grptestingbottom {
  padding: 10px 15px;
}

.grptestimg {
  width: 100%;
  max-width: 50px;
  min-width: 50px;
  height: 50px;
}

.grptestimg img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 50%;
}

.sttygaghojppd {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  font-family: noirprolight;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
}

.rcmgdivmai input[type=file] {
  background-color: initial;
  font-size: 0;
  height: 25px;
  left: 0;
  max-width: 36px !important;
  opacity: 0;
  padding: 0 !important;
  position: absolute;
  top: 2px;
  width: 100%;
  z-index: 7;
}

.grgsmmbdtklstxtop {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  font-family: poppinslight;
  line-height: 22px;
  width: 100%;
  max-width: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

}

.grgsmmbdtklstxtop span {
  font-size: 14px;
  margin-left: 5px;
}

.grptestinglnmjd {
  text-decoration: none;

}

.advocacymainsection {
  width: 100%;
  height: auto;
  padding: 100px 0px 50px 0px;
}

.advocacymainsection .rts___tabs___container {
  display: flex;
  overflow: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.advocacymainsection .rts___nav___btn {
  max-width: 30px;
  width: 100%;
  min-width: 30px;
  border-radius: 50%;
  height: 30px;
}

.thght_txt {
  color: #d3a14587;
  cursor: pointer;
  font-family: NotoSans;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
}


.advocacymainsection .rts___tab___selected {
  background-image: linear-gradient(#ed3b4b, #4c3b92);
  ;
  color: #fff;
}

.advocacymainsection .rts___tab {
  font-size: 15px;
}

.advocaysectioondiv {
  display: flex;
  justify-content: flex-start;
  padding-top: 25px;
  flex-wrap: wrap;
  column-gap: 35px;
  row-gap: 20px;
}

.advcnprlnnimgrxrdivchng {
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px 1px #ddd;
  display: flex;
  justify-content: flex-start;
  padding: 5px 10px;
  width: 100%;
  column-gap: 20px;
}

.advocaydivcntnttopimg {
  width: 100%;
  height: 380px;
  position: relative;
}

.advocaydivcntnttopimglsr {
  width: 100%;
  height: 256px;
  position: relative;
}

.advocaydivcntnttopimglsr img {
  width: 100%;
  height: 100%;
}

.advocacycreatemaindiv {
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50px;
  box-shadow: 0 0 5px 0 #ccc;
  column-gap: 7px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 14px;
  padding: 5px 20px;
  width: 100%;
  text-decoration: none;
}

.vdoulddivpbupldgteodivflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
}

.vdoulddivpbupldgteodivfrst {
  width: 100%;
  max-width: 48%;
}

.addimgdiv {
  height: 39px;
  max-width: 38px;
  min-width: 38px;
  width: 100%;
}

.addimgdiv img {
  height: 100%;
  width: 100%;
}

.crtadvocacytxtypp {
  color: #000;
  font-family: noirprolight;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
}

.advocaydivcntnttopimg img {
  width: 100%;
  height: 100%;
}

.advocaydivcntntbtmcntntxt {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #263238;
  display: -webkit-box;
  font-family: NotoSans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 2px 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.advcnprlnnimg {
  width: 100%;
  max-width: 40px;
  min-width: 40px;
}

.advcnprlnnimg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.advcnprlnnimgrxrdivflkx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  column-gap: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  padding: 5px 10px
}

.avdbbvdvprflnsmm {
  color: #000;
  font-family: NotoSans;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  padding-bottom: 4px;

}

.advocaydivcntntbtmcntn {
  border-top: 1px solid #fff;
  padding: 5px 10px;
}

.advocaydivcntnt {
  width: 100%;
  max-width: 72%;
  background-color: #fff;
  padding: 2px;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 10px;
}

.advocaydivcntntscnd {
  width: 100%;
  max-width: 25%;
}

.advocaydivcntntsnmcndbmain {
  background-color: #fff;
  padding: 2px;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 10px;
  width: 100%;
  max-width: 100%;
}

.advcyothrsdiv {
  background-color: #fff;
  padding: 2px;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 10px;
  width: 100%;
  max-width: 23%;
}

.advocaydatashrdivfkx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.tetsccdivfkjsgtstpp {
  font-size: 22px;
  line-height: 27px;
  color: #000;
  font-weight: 600;
  font-family: Noirprolight;
}

.rpstiocnimg {
  width: 100%;
  max-width: 25px;
  min-width: 25px;
  height: 25px;
}

.rpstiocnimg img {
  width: 100%;
  height: 100%;
}

.prflimgtxtflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  background-color: #fff;
  box-shadow: 0px 1px 5px 1px #ddd;
  border-radius: 5px;
}

.prflsadvctimgmain {
  width: 100%;
  max-width: 100px;
  min-width: 100px;
  height: 80px;
}

.prflsadvctimgmain img {
  width: 100%;
  height: 100%;
}

.prfdtlsadvc {
  margin-left: 20px;
}

.prfladtxt {
  color: #000;
  font-size: 15px;
  line-height: 18px;
  font-family: NoirProLight;
  font-weight: 700;
  padding-bottom: 4px;
}

.loremsingleadvocaytext {
  font-size: 16px;
  line-height: 18px;
  color: #000;
  font-family: noirprolight;
  font-weight: 500;
  padding-bottom: 20px;
}

.loremsingleadvocaytext span {
  font-size: 14px;
  line-height: 18px;
  margin-left: 5px;

}

.loremsingleadvocaytextdtlspp {
  font-size: 14px;
  line-height: 18px;
  color: #000;
  font-family: noirprolight;
  font-weight: 500;
  padding-bottom: 20px;
}

.advcacylanfdtlsdivmain {
  padding: 20px 20px;
}

.dwldafiledivfxlaad {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.dwnldflxtxtp {
  font-size: 14px;
  line-height: 18px;
  color: #000;
  font-family: noirprolight;
  font-weight: 500;
}

.dwnlddivomg {
  width: 100%;
  max-width: 25px;
  min-width: 25px;
  height: 25px;
}

.dwnlddivomg img {
  width: 100%;
  height: 100%;
}

.calenderandithredatadivflx {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.clndrbigdivmaion {
  width: 100%;
  max-width: 76%;
}

.clndrbigdivmaiondiv {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
}

.calderdatadivright {
  width: 100%;
  max-width: 21%;
}

.hightlightedevntsdiv {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-top: 0px;
  padding-bottom: 7px;
}

.hightlightedevntsdiv .top {
  width: 100%;
  height: auto;
  padding: 8px 12px 8px 12px;
  border-bottom: 1px solid rgba(198, 198, 210, 0.26);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hightlightedevntsdiv .bulleBoardHead {
  display: flex;
  align-items: center;
}

.hightlightedevntsdiv .top .head h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  color: #263238;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

.hightlightedevntsdiv .add_seeallflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
}

.hightlightedevntsdiv .tyEvMainDiv {
  margin-right: 7px;
}

.hightlightedevntsdiv .bulleBoardFig {
  width: 25px;
  height: 25px;
}

.hightlightedevntsdiv .bulleBoardFig img {
  width: 100%;
  height: 100%;
}

.hightlightedevntsdiv .bottom {
  width: 100%;
  max-height: 250px;
  padding: 0px 12px 0px 12px;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hightlightedevntsdiv .bottom ::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hightlightedevntsdiv .bottom {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.hightlightedevntsdiv .bottom .card_area {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  padding: 5px 5px;
  margin-bottom: 8px;
  position: relative;
  margin-top: 8px;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.highilightdeebcbtddiv {
  padding: 10px 0px;
}

.mysubvsevntdiv {
  padding: 10px 0px;
}

.hightlightedevntsdiv .bottom .card_area .event_img {
  width: 100%;
  max-width: 85px;
  height: 72px;
  overflow: hidden;
  border-radius: 10px;
}

.hightlightedevntsdiv .bottom .card_area .event_img img {
  width: 100%;
  height: 100%;
}

.addiconplushh {
  display: flex;
  align-items: flex-start;
}

.addiconplushh i {
  color: #000;
  font-size: 12px;
}

.myteamgrpsinglemainsection {
  width: 100%;
  height: auto;
  padding: 100px 0px 50px 0px;
}

.grptxtsstxtpp {
  text-align: center;
  color: #000;
  font-family: noirproregular;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
}

.grotsxttdxpphjh {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  font-family: norproregular;
  line-height: 20px;
  margin-bottom: 10px;
}

.grotsxttdxpphjh span {
  font-size: 14px;
  margin-left: 5px;
  font-weight: 500;
}

.clndrbigdivmaiondiv .rbc-event-content {
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(204, 204, 204) 0px 0px 5px 0px;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  margin: auto;
  max-width: 93px;
  min-width: 93px;
}

.clndrbigdivmaiondiv .rbc-event .rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: transparent !important;
}

.clndrbigdivmaiondiv .rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: transparent !important;
}

.eventimagebgoverlay {
  height: 81px;
  max-width: 93px;
  min-width: 93px;
  position: relative;
  width: 100%;
}

.eventimagebgoverlay img {
  width: 100%;
  height: 100%;
}

.clndrbigdivmaiondiv .rbc-off-range-bg {
  background-color: transparent !important;
}

.profilemainsection {
  width: 100%;
  height: auto;
  padding: 100px 0px 50px 0px
}

.profiletabfrst {
  background-color: #396b4f;
  padding: 3px 25px;
  color: #fff;
  font-size: 15px;
  line-height: normal;
  text-align: center;
  border-radius: 20px;
  border: none;
  margin: 5px 0px 5px 20px;
  border: 2px solid #396b4f;
}

.profiletabscnd {
  background-color: #ffffff;
  padding: 3px 25px;
  color: #396b4f;
  font-size: 15px;
  line-height: normal;
  text-align: center;
  border-radius: 20px;
  border: none;
  margin: 5px 0px 5px 20px;
  border: 2px solid #396b4f;
}

.profilevrthdaycardbg {
  background-color: #fff;
  width: 100%;
  border-radius: 10px;
  margin: 20px 0px;
}

.topprofilediv {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
}

.leftprofilediv {
  display: flex;
  column-gap: 4px;
}

.allcardsectionbgprofile {
  width: 100%;
  max-width: 100%;
}

.valoidatedatedivtop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #396b4f;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.hobbyAspHeadslklvtxt {
  color: #fff;
  font-family: poppinslight;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.edtdivimg {
  width: 100%;
  max-width: 25px;
  min-width: 25px;
  height: 25px;
}

.edtdivimg img {
  width: 100%;
  hyphenate-character: 100%;
}

.valoidatedatediv {

  width: 100%;
  border-radius: 10px;
  margin: 20px 0px;

}

.grptestinglnmjd:hover {
  text-decoration: none;
}

.valoidatedatedivbtm {
  border-top: 1px solid #fff;
  background-color: #396b4f;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.valoidatedatedivbtm .rts___nav___btn {
  align-items: center;
  background-color: transparent;
  border: 1px solid #fff;
  stroke: #fff;
  border-radius: 50%;
  color: #000000 !important;
  content: "\f105" !important;
  display: flex;
  font-family: FontAwesome !important;
  font-size: 27px !important;
  height: 35px;
  justify-content: center;
  line-height: 1;
  opacity: 1 !important;
  width: 35px;
}

.valoidatedatedivbtm .rts___btn {
  cursor: pointer;
  background-color: transparent;
  border-radius: 40px;
  border: 2px solid #fff;
  font-size: 15px;
  font-family: noirprolight;
  color: #fff;
}

.valoidatedatedivbtm .rts___tabs___container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
}

.valoidatedatedivbtm .rts___tab___selected {
  background-color: #fff !important;
  color: #396b4f !important;
  box-shadow: #fff;
}

.valoidatedatedivbtm .rts___svg___icon {
  stroke: #fff !important;
}


.editsimgj {
  width: 100%;
  max-width: 20px;
  min-width: 20px;
  position: absolute;
  z-index: 99;
  top: 47px;
  right: -4px;
}

.editsimgj img {
  width: 100%;
  height: 100%;
}

.editsimgj input {
  width: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0;
}

.hobbisessectionmai {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 22px;
  margin-top: 1px;
  padding-bottom: 6px;
}

.hobbisessectionmai .top {
  width: 100%;
  height: auto;
  padding: 8px 12px 8px 12px;
  border-bottom: 1px solid rgba(198, 198, 210, 0.26);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hobbisessectionmai .bulleBoardHead {
  display: flex;
  align-items: center;
}

.hobbisessectionmai .top .head h4 {
  font-family: NoirProRegular;
  color: #263238;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

.hobbisessectionmai .add_seeallflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
}

.hobbisessectionmai .bulleBoardFig {
  width: 25px;
  height: 25px;
}

.hobbisessectionmai .bulleBoardFig img {
  width: 100%;
  height: 100%;
}

.hobbsiedivmain {
  margin: 19px 0px;
}

.hobbisessectionmai .bottom {
  padding: 10px;
}

.hobbiescarddiv {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.hobbiescarddiv:last-child {
  margin-bottom: 0px;
}

.wrkHobbyText {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #000000;
  display: -webkit-box;
  font-family: poppinslight;
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cancledibvimg {
  background-color: #fff;
  border-radius: 3px;
  height: 25px;
  max-width: 21px;
  min-width: 21px;
  width: 100%;
  padding: 2px 2px 6px 2px;

}

.cancledibvimg img {
  width: 100%;
  height: 100%;

}

.biotxtmanippyyy {
  color: #000;
  font-family: notosans;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.biotxtmanipp {
  color: #000;
  font-family: notosans;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.biotxtmanipp span {
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}

.biocardarea {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 8px;
  border-bottom: 1px solid #ccc;
  padding: 5px 5px;
  margin-bottom: 8px;
}

.biocardareadivbtm {
  padding: 0px 12px 0px 12px;
  width: 100%;
  max-height: 170px;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.biocardareadivbtm::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.biocardareadivbtm {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.saveitemasdiv {
  padding-top: 20px;
}

.saveitmsmain {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-top: 0px;
  padding-bottom: 7px;
}

.saveitmsmain .top {
  width: 100%;
  height: auto;
  padding: 8px 12px 8px 12px;
  border-bottom: 1px solid rgba(198, 198, 210, 0.26);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.saveitmsmain .bulleBoardHead {
  display: flex;
  align-items: center;
}

.saveitmsmain .top .head h4 {
  font-family: NoirProRegular;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  color: #263238;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

.saveitmsmain .add_seeallflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
}

.saveitmsmain .bulleBoardFig {
  width: 25px;
  height: 25px;
}

.saveitmsmain .bulleBoardFig img {
  width: 100%;
  height: 100%;
}

.saveitmsmain .bottom {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.saveitmsmain .bottom {
  width: 100%;
  max-height: 250px;
  padding: 0px 10px 0px 10px;
  overflow-y: auto;
}

.saveitmsmain .bottom .card_area {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  padding: 5px 5px;
  /* margin-bottom: 8px; */
  position: relative;
  /* margin-top: 8px; */
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.event_imgtextbillentin {
  display: flex;
  column-gap: 6px;
}

.saveitmsmain .bottom .card_area .event_img {
  width: 100%;
  max-width: 85px;
  height: 72px;
  overflow: hidden;
  border-radius: 10px;
}

.saveitmsmain .bottom .card_area .event_img img {
  width: 100%;
  height: 100%;
}

.saveitmsmain .bottom .card_area .textbillentin h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
  width: 100%;
  max-width: 137px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 7px;
  font-family: NoirProLight;
}

.saveitmsmain .bottom .card_area .textbillentin p {
  font-family: "Mandaufont";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  /* padding: 10px 0px 0px 0px; */
  margin: 0px;
}

.rewardpomntdsdiv {
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  width: 100%;
}

.rwrdpintdtltop {
  width: 100%;
  height: auto;
  padding: 8px 12px 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rewrdpointdttxt h4 {
  font-family: NoirProRegular;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  color: #263238;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

.viewdijhjsimg {
  width: 100%;
  max-width: 25px;
  min-width: 25px;
  height: 25px;
}

.viewdijhjsimg img {
  width: 100%;
  height: 100%;
}

.clndrbigdivmaiondiv .rbc-toolbar-label {
  color: #0c0000;
  font-family: Poppinslight;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
}

.clndrbigdivmaiondiv .rbc-toolbar button {
  color: #0c0000 !important;
  font-family: Poppinslight;
  font-size: 16px !important;
  font-weight: 600;
  letter-spacing: 1px;
}

.clndrbigdivmaiondiv .rbc-month-header .rbc-header:first-child {
  color: red !important;
}

.clndrbigdivmaiondiv .rbc-button-link {
  font-size: 16px !important;
}

.somdclassdkko {
  padding-top: 15px;
}

#rating {
  background: #fff;
  border-radius: 20px;
  border-radius: 10px;
  height: auto;
  margin-top: 5px;
  padding: 15px 25px 15px 26px;
  width: 100%;
}

.rateJavaHeadDiv {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.rateJavaHead {
  color: #171725;
  font-family: Noirproregular;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.rateLinkShareDiv {
  align-items: flex-start;
  display: flex;
}

.share {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 #878787;
  padding: 2px;
}

.share .shareIconImg {
  height: 25px;
  width: 25px;
}

#rating .rating_text {
  padding-top: 43px;
}

#rating .rating_text h2,
#rating .rating_text p {
  font-size: 23px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px;
  padding: 0;
}

#rating .rating_text h2 {
  color: #171725;
  font-family: NotoSans;
  margin: 5px 0 2px;
}

#rating .rating_text p {
  color: #24243069;
  font-family: NoirProLight;
  margin: 0;
}

#rating .rating_text h2,
#rating .rating_text p {
  font-size: 23px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px;
  padding: 0;
}

#rating .rating_text h2,
#rating .rating_text p {
  font-size: 23px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px;
  padding: 0;
}

#rating .rating_text h2 {
  color: #171725;
  font-family: NotoSans;
  margin: 5px 0 2px;
}

.totalFiveText {
  color: #000;
  font-family: notosans;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}

#rating .progress_wrap {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
  margin-top: 5px;
}

#rating .icon,
#rating .number {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  padding-right: 5px;
}

#rating .icon i {
  color: #ffc700;
  font-size: 14px;
  padding-right: 13px;
}

#rating .progress {
  background-color: #fff;
  height: 15px;
  max-width: 394px;
  width: 100%;
}

#rating .progress-bar {
  background: #ffa927;
}

#rating .amount {
  color: #44444f;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  padding-left: 59px;
}

#rating .comment_section {
  display: flex;
  padding-top: 95px;
  width: 100%;
}

#rating .comment_section .comment_person {
  height: 45px;
  overflow: hidden;
  width: 45px;
}

#rating .comment_section .comment_person img {
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

#rating .comment_section .comment_right {
  padding-left: 22px;
  width: 100%;
}

#rating .comment_section .comment_top {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

#rating .comment_section .comment_top_left {
  display: flex;
}

#rating .comment_right h5 {
  color: #171725;
  font-family: notosans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

#rating .person_description {
  padding-top: 2px;
}

#rating .person_description p {
  color: #44444f;
  font-family: notosans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  margin: 0;
  padding: 0;
}

.somdclassdkko {
  padding-top: 15px;
}

.coonextionnmabthntman {
  background-color: #396b4f;
  padding: 3px 25px;
  color: #fff;
  font-size: 15px;
  line-height: normal;
  text-align: center;
  border-radius: 20px;
  border: none;
  border: 2px solid #396b4f;
  width: 100%;
}

.coonextionnmabthntmanmain {
  margin: 24px 0px 0px 0px;
}

.connectgionmain {
  padding: 100px 0px 80px 0px;
}

.connectiondivmainflex {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.connectiondivright {
  width: 100%;
  max-width: 21%;
}

.connectiondivleft {
  width: 100%;
  max-width: 76%;
}

.connectiondileftbg {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
}

.followerrighttopup {
  border-bottom: 1px solid #ccc;
  padding: 15px 26px;
  width: 100%;
}

.followtoppppxyu {
  color: #000;
  font-family: poppinslight;
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
}

.followerrighttopbtm {
  width: 100%;
}

.followerrighttopbtmden {
  border-bottom: 1px solid #ccc;
  padding: 7px 15px;
  width: 100%;
}

.connectgionmain .react-tabs__tab-list {
  border-bottom: 0 !important;
  margin: 0 !important;
}

.connectgionmain .react-tabs__tab {
  font-family: poppinslight;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 5px;
  padding: 9px 20px;
  position: relative;
}

.connectgionmain .react-tabs__tab--selected {
  border: 2px double #396b4f !important;
  border-radius: 40px !important;
  color: #000 !important;
}

.connectgionmain .react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: transparent !important;
}

.prflimgdtlsfllowdivflxflwbtn {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}

.prflimgdtlsfllowdivflx {
  align-items: center;
  column-gap: 20px;
  display: flex;
  justify-content: flex-start;
}

.profilefollowimg {
  height: 40px;
  max-width: 40px;
  min-width: 40px;
  width: 100%;
}

.profilefollowimg img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.followtxtpppnmjl {
  color: #000;
  font-family: poppinslight;
  font-weight: 300;
  font-size: 14px;
}

.followtxtpppnmjldsgnty {
  font-size: 14px;
  line-height: 20px;
  color: #000;
  font-family: poppinslight;
  font-weight: 300;
  ;
}

.messageflowwingdivflx {
  align-items: center;
  column-gap: 10px;
  display: flex;
  justify-content: flex-start;
}

.followingbtndivnm {
  border: 2px double #396b4f !important;
  border-radius: 40px !important;
  color: #000 !important;
  font-family: poppinslight;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 5px;
  padding: 5px 16px;
  position: relative;
}

.followerrighttopbtmdwnl {
  padding: 15px;
}

.suggestionndivpagding {
  padding: 20px 0px 0px 0px;
}

@media(max-width:1499px) {
  .bulletin_boardengage .top {
    padding: 5px 12px 5px 12px;
  }

  .engagedivmaileft {
    left: 125px;
    width: 17%;
  }

  .engagedivmaimiddle {
    width: 44%;
  }

  .engagedivmaimiddleprfl {
    margin: 0 auto;
    width: 44%;
  }

  .singlepagebottommmaindv {
    width: 100%;
    max-width: 22%;
    min-width: 22%;
  }

  .shtretxtpp {
    font-family: noirprolight;
    font-size: 12px;

  }

  .sharethfghbrdr {
    border: 1px solid rgb(0 101 0 / 77%);
    padding: 4px 12px;

  }

  .engagedivmairight {
    right: 125px;
    width: 17%;
  }

  .performer_boardengage .top {
    padding: 5px 12px 5px 12px;
  }

  .employee_boardengage .top {
    padding: 5px 12px 5px 12px;
  }

  .events_boardengage .top {
    padding: 5px 12px 5px 12px;
  }

  .referal_boardengage .top {
    padding: 5px 12px 5px 12px;
  }

  .training_mainright .top {
    padding: 5px 12px 5px 12px;
  }

  .affinity_mainright .top {
    padding: 5px 12px 5px 12px;
  }

  .training_mainright .top .head h4 {
    font-size: 15px;
  }

  .affinity_mainright .top .head h4 {
    font-size: 15px;
  }

  .bulletin_boardengage .bottom .card_area .event_img {
    width: 100%;
    max-width: 75px;
    min-width: 75px;
    height: 62px;
  }

  .events_boardengage .bottom .card_area .event_img {
    width: 100%;
    max-width: 88px;
    min-width: 88px;
    height: 90px;
  }

  .prflimgperfrm {
    max-width: 37px;
    height: 38px;
  }

  .txtprlnm p {
    font-size: 12px;
    line-height: 16px;
  }

  .txstyppp {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }

  .txtevent {
    font-size: 13px;
    line-height: 16px;
  }

  .thght_txtdiv .thght_txt {
    font-size: 24px;
    line-height: 30px;
  }

  .bulletin_boardengage .top .head h4 {
    font-size: 15px;
  }

  .performer_boardengage .top .head h4 {
    font-size: 15px;
  }

  .employee_boardengage .top .head h4 {
    font-size: 15px;
  }

  .events_boardengage .top .head h4 {
    font-size: 15px;
  }

  .referal_boardengage .top .head h4 {
    font-size: 15px;
  }

  .events_boardengage .bottom .card_area .event_img {
    width: 100%;
    max-width: 75px;
    min-width: 75px;
    height: 62px;
  }

  .crtevnttxt p.crttxtppdshr {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
  }

  .nametxthh {
    font-size: 20px;
    line-height: 23px;
  }

  .sara_tylor .top_next p.lrmtxtpp {
    font-size: 14px;
    line-height: 18px;
  }

  .like_comment_areasec {
    padding: 0px 20px 15px;
  }

  .advocaysectioondiv {
    column-gap: 45px;
  }

  .advocaydivcntnt {
    width: 100%;
    max-width: 72%;
  }

  .advocaydivcntntscnd {
    width: 100%;
    max-width: 24%;
  }

  .plpotxt {
    font-size: 12px;
  }

  .cmntdtxt {
    font-size: 12px;
  }

  .shrtxt {
    font-size: 12px;
  }

  .reaction_areasec {
    padding: 7px 15px;
  }

  .advcy_mainright .top {
    padding: 5px 12px 5px 12px;
  }

  .advcy_mainright .top .head h4 {
    font-size: 15px;
  }

  .joiniative_mainright .top {
    padding: 5px 12px 5px 12px;
  }

  .joiniative_mainright .top .head h4 {
    font-size: 15px;
  }

  .imgprflgrp {
    width: 44px;
    min-width: 44px;
    height: 44px;
  }

  .advcyothrsdiv {
    width: 100%;
    max-width: 22%;
  }

  .bulletin_boardengage .bottom .card_area .textbillentin h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }
}

@media(max-width:1299px) {


  .thought_area_mxh-mid {
    width: 43%;
  }

  .engagedivmaileft {
    left: 102px;
    width: 18%;
  }

  .engagedivmairight {
    right: 102px;
    width: 18%;
  }


  .advocaydivcntnt {
    width: 100%;
    max-width: 68%;
  }

  .advocaysectioondiv {
    column-gap: 35px;
  }

  .cousredtlstxtppas {
    font-size: 14px;
    line-height: 16px;

  }
}

@media(max-width:1199px) {
  .inititaivedivbottobg {
    max-width: 32%;
    min-width: 32%;
  }

  .prflimgperfrm {
    max-width: 30px;
    height: 32px;
    min-width: 30px;
  }

  .hightlightedevntsdiv .top .head h4 {
    font-size: 13px;

  }

  .engagedivmaimiddle {
    padding-bottom: 37px;
    width: 43%;
  }

  .engagedivmaimiddleprfl {
    margin: 0 auto;
    width: 43%;
  }

  .sgahrethgthimg {
    width: 100%;
    max-width: 21px;
    min-width: 21px;
    height: 24px;
  }

  .engagedivmairight {
    height: 73vh;
    margin-right: 0;
    right: 82px;
    width: 19%;
  }

  .engagedivmaileft {
    left: 84px;
    width: 19%;
  }

  .singlehallofdetilasdivbg {
    max-width: 24%;
    min-width: 24%;
  }



  .editsimgj {
    width: 100%;
    max-width: 16px;
    min-width: 16px;
    position: absolute;
    z-index: 99;
    top: 41px;
    right: 0px;
  }
}

@media(max-width:1080px) {
  .engagedivmaileft {
    height: 73vh;
    left: 30px;
    margin-left: 0;
    width: 20%;
  }

  .engagedivmairight {
    height: 73vh;
    margin-right: 0;
    right: 30px;
    width: 20%;
  }



  .engagedivmaimiddle {
    padding-bottom: 37px;
    width: 50%;
  }
}

@media(max-width:991px) {
  .inititaivedivbottobg {
    max-width: 48%;
    min-width: 48%;
  }

  .calenderandithredatadivflx {
    flex-wrap: wrap;
  }

  .clndrbigdivmaion {
    width: 100%;
    max-width: 100%;
  }

  .eventimagebgoverlay {
    height: 80px;
    max-width: 80px;
    min-width: 80px;
    position: relative;
    width: 100%;
  }

  .clndrbigdivmaiondiv .rbc-event-content {
    max-width: 78px;
    min-width: 74px;
  }

  .calderdatadivright {
    width: 100%;
    max-width: 100%;
    padding-top: 20px;
  }

  .singlehallofdetilasdivbg {
    max-width: 32%;
    min-width: 32%;
  }

  .engagedivmaileft {
    display: none;
  }

  .engagedivmairight {
    display: none;
  }

  .singlepagebottommmaindv {
    max-width: 47%;
    min-width: 47%;
  }

  .engagedivmaimiddleprfl {
    margin: 0 auto;
    width: 87%;
  }

  .engagedivmaimiddle {
    padding-bottom: 37px;
    width: 87%;
  }

  .advocaydivcntnt {
    width: 100%;
    max-width: 100%;
  }

  .advocaydivcntntscnd {
    width: 100%;
    max-width: 100%;
  }

  .engagedivmaimiddle .sara_tylor .top {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding: 10px 20px;
  }

  .advcyothrsdiv {
    width: 100%;
    max-width: 30%;
  }


}

@media(max-width:767px) {
  .inititaivedivbottobg {
    max-width: 100%;
    min-width: 100%;
  }

  .singlehallofdetilasdivbg {
    max-width: 48%;
    min-width: 48%;
  }

  .eventimagebgoverlay {
    height: 55px;
    max-width: 54px;
    min-width: 54px;
    position: relative;
    width: 100%;
    margin: auto;
  }

  .eventimagebgoverlay img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .clndrbigdivmaiondiv .rbc-event-content {
    max-width: 54px;
    min-width: 54px;
    border-radius: 50%;
  }

  .advcyothrsdiv {
    width: 100%;
    max-width: 47%;
  }

  .advocaysectioondiv {
    column-gap: 30px;
  }
}

@media(max-width:576px) {
  .singlepagebottommmaindv {
    max-width: 100%;
    min-width: 100%;
  }

  .sara_tylor .top_next {
    padding: 5px 20px 10px;
  }

  .lrmtxtpptxtpp {
    font-size: 14px;
    line-height: 18px;
  }

  .clndrbigdivmaiondiv .rbc-toolbar {
    row-gap: 10px;
    column-gap: 20px;
  }

  .clndrbigdivmaiondiv .rbc-toolbar-label {
    order: 3;
  }

  .createdivfrmmain {
    padding: 30px 30px;
  }

  .singlehallofdetilasdivbg {
    max-width: 100%;
    min-width: 100%;
  }

  .advcyothrsdiv {
    width: 100%;
    max-width: 100%;
  }

  .engagedivmaimiddle {
    width: 95%;
    padding-top: 97px;
  }

  .engagedivmaimiddle .share_thoughts {
    margin-bottom: 12px;
  }

  .engagedivmaimiddle .postthoughts_area {
    margin-bottom: 12px;

  }

  .singlepagebottommmaindv {
    max-width: 100%;
    min-width: 100%;
  }

  /* .shtretxtpp{
    display: none;
  } */
  .sharethfghbrdr {
    border: 1px solid rgb(0 101 0 / 77%);
    padding: 4px 7px;
  }

  .shtretxtpp {
    font-family: noirprolight;
    font-size: 11px;
  }

  .sgahrethgthimg {
    width: 100%;
    max-width: 20px;
    min-width: 20px;
    height: 23px;
  }

  .thght_txt {
    font-size: 19px;
    font-weight: 400;
    line-height: 22px;
  }

  .clndrbigdivmaiondiv .rbc-toolbar-label {
    font-size: 14px;
  }

  .coursrincldflx {
    width: 100%;
    max-width: 50%;
  }

  .cousreimgdmain {
    width: 100%;
    max-width: 90px;
    min-width: 90px;
    height: 76px;
  }

  .crsecntntxtdivflx {

    padding-left: 10px;
  }

}

@media(max-width:480px) {
  .shtretxtpp {
    display: none;
  }

  .clndrbigdivmaiondiv .rbc-header {
    font-size: 70%;
  }

  .engagedivmaimiddle {
    padding-top: 83px;
  }

  .eventimagebgoverlay {
    height: 40px;
    max-width: 40px;
    min-width: 40px;
    width: 100%;
  }

  .clndrbigdivmaiondiv .rbc-event-content {
    max-width: 40px;
    min-width: 40px;
    border-radius: 50%;
    width: 100%;
  }

  .sharethfghbrdr {
    border: 1px solid rgb(0 101 0 / 77%);
    padding: 2px 26px;
  }

  .coursrincldflx {
    width: 100%;
    max-width: 100%;
  }

  .wiillrndivflcx {
    flex-wrap: wrap;
    row-gap: 10px;
  }
}

@media(max-width:420px) {
  .eventimagebgoverlay {
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    width: 100%;
  }

  .clndrbigdivmaiondiv .rbc-event-content {
    max-width: 30px;
    min-width: 30px;
    border-radius: 50%;
    width: 100%;
  }
}
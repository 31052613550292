* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
figure {
  padding: 0px;
  margin: 0px;
}

body {
  background-color: #cccccc63;
}

@font-face {
  font-family: NoirProRegular;
  src: url(./Fonts/noir-pro-2023-11-27-05-25-58-utc/NoirPro-Regular.otf);
}

@font-face {
  font-family: NoirProLight;
  src: url(./Fonts/noir-pro-2023-11-27-05-25-58-utc/NoirPro-Light.otf);
}

@font-face {
  font-family: Mandaufont;
  src: url(./Fonts/mandau-sans-serif-font-family-2023-11-27-05-34-06-utc/OpenType-TT/Mandau-Regular.ttf);
}

@font-face {
  font-family: NotoSans;
  src: url(./Fonts/Noto_Sans/static/NotoSans-Regular.ttf);
}

@font-face {
  font-family: Poppinslight;
  src: url(./Fonts/Poppins/Poppins-Light.ttf);
}

/* Esg header start */
.mainheadersection {
  background: #f2f2f2;
  box-shadow: 0px 0px 5px 3px #bcbcbc;
  width: 100%;
  padding: 10px 0px;
  animation: .5s all ease-in-out;
  position: fixed;
  z-index: 9;
}

.mainheadersectionactive {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  background: #f2f2f2;
  box-shadow: 0px 0px 5px 3px #bcbcbc;
  padding: 10px 0px;
  animation: .5s all ease-in-out;
}

.esgpagecontainer {
  width: 100%;
  max-width: 87%;
  margin: 0 auto;
}

.headermaincontentflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hedrlogo {
  width: 100%;
  height: 62px;
  max-width: 132px;
  min-width: 132px;
}

.iconheader {
  cursor: pointer;
  height: 22px;
  margin: 0 auto 10px;
  position: relative;
  width: 22px;
}

.iconheader img {
  height: 100%;
  width: 100%;
}

.namelnktxtppl {
  color: #787a91;
  font-family: NotoSans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
}

.hedrlogo img {
  width: 100%;
  height: 100%;
}

.navuldiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 30px;
}

.linklistdiv {
  list-style-type: none;
  text-decoration: none;
}

.txthdlnk {
  font-family: NotoSans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #787a91;
  text-decoration: none;
  margin-right: 40px;
}

.txthdlnk.active {
  border-bottom: 3px solid #64a382;
  padding-bottom: 2px;

}

.inputtxdivmain {
  position: relative;
}

.inputsrch {
  border: 1px solid #64a382;
  padding: 5px 30px 5px 7px;
  border-radius: 3px;
}

.inputtxdivsrch {
  position: absolute;
  top: 11%;
  right: 5%;
}

.inputtxdivsrch i {
  color: #64a382;
  font-size: 14px;
}

.inputsrch:focus {
  outline: 0;
}

.prflheader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.profilebrdrhead {
  border: 1px solid #64a382;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profilebrdrimg {
  width: 100%;
  max-width: 40px;
  height: 40px;
  min-width: 40px;
}

.profilebrdrimg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.empltxtpp {
  font-size: 15px;
  line-height: 18px;
  font-family: noirprolight;
  font-weight: 500;
}

.empleearr {
  display: flex;
  justify-content: flex-start;
  column-gap: 5px;
  transition: .5s all ease-in-out;
}

.prflheaderothrs {
  position: relative;
}

/* .logolinkhedgflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
} */
.srchnfdhh {
  width: 100%;
  max-width: 25px;
  min-width: 25px;
  height: 25px;
}

.srchnfdhh img {
  width: 100%;
  height: 100%;
}

.logomenusrchnotispcl {
  align-items: center;
  display: flex;
  justify-content: flex-end;
 
}

.navbar-nav-lnkitmown {
  display: flex;
  justify-content: flex-start;
  list-style-type: none;
  column-gap: 0px;
  align-items: center;
}

.navbar-nav-own {
  align-items: center;
  column-gap: 20px;
  display: flex;
  justify-content: flex-start;
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  list-style-type: none;
}

.logomenudivspcl {
  max-width: 40%;
  width: 100%;
}

.nav-link {
  text-decoration: none;
}

/* .inputsrchprofile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  max-width: 48%;
} */

.dropwdowndiv {
  position: absolute;
  top: 56px;
  left: 0px;
  will-change: transform;
  width: 100%;
  max-width: 168px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
}

.dropwdowndivchng {
  position: absolute;
  top: 56px;
  left: 51px;
  will-change: transform;
  width: 100%;
  max-width: 130px;
  min-width: 130px;
  background-color: #fff;
  transition: .5s all ease-in-out;
  opacity: 1;
  visibility: visible;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px #ccc;

}

.prifoledivmaoin:hover {
  text-decoration: none;
  color: #000;
}

.prifoledivmaoin {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
  border-bottom: 2px solid #ccc;
  padding: 8px 20px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

.prifoledivmaoin:last-child {
  border-bottom: 0px solid #ccc;
}

.lgdhstgtxtpp {
  display: block;
  width: 100%;
  font-weight: 400;
  color: #212529;
}

.ftr_section_main {
  background: #fff;
  bottom: 0;
  box-shadow: 0 -29px 56px #00000040;
  display: none;
  height: auto;
  left: 0;
  padding-top: 5px;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 9;
}

.footercontainer {
  width: 100%;
  max-width: 95%;
  margin: 0 auto;
}

.ftr_section_main .navbar-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  width: 100%;
}

.barsgdhres {
   width: 100%;
   max-width: 22px;
   min-width: 22px;
   height: 22px;
  color: #000;
  margin-left: 10px;
  display: none;
}
.barsgdhres  img{
width: 100%;
height: 100%;
}

.sidebardiv {
  width: 100%;
  max-width: 300px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
  transition: .5s all ease-in-out;
  transform: translateX(-350px);
  box-shadow: 0px 0px 5px 0px #ccc;
}

.frtsalonlogo {
  width: 100%;
  max-width: 118px;
  min-width: 118px;
  height: 56px;
}

.frtsalonlogo img {
  width: 100%;
  height: 100%;
}

.sidetop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px 8px 15px;
  border-bottom: 1px solid #ccc;
}

.resMenuLi {
  border-top: 1px solid #353535a3;
}

.resMenuLiAn {
  background-color: initial;
  border: 0;
  color: #000;
  display: inline-block;
  font-size: 17px;
  padding: 10px 15px;
  text-align: left;
  width: 100%;
  font-family: NotoSans;
  font-weight: 500;
}

.resMenuLiAn:hover {
  color: #000;
}

/* Esg Header End */


@media(max-width:1499px) {
  .logomenudivspcl {
    max-width: 36%;
    width: 100%;
  }

  /* .dropwdowndivchng {
    left: 18px;
  } */
}



@media (max-width: 1199px) {
  .logomenudivspcl {
    max-width: 30%;
    width: 100%;
  }
}

@media(max-width:991px) {
  .barsgdhres  {
    font-size: 22px;
    color: #000;
    margin-left: 10px;
    display: block;
  }

  .dropwdowndivchng {
    position: absolute;
    top: 56px;
    left: -53px;
    will-change: transform;
    width: 100%;
    max-width: 130px;
    min-width: 130px;
    background-color: #fff;
    transition: .5s all ease-in-out;
    opacity: 1;
    visibility: visible;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px #ccc;
  }

  .resnamedesgimgdiv {
    display: none;
  }

  .sidebardivchng {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    max-width: 350px;
    transition: .5s all ease-in-out;
    transform: translateX(0);
    position: fixed;
    background: rgb(255 255 255);
    height: 100vh;
    box-shadow: 0px 0px 5px 0px #ccc;
  }

  .ftr_section_main {
    display: block;
  }

  .resMenuLi:last-child {
    border-bottom: 1px solid #353535a3;
  }

  .logomenudivspcl {
    display: none;
  }

  .logomenusrchnotispcl .nav-item {
    display: none;
  }

  .logomenusrchnotispcl .notiBtn {
    display: none;
  }
  .esgpagecontainer {
    width: 100%;
    max-width: 95%;
    margin: 0 auto;
}
}

@media(max-width:576px) {
  /* .dropwdowndivchng {
    left: -24px;
  } */

}

@media(max-width:480px) {
  .hedrlogo {
    width: 100%;
    height: 51px;
    max-width: 105px;
    min-width: 105px;
  }

  .sidebardivchng {
    width: 100%;
    max-width: 270px;
  }

  .profilebrdrimg {
    width: 100%;
    max-width: 30px;
    height: 30px;
    min-width: 30px;
  }

  .empltxtpp {
    font-size: 13px;
    line-height: 16px;
    font-family: noirprolight;
    font-weight: 500;
  }
}

@media(max-width:420px) {
  .dropwdowndivchng {
    left: -44px;
    max-width: 122px;
    min-width: 122px;
  }
}